<script setup>
import { computed } from "vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

const emit = defineEmits(["update:modelValue", "resetToDefault"]);

const props = defineProps({
  modelValue: {
    type: [String, null],
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
  id: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  resetToDefaultIcon: {
    type: [Boolean],
    required: false,
    default: false,
  },
});

const valueTmp = computed({
  get() {
    return props.modelValue ? props.modelValue : "";
  },
  set(evt) {
    emit("update:modelValue", evt);
  },
});
</script>

<template>
  <div>
    <div v-if="label" class="flex gap-0.5">
      <label :for="id" class="block text-xs font-medium text-gray-500 mb-1">
        {{ label }}
      </label>
      <div v-if="!disabled" class="h-3 w-3 flex">
        <font-awesome-icon
          v-if="resetToDefaultIcon"
          title="Reset to default value"
          icon="fa solid fa-rotate-left"
          class="cursor-pointer text-gray-500 font-medium text-sm h-3 w-3 my-auto ml-2.5"
          @click="emit('resetToDefault')"
        />
      </div>
    </div>

    <QuillEditor
      :id="id"
      v-model:content="valueTmp"
      :readOnly="disabled"
      contentType="html"
      :toolbar="[['bold', 'italic', 'underline'], ['link']]"
      data-paceholder=""
      class="block w-full border border-gray-300 rounded-b-md shadow-sm py-1 focus:outline-none focus:border-gray-300 focus:ring-0 text-sm"
      :class="[disabled ? 'bg-gray-200 cursor-default' : 'bg-white']"
    />
  </div>
</template>

<style>
.ql-toolbar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.ql-container {
  height: fit-content !important;
}
</style>
