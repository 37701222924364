<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import BaseTabsParamsSettingsButtonNew from "@/components/Base/ComponentsForBase/BaseTabsParamsSettingsButton.vue";

const route = useRoute();

defineEmits(["updateTabs", "openAssetLogModal"]);

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  fixedTabs: {
    type: Array,
    required: false,
    default: () => [],
  },
  allAvailableTabs: {
    type: Array,
    required: false,
    default: () => [],
  },
  showSettingsButton: {
    type: Boolean,
    default: false,
  },
  showActivityButton: {
    type: Boolean,
    default: false,
  },
  settingsButtonClick: {
    type: Function,
    default: () => {},
  },
});

const currentQuery = computed(() => {
  let query = { ...route.query };
  if (!query.tab) query = { ...query, tab: props.tabs[0].to };

  return query;
});
const tabClass = (tab) => {
  if (["errors"].includes(tab.to)) {
    return tab.to !== currentQuery.value.tab
      ? "text-bs-red hover:bg-bs-red-light"
      : "text-bs-red border-b-4 border-bs-red";
  }
  if (["warnings"].includes(tab.to)) {
    return tab.to !== currentQuery.value.tab
      ? "text-bs-orange-dark hover:bg-bs-orange"
      : "text-bs-orange-dark border-b-4 border-bs-orange-dark";
  }

  return tab.to === currentQuery.value.tab
    ? "text-bs-black border-bs-black border-b-4"
    : "text-bs-black-80 border-transparent hover:bg-gray-100";
};
</script>

<template>
  <div v-if="tabs">
    <div class="flex items-center gap-1 mb-2.5">
      <nav class="flex w-full overflow-x-auto whitespace-nowrap border-b-2 border-bs-gray-border">
        <template v-for="tab in tabs" :key="tab.id">
          <router-link
            v-if="tab"
            class="px-2.5 py-1.5 text-xs font-medium"
            :to="{ query: { ...currentQuery, tab: tab.to } }"
            :class="tabClass(tab)"
          >
            {{ tab.text }}
          </router-link>
        </template>
      </nav>

      <div
        v-if="showActivityButton"
        title="Activity logs"
        class="flex items-center bg-gray-100 hover:bg-gray-200 h-8 w-8 justify-center rounded text-gray-500"
      >
        <button @click.stop="$emit('openAssetLogModal')">
          <font-awesome-icon icon="fa-solid fa-chart-line" size="sm" />
        </button>
      </div>

      <BaseTabsParamsSettingsButtonNew
        v-if="showSettingsButton"
        :fixedTabs="fixedTabs"
        :checkedTabs="tabs.map((item) => item.to)"
        :allAvailableTabs="allAvailableTabs"
        @updateTabs="$emit('updateTabs', $event)"
      />
    </div>

    <Transition mode="out-in">
      <component
        :is="tabs.find((tab) => tab.to === currentQuery.tab)?.component"
      />
    </Transition>
  </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
