<script setup>
import { useRoute } from "vue-router";
import { computed, inject, ref, shallowRef, watch } from "vue";
import { useAuthStore } from "@/stores/auth";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import HelpAndFeedback from "@/components/Layout/SideBar/Components/HelpAndFeedback.vue";
import FeedbackPopup from "./Components/FeedbackPopup.vue";
import HelpAndFeedbackModal from "./Components/HelpAndFeedbackModal.vue";
import ImpersonateButton from "@/components/Layout/SideBar/Components/ImpersonateButton.vue";
import ImpersonateModal from "@/components/Layout/SideBar/Components/ImpersonateModal.vue";
import StaticSidebarRouterButton from "@/components/Layout/SideBar/Components/StaticSidebarRouterButton.vue";
import MobileSidebarRouterButton from "@/components/Layout/SideBar/Components/MobileSidebarRouterButton.vue";

const getPermissions = inject("getPermissions");
const auth = useAuthStore();
const route = useRoute();

const navigation = shallowRef([
  {
    name: "Add media",
    route: "add-media",
    href: "/add-media",
    icon: "fa-plus",
    view_permission: ["media.general"],
  },
  {
    name: "Media",
    route: "media",
    href: "/",
    icon: "fa-circle-play",
    view_permission: ["media.general"],
  },
  {
    name: "Groups",
    route: "groups",
    href: "/groups",
    icon: "fa-layer-group",
    view_permission: ["groups.general"],
  },
  {
    name: "Channels",
    route: "channels",
    href: "/channels/live",
    icon: "fa-video",
    view_permission: ["channels.general", "channels.recordings"],
  },
  {
    name: "VOD2Live",
    route: "vod2live",
    href: "/vod2live/channel",
    icon: "fa-clapperboard",
    for_superadmin_only: true,
  },
  {
    name: "Folders",
    route: "folders",
    href: "/folders?tab=general",
    icon: "fa-folder-closed",
    view_permission: ["folders.general"],
  },
  {
    name: "Playback",
    route: "playback",
    href: "/playback/players",
    icon: "fa-play",
    view_permission: [
      "players.player_presets",
      "players.advertisements",
      "players.protection",
    ],
  },
  {
    name: "Analytics",
    route: "analytics",
    href: "/analytics",
    icon: "fa-chart-column",
    view_permission: ["analytics.general"],
  },
  {
    name: "OTT",
    route: "ott",
    href: "/ott/banners",
    icon: "fa-tv",
    for_superadmin_only: true,
  },
]);

const sidebarCurrent = computed(() => {
  const routeTmp = navigation.value.find((item) => item.route === route.name);

  return routeTmp ? routeTmp.name : "none";
});

const filteredNavigation = computed(() => {
  let navTmp = [...navigation.value];

  //filter by View Permission
  navTmp = navTmp.filter((item) => {
    if (
      !item.view_permission ||
      item.view_permission.some((permission) => getPermissions.view(permission))
    ) {
      return item;
    }
  });

  //special analytics filter
  if (!auth.user || !auth.user.client.view_analytics) {
    navTmp = navTmp.filter((item) => item.route !== "analytics");
  }

  //filter by Superadmin
  if (!auth.user || !auth.user.is_superadmin) {
    navTmp = navTmp.filter((item) => !item.for_superadmin_only);
  }

  return navTmp;
});

const openModal = ref(false);
const openFeedback = ref(false);
const openImpersonateModal = ref(false);

watch(
  () => auth.openHelpAndFeedbackModal,
  () => {
    if (auth.openHelpAndFeedbackModal) {
      openFeedback.value = true;
      auth.openHelpAndFeedbackModal = false;
    }
  }
);
</script>

<template>
  <div v-if="auth.user" class="flex h-full bg-gray-50">
    <!--   mobile sidebar-->
    <TransitionRoot as="template" :show="auth.mobileSidebarOpen">
      <Dialog as="div" class="relative z-40 lg:hidden">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 flex z-40">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel
              class="relative flex-1 flex flex-col max-w-[220px] w-full pt-5 pb-4 bg-white"
            >
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute top-0 right-0 p-2 translate-x-full">
                  <button
                    type="button"
                    class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    @click="auth.mobileSidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>

                    <font-awesome-icon
                      icon="fa-solid fa-xmark"
                      class="h-6 w-6 text-white"
                    />
                  </button>
                </div>
              </TransitionChild>

              <div class="mt-5 flex-1 h-0 overflow-y-auto">
                <nav class="px-2 space-y-1">
                  <MobileSidebarRouterButton
                    v-for="item in filteredNavigation"
                    :key="item.name"
                    :item="item"
                    :sidebar-current="sidebarCurrent"
                  />

                  <ImpersonateButton
                    v-if="auth.user.is_superadmin"
                    :mobile="true"
                    :sidebar-simple="auth.sidebarSimple"
                    @open-modal="openImpersonateModal = true"
                  />

                  <HelpAndFeedback
                    :mobile="true"
                    :sidebar-simple="auth.sidebarSimple"
                    @open-modal="openModal = true"
                  />
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div
      class="hidden lg:flex relative z-30 h-full flex-auto overflow-auto"
      :class="auth.sidebarSimple ? 'lg:w-[54px]' : 'lg:w-[74px]'"
    >
      <nav class="flex flex-col max-h-full">
        <StaticSidebarRouterButton
          v-for="item in filteredNavigation"
          :key="item.name"
          :item="item"
          :sidebar-current="sidebarCurrent"
        />

        <div class="mt-auto">
          <ImpersonateButton
            v-if="auth.user.is_superadmin"
            :sidebar-simple="auth.sidebarSimple"
            @open-modal="openImpersonateModal = true"
          />

          <HelpAndFeedback
            :sidebar-simple="auth.sidebarSimple"
            @open-modal="openModal = true"
          />
        </div>
      </nav>
    </div>

    <ImpersonateModal
      v-if="auth.user.is_superadmin"
      :open="openImpersonateModal"
      @close-impersonate-modal="openImpersonateModal = false"
    />

    <HelpAndFeedbackModal
      :open="openModal"
      @on-update:open="openModal = $event"
      @on-update:openFeedback="openFeedback = $event"
    />
    <FeedbackPopup
      :isActivePrompt="openFeedback"
      @closePopup="openFeedback = false"
      @openPopup="openFeedback = true"
    />
  </div>
</template>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.docs-btn {
  padding: 0.7rem !important;
  font-size: 13px;
}
.align-files {
  vertical-align: top;
}
@media (max-width: 1500px) {
  #main-popup .vs-popup {
    width: 50% !important;
  }
}
@media (max-width: 1200px) {
  .feedback-button-paragraph {
    display: none !important;
  }
  .docs-btn {
    padding: 5px 5px 1px !important;
  }
}
@media (max-width: 768px) {
  #main-popup .vs-popup {
    width: 90% !important;
  }
}
</style>
