export const defaultPreferences = {
  mediaColumns: {
    fixedList: ["Image", "Title"],
    fixedGrid: ["Image", "Title", "Action"],
    fixedAdvanced: ["Image", "Title", "Status", "Transcoding_status"],
    default: [
      "Image",
      "Title",
      "System_ID",
      "Asset_ID",
      "Status",
      "Duration",
      "Created",
      "Modified",
      "Action",
    ],
    all: [
      "Image",
      "Title",
      "System_ID",
      "Asset_ID",
      "Folder",
      "Tags",
      "Status",
      "Duration",
      "Created",
      "Modified",
      "Approved",
      "Published",
      "Transcoding_status",
      "Action",
    ],
  },
  defaultMediaViews: [
    {
      id: "default",
      name: "Default view",
      color: "bg-main1",
      columns: [
        "Image",
        "Title",
        "System_ID",
        "Asset_ID",
        "Status",
        "Duration",
        "Created",
        "Modified",
        "Action",
      ],
      viewType: "List",
      mediaLimit: 20,
      orderBy: "id",
      orderDir: true,
      selectedSearchFilters: [],
      selectedSearchWildcards: [],
      selectedFolder: 0,
      mediaListParams: {
        id: null,
        title: null,
        status: [],
        status_exclude: [],
        publisher_status: null,
        technical_status: [],
        technical_status_exclude: [],
        errors: null,
        metadata: {},
        tags: [],
        source_file_ext: [],
        created_period: null,
        created_from: null,
        created_to: null,
        cat_id: null,
        resolution: null,
        file_length: null,
        gop_type: null,
        frame_rate: null,
        codec: null,
        language: null,
        gop_size: null,
        audio_language: null,
        subtitle_language: null,
        audio_tracks_count: null,
        audio_type: null,
        content_type: null,
      },
    },
  ],
  mediaListParams: {
    id: null,
    title: null,
    status: [],
    status_exclude: [],
    publisher_status: null,
    technical_status: [],
    technical_status_exclude: [],
    errors: null,
    metadata: {},
    tags: [],
    source_file_ext: [],
    created_period: null,
    created_from: null,
    created_to: null,
    cat_id: null,
    resolution: null,
    file_length: null,
    frame_rate: null,
    codec: null,
    language: null,
    gop_type: [],
    gop_size: null,
    audio_language: null,
    subtitle_language: null,
    audio_tracks_count: null,
    audio_type: [],
    content_type: null,
  },
  liveTabs: {
    fixed: ["general"],
    default: [
      "general",
      "input_info",
      "images_visuals",
      "publish",
      "monetization",
      "protection",
      "recording",
      "elementary_streams",
      "manifests",
    ],
  },
  filesDropdown: {
    fixed: [
      "icon",
      "file_name",
      "file_size",
      "resolution",
      "language",
      "actions",
    ],
    default: [
      "icon",
      "file_name",
      "file_size",
      "resolution",
      "language",
      "actions",
    ],
    all: [
      "icon",
      "file_type",
      "file_name",
      "file_size",
      "extension",
      "resolution",
      "bitrate",
      "language",
      "codec",
      "progressive_link",
      "actions",
    ],
  },
  editTabs: {
    fixed: ["general"],
    default: [
      "general",
      "embed_play",
      "images_visuals",
      "advertisement",
      "protection",
      "download",
      "custom_titles",
      "upload",
      "files",
    ],
  },
  fileInfoParameters: [
    { id: "resolution", name: "Resolution" },
    { id: "file_length", name: "Length" },
    { id: "gop_type", name: "Key frame interval" },
    { id: "gop_size", name: "GOP size" },
    { id: "frame_rate", name: "Frame rate" },
    { id: "codec", name: "Codec" },
    { id: "content_type", name: "Content type" },
    { id: "audio_tracks_count", name: "Audio track amount" },
    { id: "audio_type", name: "Audio type" },
    { id: "language", name: "Language" },
    { id: "audio_language", name: "Audio language" },
    { id: "subtitle_language", name: "Subtitle language" },
  ],
};
