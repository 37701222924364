import { defineStore } from "pinia";
import axiosClient from "@/axiosClient";
import { app } from "@/main";
import { useAuthStore } from "@/stores/auth";

const URL = import.meta.env.VITE_API_URL;

export const useAdminStore = defineStore({
  id: "admin",
  state: () => ({
    adminSettingsLoading: false,
    adminUsersLoading: false,
    adminSettings: null,
    disableVatInput: false,
    defaultAdminSettings: null,
    clientUsers: null,
    defaultClientUsers: null,
    clientUsersSort: {
      orderBy: "name",
      orderDir: true,
      limit: 10,
      offset: 0,
      total: 0,
    },
    edit_user_ID: null,
    open_user_edit: false,
    newClientUser: {
      status: "active",
      name: "",
      email: "",
      usergroup_id: null,
      timezone: useAuthStore().user.timezone,
      datetime_format: useAuthStore().user.datetime_format,
      image: "",
      ftp_root_folder: "",
      web_upload_root_category_id: 0,
      ip_whitelist: "",
      email_notifications: 0,
      send_invite: 1,
    },
    newUserGroup: {
      name: "",
      access: {},
    },
    clientUserGroups: null,
    defaultClientUserGroups: null,
    clientUserGroupsSort: {
      orderBy: "name",
      orderDir: true,
    },
    edit_userGroup_ID: null,
    open_userGroup_edit: false,
    imageCropped: null,
    userGroupsStructure: null,
    userRolesNames: null,
    clientUserNames: null,
    constructorPreview: [],
    clientUserCSVExport: null,
    returnValues: ["general"],
  }),
  actions: {
    getAdminSettings(payload) {
      this.adminSettingsLoading = true;
      const paramsTmp = payload ? payload : this.returnValues;
      const params = {
        return: [...paramsTmp].join(","),
      };

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/client/get/", { params })
          .then((response) => {
            if (response.data.code === 0) {
              this.adminSettings = response.data.data;
              if (paramsTmp.includes("billing")) {
                this.disableVatInput = !!this.adminSettings.billing.vat_no;
              }

              if (
                paramsTmp.includes("metadata") &&
                (!this.adminSettings.metadata?.config ||
                  !Array.isArray(this.adminSettings.metadata?.config))
              ) {
                this.adminSettings.metadata.config = [];
              }
              this.adminSettingsLoading = false;
              this.defaultAdminSettings = JSON.parse(
                JSON.stringify(this.adminSettings)
              );
              resolve(response);
            }
          })
          .catch((error) => {
            this.adminSettingsLoading = false;
            reject(error);
          });
      });
    },

    updateMetadataIndexProgress() {
      const params = {
        return: ["metadata"],
      };

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/client/get/", { params })
          .then((response) => {
            if (response.data.code === 0) {
              if (!this.adminSettings)
                this.adminSettings = {
                  metadata: { search_index_progress: 0, search_index_ready: 0 },
                };

              this.adminSettings["metadata"]["search_index_progress"] =
                response.data.data.metadata.search_index_progress;

              this.adminSettings["metadata"]["search_index_ready"] =
                response.data.data.metadata.search_index_ready;

              this.defaultAdminSettings = JSON.parse(
                JSON.stringify(this.adminSettings)
              );
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    saveAdminSettings(payload) {
      let params = payload ? payload : this.adminSettings;

      if (params.billing && !params.billing.vat_no) {
        delete params.billing.vat_no;
        delete params.billing.vat_percentage;
      }

      return new Promise((resolve, reject) => {
        axiosClient
          .put(URL + "/client/update", params)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: response.data.msg,
              });
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getUserList(status = "active") {
      this.adminUsersLoading = true;

      const params = {
        status: status,
        order_by: this.clientUsersSort.orderBy,
        order_dir: this.clientUsersSort.orderDir ? "desc" : "asc",
        limit: this.clientUsersSort.limit,
        offset: this.clientUsersSort.offset,
      };

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/client/user/list", { params })
          .then((response) => {
            if (response.data.code === 0) {
              this.clientUsers = response.data.data;
              this.defaultClientUsers = JSON.parse(
                JSON.stringify(response.data.data)
              );
              this.clientUsersSort.total = response.data.total;
              this.clientUsersSort.limit = response.data.limit;
              this.clientUsersSort.offset = response.data.offset;

              this.clientUserCSVExport = response.data.export.csv;
              this.adminUsersLoading = false;
              resolve(response);
            }
          })
          .catch((error) => {
            this.adminUsersLoading = false;
            reject(error);
          });
      });
    },

    getClientUserNames(status = "active") {

      const params = {
        status: status,
        order_by: "name",
        order_dir: "desc",
        limit: 100,
      };

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/client/user/list/", params )
          .then((response) => {
            if (response.data.code === 0) {
              this.clientUserNames = response.data.data;
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getUserGroupList() {
      this.adminSettingsLoading = true;

      const params = {
        order_by: this.clientUserGroupsSort.orderBy,
        order_dir: this.clientUserGroupsSort.orderDir ? "desc" : "asc",
      };

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/client/userrole/list", { params })
          .then((response) => {
            if (response.data.code === 0) {
              this.clientUserGroups = response.data.data;
              this.defaultClientUserGroups = JSON.parse(
                JSON.stringify(response.data.data)
              );
            }
            this.adminSettingsLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.adminSettingsLoading = false;
            reject(error);
          });
      });
    },

    getUserGroupListNames() {
      this.adminSettingsLoading = true;

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/client/userrole/listnames")
          .then((response) => {
            if (response.data.code === 0) {
              this.userRolesNames = response.data.data;
            }
            this.adminSettingsLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.adminSettingsLoading = false;
            reject(error);
          });
      });
    },

    saveClientUser(payload) {
      this.adminSettingsLoading = true;

      if (this.imageCropped != null) payload.image = this.imageCropped;
      if (!payload.ftp_root_folder) {
        delete payload.ftp_root_folder;
      }

      return new Promise((resolve, reject) => {
        axiosClient
          .put(URL + "/client/user/update", payload)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: response.data.msg,
              });
            }
            this.adminSettingsLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.adminSettingsLoading = false;
            reject(error);
          });
      });
    },

    createClientUser(payload) {
      this.adminSettingsLoading = true;
      if (this.imageCropped != null) {
        payload.image = this.imageCropped;
      }

      if (!payload.ftp_root_folder) {
        delete payload.ftp_root_folder;
      }

      return new Promise((resolve, reject) => {
        axiosClient
          .post(URL + "/client/user/create", payload)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: response.data.msg,
              });
            }
            this.adminSettingsLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.adminSettingsLoading = false;
            reject(error);
          });
      });
    },

    deleteClientUser(userId) {
      return new Promise((resolve, reject) => {
        axiosClient
          .delete(URL + "/client/user/delete?id=" + userId)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: response.data.msg,
              });
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    resetUserPassword(userId) {
      return new Promise((resolve, reject) => {
        axiosClient
          .post(URL + "/client/user/resetpassword?id=" + userId)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: response.data.msg,
              });
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    resendUserInvitation(userId) {
      return new Promise((resolve, reject) => {
        axiosClient
          .post(URL + "/client/user/resendinvitation?id=" + userId)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: response.data.msg,
              });
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getUserGroupsStructure() {
      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/client/userrole/liststructure")
          .then((response) => {
            if (response.data.code === 0) {
              this.userGroupsStructure = response.data.data;
              this.adminSettingsLoading = false;
              resolve(response);
            }
          })
          .catch((error) => {
            this.adminSettingsLoading = false;
            reject(error);
          });
      });
    },

    saveUserGroup(payload) {
      this.adminSettingsLoading = true;

      return new Promise((resolve, reject) => {
        axiosClient
          .put(URL + "/client/userrole/update", payload)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: response.data.msg,
              });
            }
            this.adminSettingsLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.adminSettingsLoading = false;
            reject(error);
          });
      });
    },

    createUserGroup(payload) {
      this.adminSettingsLoading = true;

      return new Promise((resolve, reject) => {
        axiosClient
          .post(URL + "/client/userrole/create", payload)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: response.data.msg,
              });
            }
            this.adminSettingsLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.adminSettingsLoading = false;
            reject(error);
          });
      });
    },

    deleteUserGroup(userGroupId) {
      return new Promise((resolve, reject) => {
        axiosClient
          .delete(URL + "/client/userrole/delete?id=" + userGroupId)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: response.data.msg,
              });
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deleteClient() {
      return new Promise((resolve, reject) => {
        axiosClient
          .delete(URL + "/client/delete")
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                type: "success",
                text: response.data.msg,
              });
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    confirmDeleteClient(hash) {
      return new Promise((resolve, reject) => {
        axiosClient
          .delete(URL + "/client/deletebyhash?hash=" + hash)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                type: "success",
                text: response.data.msg,
              });
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});
