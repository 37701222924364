<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const currentSection = computed(() => {
  return route.params.section;
});

defineProps({
  sections: {
    type: Array,
    required: true,
  },
});
</script>

<template>
  <div>
    <div v-if="route.name !== 'vod2live'" class="flex items-center gap-1 mb-3">
      <nav class="flex w-full overflow-x-auto whitespace-nowrap border-b-2 border-bs-gray-border">
        <router-link
          v-for="section in sections"
          :key="section.id"
          class="px-2.5 py-1.5 text-xs font-medium"
          :to="{ name: route.name, params: { section: section.to } }"
          :class="
            section.to === currentSection
              ? 'text-bs-black border-bs-black border-b-4'
              : 'text-bs-black-80 border-transparent hover:bg-gray-100'
          "
        >
          {{ section.text }}
        </router-link>
      </nav>
    </div>
    <Transition
      mode="out-in"
      v-if="sections.find((section) => section.to === currentSection)"
    >
      <component
        :is="
          sections.find((section) => section.to === currentSection).component
        "
      />
    </Transition>
  </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
