import {
  configure,
  defineRule,
  ErrorMessage,
  Field as VeeField,
  Form as VeeForm,
} from "vee-validate";
import {
  confirmed,
  email,
  min,
  required,
} from "@vee-validate/rules";

export default {
  install(app) {
    app.component("VeeForm", VeeForm);
    app.component("VeeField", VeeField);
    app.component("ErrorMessage", ErrorMessage);

    defineRule("required", required);
    defineRule("tos", required);
    defineRule("min", min);
    defineRule("min_12", min);
    defineRule("email", email);
    defineRule("passwords_mismatch", confirmed);
    defineRule("one_letter", (value) => {
      const regex = /[a-zA-Z]/g;
      if (!regex.test(value)) {
        return "The password field must contain one letter.";
      }
      return true;
    });
    defineRule("capital_letter", (value) => {
      const regex = /[A-Z]/g;
      if (!regex.test(value)) {
        return "The password field must contain one capital letter.";
      }
      return true;
    });
    defineRule("one_number", (value) => {
      const regex = /\d/g;
      if (!regex.test(value)) {
        return "The password field must contain one number.";
      }
      return true;
    });
    defineRule("one_symbol", (value) => {
      const regex = /\p{Z}|\p{S}|\p{P}/u;
      if (!regex.test(value)) {
        return "The password field must contain one symbol.";
      }
      return true;
    });

    configure({
      generateMessage: (ctx) => {
        const messages = {
          required: `This field is required.`,
          min: `The ${ctx.field} field is too short.`,
          min_12: `The ${ctx.field} field must contain at least 12 characters.`,
          email: `The ${ctx.field} field must be a valid email.`,
          passwords_mismatch: `The passwords don't match.`,
          tos: `You must accept the Terms of Service.`,
        };
        return messages[ctx.rule.name]
          ? messages[ctx.rule.name]
          : `The field ${ctx.field} is invalid.`;
      },
      validateOnBlur: true,
      validateOnChange: true,
      validateOnInput: false,
      validateOnModelUpdate: true,
    });
  },
};
