<script setup>
import { notify } from "@kyvg/vue3-notification";

const props = defineProps({
  modelValue: {
    type: [String, Number],
    required: true,
  },
  onlyIcon: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const copy = () => {
  navigator.clipboard.writeText(props.modelValue);

  notify({
    type: "success",
    text: "Copied to clipboard!",
    duration: 1000,
  });
};
</script>

<template>
  <button
    type="button"
    class="flex items-center text-main1 transform cursor-pointer transition duration-250 ease-in-out hover:text-main1-dark hover:scale-110 disabled:opacity-50 disabled:cursor-default disabled:text-main1 disabled:scale-100"
    :disabled="disabled"
    @click="copy()"
  >
    <font-awesome-icon icon="fa-solid fa-copy" size="lg" />

    <p v-if="!onlyIcon" class="text-xs font-medium pr-1 ml-1">Copy</p>
  </button>
</template>
