<script setup>
import { computed } from "vue";
import { ChevronDownIcon } from "@heroicons/vue/outline";

const props = defineProps({
  showSaveOptions: {
    type: Boolean,
    required: true,
  },
  disableBtns: {
    type: Boolean,
    required: true,
  },
  createBtns: {
    type: Boolean,
    required: false,
    default: false,
  },
  actionInProgress: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["onUpdate:showSaveOptions", "save", "saveAndExit"]);

const tmpShowSaveOptions = computed({
  get() {
    return props.showSaveOptions;
  },
  set(evt) {
    emit("onUpdate:showSaveOptions", evt);
  },
});
</script>

<template>
  <div class="relative inline-flex shadow-sm" role="group">
    <button
      :disabled="disableBtns || actionInProgress"
      type="button"
      class="disabled:opacity-50 inline-flex items-center w-20 px-2.5 py-2 text-xs font-medium shadow-sm transition duration-250 ease-in-out rounded-l-2xl text-center justify-center focus:outline-none focus:ring-0 text-white bg-main1 hover:bg-main1-dark border-transparent"
      @click="emit('save')"
    >
      <span
        v-if="actionInProgress"
        class="animate-spin w-4 h-4 border-4 border-b-transparent rounded-full"
        role="status"
      />
      <span v-else>
        {{ createBtns ? "Create" : "Save" }}
      </span>
    </button>

    <button
      :disabled="disableBtns || actionInProgress"
      type="button"
      class="disabled:opacity-50 inline-flex items-center px-2.5 py-1.5 text-xs font-medium shadow-sm border-l border-gray-100 transition duration-250 ease-in-out rounded-r-2xl text-center justify-center focus:outline-none focus:ring-0 text-white bg-main1 hover:bg-main1-dark border-transparent"
      @click="tmpShowSaveOptions = !tmpShowSaveOptions"
    >
      <ChevronDownIcon class="h-4" />
    </button>

    <div
      v-if="tmpShowSaveOptions"
      class="absolute right-0 top-[115%] bg-gray-100 text-gray-700 rounded shadow-sm z-[999999]"
    >
      <button
        :disabled="disableBtns || actionInProgress"
        type="button"
        class="px-2.5 py-1.5 w-[117px] rounded text-xs font-medium hover:cursor-pointer hover:bg-gray-200"
        @click.stop.prevent="emit('saveAndExit')"
      >
        {{ createBtns ? "Create" : "Save" }} & Exit
      </button>
    </div>
  </div>
</template>
