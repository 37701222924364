<script setup>
import { ref, shallowRef } from "vue";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import LinkCard from "@/components/Layout/BsWelcomeMsg/Components/LinkCard.vue";
import BsButton from "@/components/Base/BsButton.vue";
import BsCheckbox from "@/components/Base/BsCheckbox.vue";

const authStore = useAuthStore();
const { showWelcomeModal } = storeToRefs(authStore);

const infoCards = shallowRef([
  {
    icon: "circle-plus",
    href: "https://docs.backscreen.com/documentation/getting-started/upload-and-publish-a-video",
    link_text: "Add and publish media",
    description:
      "Upload through Backscreen or FTP, get the embed code, and publish!",
  },
  {
    icon: "circle-play",
    href: "https://docs.backscreen.com/documentation/getting-started/manage-content",
    link_text: "Manage your content",
    description:
      "Edit videos one by one or create a custom setup for multiple media at once with Playback templates.",
  },
  {
    icon: "folder",
    href: "https://docs.backscreen.com/documentation/getting-started/categorize",
    link_text: "Arrange your content",
    description:
      "Sort your media by categorizing it and edit the dashboard for efficiency.",
    split_description: "dashboard",
    description_href:
      "https://docs.backscreen.com/documentation/getting-started/edit-dashboard",
  },
  {
    icon: "gear",
    href: "https://docs.backscreen.com/documentation/getting-started/edit-dashboard#advanced-mode-toggle",
    link_text: "Enable advanced mode",
    description:
      "Dive deeper into the platform's functionality and the ability to customize specific needs.",
  },
]);

const doNotShowAgain = ref(false);

const letsStart = () => {
  authStore.showWelcomeModal = false;
  if (doNotShowAgain.value) {
    authStore.savePreferences({
      preferences: {
        disableWelcomeMsg: "true",
      },
    });
  }
};
</script>

<template>
  <TransitionRoot appear unmount :show="showWelcomeModal">
    <Dialog as="div" class="relative z-[9999]">
      <!-- The backdrop, rendered as a fixed sibling to the panel container -->
      <TransitionChild
        appear
        enter="transition-opacity duration-1000"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-1000"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-75" aria-hidden="true" />
      </TransitionChild>

      <!-- Full-screen scrollable container -->
      <div class="fixed inset-0 overflow-y-auto">
        <!-- Container to center the panel -->
        <div class="flex h-full w-full items-center justify-center p-4">
          <TransitionChild
            enter="transition ease-in duration-1000 transform"
            enter-from="scale-0 opacity-0"
            enter-to="scale-100 opacity-100"
            leave="transition ease-out duration-1000 transform"
            leave-from="scale-100 opacity-100"
            leave-to="scale-0 opacity-0"
          >
            <DialogPanel
              :class="[
                'relative w-full max-w-[720px] md:min-w-[720px] rounded bg-white',
                'grid gap-8 p-8',
                'text-left',
              ]"
            >
              <div class="w-full">
                <h1 class="text-bs-black font-medium text-2xl">
                  Welcome to Backscreen!
                </h1>

                <h2
                  class="text-bs-black-80 font-medium tracking-wide text-xl leading-6"
                >
                  Here are some tips on how to start using the platform
                </h2>
              </div>

              <div class="w-full grid gap-4">
                <LinkCard
                  v-for="(item, i) in infoCards"
                  :key="i"
                  :card="item"
                />

                <p class="text-base font-normal leading-6 text-bs-black">
                  To learn more about Backscreen
                  <a
                    href="https://docs.backscreen.com/documentation/"
                    target="_blank"
                    class="underline"
                  >
                    read documentation</a
                  >.
                </p>
              </div>

              <div class="flex justify-between items-center">
                <BsCheckbox
                  id="Do not show this again"
                  label="Do not show this again"
                  v-model="doNotShowAgain"
                />

                <BsButton theme="dark" class="" @click="letsStart()">
                  Let's start
                </BsButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
