import { ref } from "vue";
import { autoUpdate, flip, offset, shift, useFloating } from "@floating-ui/vue";

export default function useTooltip(placement = "top") {
  const showTooltip = ref(false);
  const reference = ref(null);
  const floating = ref(null);
  const { floatingStyles } = useFloating(reference, floating, {
    whileElementsMounted(...args) {
      // Important! Always return the cleanup function.
      return autoUpdate(...args, { animationFrame: true });
    },
    placement: placement,
    middleware: [offset(5), flip(), shift()],
  });

  return {
    showTooltip,
    reference,
    floating,
    floatingStyles,
  };
}