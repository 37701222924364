<script setup>
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  ComboboxLabel,
} from "@headlessui/vue";
import { computed, nextTick, ref, watchEffect } from "vue";
import { SelectorIcon } from "@heroicons/vue/outline";

const props = defineProps({
  modelValue: {
    type: [String, Number, Object, null],
    required: false,
    default: "",
  },
  label: {
    type: String,
    required: false,
  },
  options: {
    type: [Array],
    required: true,
  },
  trackBy: {
    type: [Array],
    required: false,
    default: () => ["name"],
  },
  disabled: {
    type: [Boolean],
    required: false,
    default: false,
  },
  placeholder: {
    type: String,
    required: false,
    default: "none",
  },
  focusOnMount: {
    type: [Boolean],
    required: false,
    default: false,
  },
  noBorder: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabledOptions: {
    type: [Array],
    required: false,
    default: () => [],
  },
  disabledOptionTrackBy: {
    type: String,
    required: false,
    default: "id",
  },
  showXButton: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue"]);

const tmpValue = computed({
  get() {
    if (
      !props.modelValue ||
      (Array.isArray(props.modelValue) && !props.modelValue.length)
    ) {
      return "";
    }
    return props.modelValue;
  },
  set(evt) {
    emit("update:modelValue", evt);
  },
});

const query = ref("");
const singleBatch = ref(100);
const lazyLoadBatches = ref(1);

const totalBatch = computed(() => {
  return singleBatch.value * lazyLoadBatches.value;
});

const optimizeSearch = computed(() => {
  if (!props.options || !Array.isArray(props.options)) return false;
  return props.options.length > singleBatch.value;
});

const filteredQueryOptions = computed(() => {
  return props.options.filter((item) => {
    return (
      item.id.toString().includes(query.value.toString()) ||
      item.name.toLowerCase().includes(query.value.toLowerCase())
    );
  });
});

const filteredOptions = computed(() => {
  if (query.value.length < 3 && optimizeSearch.value) {
    return [];
  }

  return query.value === ""
    ? props.options
    : filteredQueryOptions.value.filter((item, index) => {
        return index < totalBatch.value;
      });
});

const checkOptimizedSearch = computed(() => {
  return props.options.some(
    (item) =>
      item.id.toString().includes(query.value.toString()) ||
      item.name.toLowerCase().includes(query.value.toLowerCase())
  );
});

const dropdownIsOpen = ref(false);
const openDropdown = () => {
  dropdownIsOpen.value = true;
};

const comboboxButton = ref(null);
const comboboxOptions = ref(null);

const dropdownIsAbove = ref(false);

watchEffect(() => {
  if (dropdownIsOpen.value && comboboxButton.value) {
    nextTick(() => {
      const buttonRect = comboboxButton.value.getBoundingClientRect();
      const spaceBelow = window.innerHeight - buttonRect.bottom;
      dropdownIsAbove.value = spaceBelow < 250;
    });
  }
});
const clearQuery = (evt) => {
  query.value = evt;
  lazyLoadBatches.value = 1;
};
</script>

<template>
  <Combobox
    as="div"
    class="w-full font-normal"
    :disabled="!!disabled"
    nullable
    v-slot="{ open }"
    v-model="tmpValue"
  >
    <ComboboxLabel class="text-xs font-medium text-gray-500 block mb-1">
      {{ label }}
    </ComboboxLabel>

    <div ref="comboboxButton">
      <div
        class="relative w-full cursor-pointer bg-white text-left focus:outline-none disabled:cursor-default"
        :class="[
          noBorder
            ? 'border-transparent focus:border--transparent text-xs py-2'
            : 'border rounded-md border-gray-300 shadow-sm text-sm py-[1px]',
          disabled ? 'bg-gray-200' : 'bg-white',
        ]"
        @click="openDropdown"
      >
        <ComboboxInput
          class="w-full rounded border-none py-[3px] pr-12 focus:ring-0 disabled:bg-gray-200 disabled:cursor-default font-normal text-sm text-gray-900 truncate placeholder-[#999999]"
          :placeholder="placeholder"
          :display-value="(item) => item.name"
          @change="clearQuery($event.target.value)"
        />

        <span
          v-if="showXButton && !!tmpValue && !disabled"
          class="absolute inset-y-0 right-0 flex items-center mr-8 cursor-pointer"
          @click="emit('update:modelValue', '')"
        >
          <font-awesome-icon
            icon="fa-solid fa-x"
            class="h-3 w-3 text-gray-400"
          />
        </span>

        <ComboboxButton
          class="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-2 disabled:bg-gray-200 disabled:cursor-default"
          @click="clearQuery('')"
        >
          <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>
      </div>
    </div>

    <transition
      leave-active-class="transition duration-100 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div class="relative w-full">
        <div v-if="dropdownIsOpen" ref="comboboxOptions">
          <ComboboxOptions
            :key="open"
            class="z-[999999] absolute border overflow-y-auto w-full overscroll-x-hidden rounded-md bg-white p-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus-visible:border-indigo-500 sm:text-sm max-h-96"
            :class="[dropdownIsAbove ? 'bottom-full mb-8' : 'top-full']"
          >
            <ComboboxOption
              v-if="filteredOptions.length === 0"
              :disabled="true"
              as="template"
            >
              <li
                class="relative cursor-pointer select-none py-2 px-2 text-gray-900 font-normal"
              >
                <span class="block truncate text-left">
                  List is empty!
                  {{
                    optimizeSearch && checkOptimizedSearch
                      ? "Type to search, min 3 chars!"
                      : ""
                  }}
                </span>
              </li>
            </ComboboxOption>

            <ComboboxOption
              v-else
              v-slot="{ active, selected, disabled }"
              v-for="option in filteredOptions"
              :key="option"
              :value="option"
              :disabled="
                disabledOptions.includes(option[disabledOptionTrackBy])
              "
              as="template"
              @click="clearQuery('')"
            >
              <li
                class="rounded relative cursor-pointer select-none py-2 px-2"
                :class="[
                  disabled ? 'bg-gray-300' : '',
                  active ? 'bg-main1-dark text-white' : 'text-gray-900',
                  selected ? 'font-medium text-white bg-main1' : 'font-normal',
                ]"
              >
                <div class="text-left flex">
                  <p v-for="(track, i) in trackBy" :key="i">
                    {{ option[track] }} &nbsp;
                  </p>
                </div>
              </li>
            </ComboboxOption>

            <div
              v-if="
                optimizeSearch &&
                query.length > 2 &&
                filteredQueryOptions.length !== filteredOptions.length
              "
              @click.prevent="lazyLoadBatches++"
            >
              <div
                class="rounded relative cursor-pointer select-none py-2 px-2 text-main1 font-semibold hover:bg-gray-100"
              >
                <span class="block truncate text-left">
                  Show more options ...
                </span>
              </div>
            </div>
          </ComboboxOptions>
        </div>
      </div>
    </transition>
  </Combobox>
</template>
