<script setup>
import { ref, computed, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const emit = defineEmits(["mediaLimit"]);

const props = defineProps({
  showDropdown: {
    default: true,
  },
  showText: {
    default: true,
  },
  total: {
    type: Number,
    required: true,
    default: 100,
  },
  offset: {
    type: Number,
    required: true,
    default: 1,
  },
  limit: {
    type: Number,
    required: true,
    default: 20,
  },
  limitOptions: {
    type: Array,
    required: false,
    default: () => [10, 20, 30],
  },
});

const maxVisibleButtons = ref(5);

const currentPage = computed(() => {
  return route.query.page ? parseInt(route.query.page) : 1;
});

const limitTmp = computed({
  get() {
    return props.limit;
  },
  set(value) {
    emit("mediaLimit", value);
  },
});

const totalPages = computed(() => {
  return Math.ceil(props.total / limitTmp.value);
});

watchEffect(() => {
  if (currentPage.value > totalPages.value) {
    router.push({
      name: route.name,
      params: route.params,
      query: {
        ...route.query,
        page: totalPages.value === 0 ? 1 : totalPages.value,
      },
    });
  }
});

const showingTo = computed(() => {
  if (totalPages.value === 1) return props.total;

  return currentPage.value === totalPages.value
    ? props.total
    : props.offset + limitTmp.value;
});

// PAGINATION
const startPage = computed(() => {
  if (currentPage.value < 5) return 1;
  if (currentPage.value === totalPages.value)
    return totalPages.value - maxVisibleButtons.value + 1;
  return currentPage.value - 2;
});

const endPage = computed(() => {
  return Math.min(
    startPage.value + maxVisibleButtons.value - 1,
    totalPages.value
  );
});

const pages = computed(() => {
  const range = [];

  for (let i = startPage.value; i <= endPage.value; i++) {
    range.push({
      name: i,
      isDisabled: i === currentPage.value,
    });
  }

  return range;
});

const resetLink = () => {
  router.push({
    name: route.name,
    params: route.params,
    query: { ...route.query, page: 1 },
  });
};

const getSpecificLink = (link) => {
  return {
    query: { ...route.query, page: link },
  };
};

const getNextLink = () => {
  let nextPageLink = currentPage.value + 1;
  nextPageLink >= totalPages.value && (nextPageLink = totalPages.value);
  return {
    query: { ...route.query, page: nextPageLink },
  };
};

const getPreviousLink = () => {
  let previousPage = currentPage.value - 1;
  previousPage <= 1 && (previousPage = 1);
  return {
    query: { ...route.query, page: previousPage },
  };
};

const checkFirstLink = computed(() => {
  if (totalPages.value === currentPage.value) {
    if (currentPage.value === 6) return false;
  }
  return totalPages.value > 5 && currentPage.value > 4;
});
</script>

<template>
  <div
    class="bg-white flex items-center justify-between border-t border-gray-200"
  >
    <!--    SMALL SCREEN-->
    <div class="flex-1 flex justify-between lg:hidden py-1">
      <router-link
        :to="getPreviousLink()"
        :class="{ 'pointer-events-none cursor-default': offset === 0 }"
        class="relative items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
      >
        Previous
      </router-link>

      <router-link
        :to="getNextLink()"
        :class="{
          'pointer-events-none cursor-default': currentPage === totalPages,
        }"
        class="ml-1 relative items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
      >
        Next
      </router-link>
    </div>

    <!--    LARGE SCREEN-->
    <div class="hidden lg:flex-1 lg:flex lg:items-center lg:justify-between">
      <p v-if="showText && total > 0" class="text-sm text-gray-700 pt-1">
        Showing
        {{ " " }}
        <span class="font-medium">{{ offset ? offset : 1 }}</span>
        {{ " " }}
        to
        {{ " " }}
        <span class="font-medium">{{ showingTo }}</span>
        {{ " " }}
        of
        {{ " " }}
        <span class="font-medium">{{ total }}</span>
        {{ " " }}
        results
      </p>

      <div v-if="showDropdown" class="flex items-center mr-12 ml-auto">
        <p class="mr-4 text-sm text-gray-500">View per page</p>
        <select
          v-model="limitTmp"
          id="per_page"
          name="per_page"
          class="mt-1 block pl-3 pr-10 py-2 text-sm text-gray-700 focus:outline-none focus:ring-0 rounded-md border-transparent focus:border-transparent"
          @change="resetLink()"
        >
          <option v-for="opt in limitOptions" :value="opt" :key="opt">
            {{ opt }}
          </option>
        </select>
      </div>

      <nav
        class="relative z-0 inline-flex rounded-md -space-x-px"
        aria-label="Pagination"
      >
        <!-- PREVIOUS LINK -->
        <router-link
          :to="getPreviousLink()"
          :class="{ 'pointer-events-none cursor-default': offset === 0 }"
          class="relative inline-flex items-center px-2 pb-2 pt-3 rounded-l-md bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <font-awesome-icon
            icon="fa-solid fa-arrow-left"
            class="mr-3 h-4 w-4 items-center mt-1"
          />
          <span class="">Previous</span>
        </router-link>

        <!-- FIRST PAGE LINK -->
        <router-link
          v-if="totalPages > 5 && currentPage > 4"
          :to="getSpecificLink(1)"
          aria-current="page"
          :disabled="currentPage === totalPages"
          :class="{
            'z-10 border-t-2 border-main1 text-main1': currentPage === 1,
            'bg-white text-gray-500 hover:bg-gray-50': currentPage !== 1,
          }"
          class="relative inline-flex items-center px-4 pb-2 pt-3 text-sm font-medium"
        >
          1
        </router-link>

        <span
          v-if="checkFirstLink"
          class="relative inline-flex items-center px-4 pb-2 pt-3 bg-white text-sm font-medium text-gray-700"
        >
          ...
        </span>

        <span v-for="(page, i) in pages" :key="i">
          <router-link
            :to="getSpecificLink(page.name)"
            aria-current="page"
            :disabled="currentPage === totalPages"
            :class="{
              'z-10 border-t-2 border-main1 text-main1':
                (offset === 0 && page.name === 1) || currentPage === page.name,
              'bg-white text-gray-500 hover:bg-gray-50':
                currentPage !== page.name,
            }"
            class="relative inline-flex items-center px-4 pb-2 pt-3 text-sm font-medium"
          >
            {{ page.name }}
          </router-link>
        </span>

        <!-- LAST PAGE LINK -->
        <template v-if="totalPages > 5 && totalPages - currentPage > 2">
          <span
            v-if="totalPages > 6 && totalPages - currentPage > 3"
            class="relative inline-flex items-center px-4 pb-2 pt-3 bg-white text-sm font-medium text-gray-700"
          >
            ...
          </span>

          <router-link
            :to="getSpecificLink(totalPages)"
            aria-current="page"
            :disabled="currentPage === totalPages"
            :class="{
              'z-10 border-t-2 border-main1 text-main1':
                currentPage === totalPages,
              'bg-white text-gray-500 hover:bg-gray-50':
                currentPage !== totalPages,
            }"
            class="relative inline-flex items-center px-4 pb-2 pt-3 text-sm font-medium"
          >
            {{ totalPages }}
          </router-link>
        </template>

        <!-- NEXT LINK -->
        <router-link
          :to="getNextLink()"
          :class="{
            'pointer-events-none cursor-default': currentPage === totalPages,
          }"
          class="wtf relative inline-flex items-center px-2 pb-2 pt-3 rounded-l-md bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <span class="">Next</span>
          <font-awesome-icon
            icon="fa-solid fa-arrow-right"
            class="ml-3 h-4 w-4 items-center mt-1"
          />
        </router-link>
      </nav>
    </div>
  </div>
</template>
