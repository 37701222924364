<script setup>
import { computed, onMounted, ref, watchEffect } from "vue";
import { Menu, MenuButton, MenuItems } from "@headlessui/vue";
import {
  useElementBounding,
  useElementVisibility,
  useWindowSize,
} from "@vueuse/core/index";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const emit = defineEmits(["updateTabs"]);

const props = defineProps({
  checkedTabs: {
    type: Array,
    required: true,
  },
  fixedTabs: {
    type: Array,
    required: true,
    default: () => [],
  },
  allAvailableTabs: {
    type: Array,
    required: true,
    default: () => [],
  },
});

const initialRoute = ref(null);

onMounted(() => {
  initialRoute.value = route.name;
});

watchEffect(() => {
  if (initialRoute.value === route.name) {
    if (route.query.tab && !props.checkedTabs.includes(route.query.tab)) {
      router.push({
        name: route.name,
        params: route.params,
        query: { ...route.query, tab: props.fixedTabs[0] },
      });
    }
  }
});
const onItemSelect = (item) => {
  let checkedTabsTmp = JSON.parse(JSON.stringify(props.checkedTabs));

  if (props.fixedTabs.includes(item)) return;

  if (checkedTabsTmp.includes(item)) {
    checkedTabsTmp.splice(checkedTabsTmp.indexOf(item), 1);
  } else {
    checkedTabsTmp.push(item);
  }

  emit("updateTabs", checkedTabsTmp);
};

const inputFocus = ref(null);

const functionRef = (el) => {
  inputFocus.value = el;
};

const { right, bottom, top } = useElementBounding(inputFocus);
const targetIsVisible = useElementVisibility(inputFocus);
const { height } = useWindowSize();

const styleObject = computed(() => {
  let bottomTmp = {
    maxHeight: height.value - bottom.value - 50 + "px",
    top: bottom.value + 4 + "px",
    width: 175 + "px",
    left: right.value - 175 + "px",
  };
  let topTmp = {
    maxHeight: top.value - 50 + "px",
    top: top.value - 4 + "px",
    width: 175 + "px",
    left: right.value - 175 + "px",
    transform: "translateY(-100%)",
    marginBottom: 4 + "px",
  };
  return height.value - bottom.value > 200 ? bottomTmp : topTmp;
});
</script>

<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        :ref="functionRef"
        class="flex items-center bg-gray-100 hover:bg-gray-200 h-8 w-8 justify-center rounded"
      >
        <font-awesome-icon
          icon="fa-solid fa-gear"
          size="sm"
          class="text-gray-500"
        />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <Teleport to="body">
        <MenuItems
          v-if="targetIsVisible"
          class="absolute overflow-y-auto overscroll-x-hidden mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30"
          :style="styleObject"
        >
          <div class="w-[150px] p-3.5">
            <template v-for="item in allAvailableTabs" :key="item.to">
              <div v-if="item.to" @click="onItemSelect(item.to)">
                <div
                  class="text-gray-700 flex py-2 text-sm cursor-pointer hover:bg-gray-100 hover:text-gray-900"
                >
                  <div class="mx-2 flex">
                    <BaseCheckboxNew
                      class="w-2 h-2"
                      :id="item.to"
                      :round="true"
                      :disabled="fixedTabs.includes(item.to)"
                      :model-value="checkedTabs.includes(item.to)"
                    />
                  </div>
                  <p class="ml-1.5 text-xs">
                    {{ item.text }}
                  </p>
                </div>
              </div>
            </template>
          </div>
        </MenuItems>
      </Teleport>
    </transition>
  </Menu>
</template>
