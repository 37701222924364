import { defineStore } from "pinia";
import axios from "axios";
import { app } from "@/main";
import axiosClient from "@/axiosClient";
import useListMediaStore from "@/stores/media/listMedia.js";
import { useGeneralStore } from "@/stores/general";
import {
  getBooleanFromString,
  useGetPreferencesArray,
} from "@/composables/getPreferencesArray";
import { useGetMediaViews } from "@/composables/media/getMediaViewsArray";
import { defaultPreferences } from "@/helpers/defaultPreferences";

const URL = import.meta.env.VITE_API_URL;

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    is_loggedin: false,
    checkingAccessToken: false,
    authErrorMessage: null,
    authErrorStatus: false,
    openMfaModal: false,
    mfaErrorMessage: false,
    user: null,
    userLoading: false,
    imageCropped: null,
    uploadWidgetLoading: false,
    verifyEmailHash: null,
    loginFailed: false,
    showWelcomeModal: false,
    advanced: false,
    showNotificationCentre: false,
    mobileSidebarOpen: false,
    sidebarSimple: false,
    openHelpAndFeedbackModal: false,
    impersonateResetMedia: false,
  }),
  actions: {
    login(payload) {
      let params = {
        return: ["client.limits", "notices"],
      };
      return new Promise((resolve, reject) => {
        axios
          .post(URL + "/user/login/", { ...payload, ...params })
          .then((response) => {
            if (response.data.code === 0) {
              const token = response.data.data.auth_token;
              localStorage.setItem("access_token", token);

              this.loginFailed = false;
              this.is_loggedin = true;
              this.user = response.data.data;

              this.updatePreferences();

              this.openMfaModal = false;
              this.mfaErrorMessage = false;

              app.config.globalProperties.$router.push(
                this.user.force_pay_invoice_id ? "/pre-payment" : "/"
              );

              app.config.globalProperties.$notify({
                type: "success",
                text: "You are successfully logged in!",
              });
            } else if (response.data.code === 423) {
              this.openMfaModal = true;
            } else if (response.data.code === 424) {
              this.openMfaModal = true;
              this.mfaErrorMessage = true;
            } else {
              this.loginFailed = true;
              this.is_loggedin = false;
            }
            resolve(response);
          })
          .catch((error) => {
            this.authErrorMessage = "Nepareizs e-pasts un/vai parole!";
            this.is_loggedin = false;
            this.openMfaModal = false;

            localStorage.removeItem("access_token");
            reject(error);
          });
      });
    },

    logout() {
      const token = localStorage.getItem("access_token");
      if (!this.is_loggedin && !token) return;
      this.checkingAccessToken = true;

      return new Promise((resolve, reject) => {
        axios
          .post(
            URL + "/user/logout/",
            {},
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          )
          .then(async () => {
            localStorage.removeItem("access_token");
            this.is_loggedin = false;
            this.user = null;
            app.config.globalProperties.$router.push({ name: "login" });
            this.checkingAccessToken = false;
          })
          .catch((error) => {
            this.is_loggedin = false;
            localStorage.removeItem("access_token");

            reject(error);
          });
      });
    },

    getUser() {
      this.userLoading = true;
      let params = {
        return: ["client.limits", "notices"],
      };

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/user/get", { params })
          .then((response) => {
            this.userLoading = false;

            if (response.data.code === 0) {
              this.checkingAccessToken = false;
              this.user = response.data.data;
              this.updatePreferences();

              resolve(response);
            }
          })
          .catch((error) => {
            this.userLoading = false;
            reject(error);
          });
      });
    },

    updatePreferences() {
      this.user.preferences["filesDropdown"] = this.user.preferences
        .filesDropdown
        ? useGetPreferencesArray(
            this.user.preferences.filesDropdown,
            defaultPreferences.filesDropdown.fixed
          )
        : defaultPreferences.filesDropdown.default;

      this.user.preferences["editTabs"] = this.user.preferences.editTabs
        ? useGetPreferencesArray(
            this.user.preferences.editTabs,
            defaultPreferences.editTabs.fixed
          )
        : defaultPreferences.editTabs.default;

      this.user.preferences["liveTabs"] = this.user.preferences.liveTabs
        ? useGetPreferencesArray(
            this.user.preferences.liveTabs,
            defaultPreferences.liveTabs.fixed
          )
        : defaultPreferences.liveTabs.default;

      //newMediaViews
      useListMediaStore().mediaViewsNew =
        this.user.preferences.mediaViews &&
        this.user.preferences.mediaViews !== "null"
          ? useGetMediaViews(this.user.preferences.mediaViews)
          : [...defaultPreferences.defaultMediaViews];

      //get active view
      if (this.user.preferences.activeView) {
        const checkIfViewExists = useListMediaStore().mediaViewsNew.some(
          (item) => item.id === this.user.preferences.activeView
        );
        useListMediaStore().activeView = checkIfViewExists
          ? this.user.preferences.activeView
          : "default";
      } else {
        // check if there is view in localstorage, then delete it and create new
        const storedView = localStorage.getItem("activeMediaView");
        if (storedView) localStorage.removeItem("activeMediaView");
        const checkIfViewExists = useListMediaStore().mediaViewsNew.some(
          (item) => item.id === storedView
        );
        useListMediaStore().activeView = checkIfViewExists
          ? storedView
          : "default";
      }

      //disable welcome msg
      if (app.config.globalProperties.$route.name === "login") {
        this.showWelcomeModal = !getBooleanFromString(
          this.user.preferences["disableWelcomeMsg"]
        );
      }

      //Advanced switch
      this.advanced = getBooleanFromString(this.user.preferences["advanced"]);
      this.user.preferences["advanced"] = this.advanced.toString();

      //reset media view when impersonate
      this.impersonateResetMedia = getBooleanFromString(
        this.user.preferences["impersonate_reset_media"]
      );
      this.user.preferences["impersonate_reset_media"] =
        this.impersonateResetMedia.toString();
    },

    savePreferences({ preferences, msg = "" }) {
      const payload = {
        preferences: { ...preferences },
      };

      return new Promise((resolve, reject) => {
        axiosClient
          .put(URL + "/user/update", payload)
          .then((response) => {
            if (response.data.code === 0 && msg) {
              app.config.globalProperties.$notify({
                type: "success",
                text: msg,
              });
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getRedirectedUser(unifiedToken, pathName) {
      return new Promise((resolve, reject) => {
        axios
          .get(URL + "/user/get?return[]=client.limits&return[]=notices", {
            headers: {
              Authorization: "Bearer " + unifiedToken,
            },
          })
          .then((response) => {
            if (response.data.code === 0) {
              localStorage.setItem("access_token", unifiedToken);
              this.is_loggedin = true;
              this.user = response.data.data;
              this.updatePreferences();
              useGeneralStore().getCountryList();
              useGeneralStore().getLanguageList();

              app.config.globalProperties.$router.push(pathName);
            } else {
              this.is_loggedin = false;
              app.config.globalProperties.$router.push({ name: "login" });
            }
          })
          .catch((error) => {
            this.is_loggedin = false;
            app.config.globalProperties.$router.push({ name: "login" });
            localStorage.removeItem("access_token");

            reject(error);
          });
      });
    },

    saveProfile() {
      this.userLoading = true;
      let params = this.user;
      if (this.imageCropped != null) params.image = this.imageCropped;

      if (this.user.preferences) delete params.preferences;

      return new Promise((resolve, reject) => {
        axiosClient
          .put(URL + "/user/update", params)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: response.data.msg,
              });
              this.userLoading = false;
            } else {
              this.getUser();
            }
            resolve(response);
          })
          .catch((error) => {
            app.config.globalProperties.$notify({
              title: "Error!",
              type: "error",
              text: "Something went wrong!",
            });
            reject(error);
          });
      });
    },

    deleteUser() {
      return new Promise((resolve, reject) => {
        axiosClient
          .delete(URL + "/user/delete")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    confirmDeleteUser(hash) {
      return new Promise((resolve, reject) => {
        axiosClient
          .delete(URL + "/user/deletebyhash?hash=" + hash)
          .then((response) => {
            app.config.globalProperties.$notify({
              type: "success",
              text: response.data.msg,
            });
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    sendPwdResetEmail(email) {
      return new Promise((resolve, reject) => {
        axios
          .post(URL + "/user/sendPwdResetEmail/", {
            email: email,
            reset_url: window.location.origin + "/reset-password#",
          })
          .then((response) => {
            if (response.data.code === 0) {
              this.loginFailed = false;
              app.config.globalProperties.$router.push("/login");
              app.config.globalProperties.$notify({
                type: "success",
                text: response.data.msg,
              });
            } else {
              this.loginFailed = true;
            }

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getInfoByHash(hash) {
      this.userLoading = true;

      return new Promise((resolve, reject) => {
        axios
          .post(URL + "/user/getResetInfoByHash/", { hash: hash })
          .then((response) => {
            this.authErrorStatus = false;
            this.authErrorMessage = null;

            this.userLoading = false;

            if (response.data.code !== 0) {
              app.config.globalProperties.$router.push("/login");
            }
            resolve(response);
          })
          .catch((error) => {
            app.config.globalProperties.$router.push("/login");
            reject(error);
          });
      });
    },

    resetPassword(hash, id, pwd) {
      return new Promise((resolve, reject) => {
        axios
          .post(URL + "/user/resetPassword/", {
            hash: hash,
            user_id: id,
            password: pwd,
          })
          .then((response) => {
            if (response.data.code === 0) {
              // response.data.code &&
              app.config.globalProperties.$router.push("/login");
              app.config.globalProperties.$notify({
                type: "success",
                text: response.data.msg,
              });
            }

            resolve(response);
          })
          .catch((error) => {
            this.authErrorMessage = "Probably incorrect email!";

            reject(error);
          });
      });
    },

    simplyLogout() {
      const token = localStorage.getItem("access_token");

      return new Promise((resolve, reject) => {
        axios
          .post(
            URL + "/user/logout/",
            {},
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          )
          .then(async () => {
            localStorage.removeItem("access_token");
            this.is_loggedin = false;
            this.checkHash();
          })
          .catch((error) => {
            this.is_loggedin = false;
            localStorage.removeItem("access_token");

            reject(error);
          });
      });
    },

    checkHash() {
      return new Promise((resolve, reject) => {
        axios
          .get(URL + "/client/checkregisterhash/?hash=" + this.verifyEmailHash)
          .then(async (response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$router.push({
                name: "verify-email",
              });
              app.config.globalProperties.$notify({
                type: "success",
                text: response.data.msg,
              });
            } else {
              app.config.globalProperties.$router.push("/login");
            }
            resolve(response);
          })
          .catch((error) => {
            app.config.globalProperties.$router.push("/login");
            reject(error);
          });
      });
    },

    registerHash({ password }) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            URL +
              "/client/RegisterHash/?hash=" +
              this.verifyEmailHash +
              "&password=" +
              password +
              "&timezone=" +
              Intl.DateTimeFormat().resolvedOptions().timeZone
          )
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$router.push({
                name: "account-created",
              });
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: response.data.msg,
                duration: 2000,
              });
            }
            resolve(response);
          })
          .catch((error) => {
            app.config.globalProperties.$router.push("/login");
            reject(error);
          });
      });
    },

    enableMfa(payload) {
      return new Promise((resolve, reject) => {
        axiosClient
          .put(URL + "/user/mfa/enable2fa", { ...payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    disableMfa() {
      return new Promise((resolve, reject) => {
        axiosClient
          .put(URL + "/user/mfa/disable2fa")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    startImpersonating({ client_id, group_id }) {
      if (this.impersonateResetMedia) {
        this.resetMediaToDefaultView();
      }

      const params = {
        client_id,
        ...(group_id && { group_id }),
      };

      return new Promise((resolve, reject) => {
        axiosClient
          .post(URL + "/admin/impersonation/on", params)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                type: "success",
                text: response.data.msg,
              });
              // location.reload()
              const locationName = app.config.globalProperties.$route.name;
              app.config.globalProperties.$router
                .replace({ name: locationName })
                .then(() => {
                  location.reload();
                });
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    stopImpersonating() {
      if (this.impersonateResetMedia) {
        this.resetMediaToDefaultView();
      }

      return new Promise((resolve, reject) => {
        axiosClient
          .put(URL + "/admin/impersonation/off")
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                type: "success",
                text: response.data.msg,
              });
              const locationName = app.config.globalProperties.$route.name;
              app.config.globalProperties.$router
                .replace({ name: locationName })
                .then(() => {
                  location.reload();
                });
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    resetMediaToDefaultView() {
      useListMediaStore().activeView = "default";

      useListMediaStore().mediaViewsNew[0].selectedSearchFilters = [];

      useListMediaStore().mediaViewsNew[0].selectedSearchWildcards = [];

      useListMediaStore().mediaViewsNew[0].selectedFolder = 0;

      useListMediaStore().mediaViewsNew[0].searchValue = null;

      useListMediaStore().mediaViewsNew[0].mediaListParams = JSON.parse(
        JSON.stringify(defaultPreferences.mediaListParams)
      );
    },

    dismissNotice({ id }) {
      const params = {
        notice_id: id,
      };
      return new Promise((resolve, reject) => {
        axiosClient
          .post(URL + "/user/dismissnotice", params)
          .then((response) => {
            if (response.data.code === 0) {
              this.user.notices = this.user.notices.filter(
                (notice) => notice.id !== id
              );
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },

  persist: {
    paths: ["user"],
  },
});
