<script setup>
import {
  Dialog,
  DialogPanel,
  DialogOverlay,
  TransitionChild,
  TransitionRoot, DialogTitle
} from "@headlessui/vue";
import { Cropper }  from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { ref } from "vue";

defineProps({
  showModal: {
    type: Boolean,
    required: true
  },
  outputFile: {
    type: [String, null],
    required: true,
  },
  customAspect: {
    type: Boolean,
    required: false,
    default: false
  }
});
const emit = defineEmits(["accept", "cancel"]);
const coords = ref({
  width: 0,
  height: 0,
  left: 0,
  top: 0,
})
const cropper = ref()

const change = ({coordinates}) => {
  coords.value = coordinates;
}
const crop = () => {
  const { coordinates, canvas } = cropper.value.getResult();
  coords.value = coordinates;
  const tmpOutputFile = canvas.toDataURL();
  emit("accept", tmpOutputFile)
}

const defaultSize = ({ imageSize, visibleArea }) => {
  return {
    width: (visibleArea || imageSize).width,
    height: (visibleArea || imageSize).height,
  };
}
</script>

<template>
  <TransitionRoot appear as="template" :show="showModal">
    <Dialog as="div" class="relative z-[999999]">

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div
          class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 bg-gray-500 opacity-75"
            />
          </TransitionChild>

          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative bg-white rounded p-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full"
            >
              <DialogTitle as="h2" class="text-sm text-gray-600 mb-5">
              </DialogTitle>

              <Transition mode="out-in">
                <div>

                  <div v-if="!customAspect" class="flex items-center border-[6px] border-gray-500 mb-2.5">
                    <Cropper
                      ref="cropper"
                      class="cropper"
                      :src="outputFile"
                      :stencil-props="{aspectRatio: 1}"
                      :autoZoom="false"
                      :transitions="false"
                      :resizeImage="true"
                      :default-size="defaultSize"
                      @change="change"
                    />
                  </div>

                  <div v-if="customAspect" class="flex items-center border-[6px] border-gray-500 mb-2.5">
                    <Cropper
                      ref="cropper"
                      class="cropper"
                      :src="outputFile"
                      :autoZoom="false"
                      :transitions="false"
                      :resizeImage="true"
                      :default-size="defaultSize"
                      @change="change"
                    />
                  </div>

                  <div class="mt-5 sm:mt-6 flex justify-end">
                    <button
                      type="button"
                      class="inline-flex justify-center items-center rounded-md border-none mr-5 py-1 bg-transparent text-xs text-gray-700 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm"
                      @click="emit('cancel')"
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      class="w-full max-w-[115px] inline-flex justify-center rounded-md border border-main1 shadow-sm px-4 py-1 bg-transparent text-xs font-medium text-main1 hover:bg-main1 hover:text-white focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm"
                      @click="crop()"
                    >
                      Accept
                    </button>
                  </div>
                </div>
              </Transition>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
