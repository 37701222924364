<script setup>
import { computed } from "vue";
import { Switch } from "@headlessui/vue";

const props = defineProps({
  modelValue: {
    type: [Boolean, Number],
    default: false,
  },
  big: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["onUpdate:modelValue", "update:modelValue"]);

const modelValueTmp = computed({
  get() {
    if (typeof props.modelValue === "boolean") {
      return props.modelValue;
    }
    if (props.modelValue === "true") {
      return true;
    }
    if (props.modelValue === "false") {
      return false;
    }
    return props.modelValue === 1;
  },
  set(evt) {
    emit("onUpdate:modelValue", evt);
    emit("update:modelValue", evt);
  },
});
</script>

<template>
  <Switch
    v-model="modelValueTmp"
    :aria-disabled="disabled"
    :class="[
      modelValueTmp && !disabled ? 'bg-main1' : 'bg-gray-200',
      big ? 'h-6 w-11 border-2' : 'h-[16px] w-[30px] border',
      !disabled ? 'cursor-pointer' : 'cursor-default',
      'relative inline-flex flex-shrink-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0',
    ]"
  >
    <span
      :class="[
        modelValueTmp && big ? 'translate-x-5' : '',
        modelValueTmp && !big ? 'translate-x-[14px]' : '',
        big ? 'h-5 w-5' : 'h-[14px] w-[14px]',
        'pointer-events-none relative inline-block  rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
      ]"
    >
      <span
        :class="[
          modelValueTmp
            ? 'opacity-0 ease-out duration-100'
            : 'opacity-100 ease-in duration-200',
          'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
        ]"
        aria-hidden="true"
      >
        <svg
          :class="[big ? 'h-3 w-3' : 'h-2.5 w-2.5 ', 'text-gray-400']"
          fill="none"
          viewBox="0 0 12 12"
        >
          <path
            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>

      <span
        :class="[
          modelValueTmp
            ? 'opacity-100 ease-in duration-200'
            : 'opacity-0 ease-out duration-100',
          'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
        ]"
        aria-hidden="true"
      >
        <svg
          :class="[big ? 'h-3 w-3' : 'h-2.5 w-2.5 ', 'text-main1' && !disabled]"
          fill="currentColor"
          viewBox="0 0 12 12"
        >
          <path
            d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
          />
        </svg>
      </span>
    </span>
  </Switch>
</template>
