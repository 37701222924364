import { defineStore } from "pinia";
import axiosClient from "@/axiosClient";

const URL = import.meta.env.VITE_API_URL;

export default defineStore({
  id: "useClientStore",
  state: () => ({
    clientListLoading: false,
    clientList: null,
  }),
  actions: {
    getClientList() {
      this.clientListLoading = true;
      let params = {
        limit: 1000,
      };
      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/admin/client/list/", { params })
          .then((response) => {
            if (response.data.code === 0) {
              this.clientList = response.data.data;
            }
            this.clientListLoading = false;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});
