import { defineStore } from "pinia";
import axiosClient from "@/axiosClient";
import { filterDuplicateValues } from "@/composables/filterDuplicateValues";

const URL = import.meta.env.VITE_API_URL;

export const useGeneralStore = defineStore({
  id: "useGeneralStore",
  state: () => ({
    countryListLoading: false,
    countryList: null,
    languageListLoading: false,
    languageList: null,
  }),

  actions: {
    getCountryList() {
      this.countryListLoading = true;

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/general/getcountrylist")
          .then((response) => {
            if (response.data.code === 0) {
              this.countryList = response.data.data;
              this.countryListLoading = false;
            }
            resolve(response);
          })
          .catch((error) => {
            this.countryListLoading = false;
            reject(error);
          });
      });
    },
    getLanguageList() {
      this.languageListLoading = true;

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/general/getlanguagelist")
          .then((response) => {
            if (response.data.code === 0) {
              this.languageList = filterDuplicateValues(response.data.data);
              this.languageListLoading = false;
            }
            resolve(response);
          })
          .catch((error) => {
            this.languageListLoading = false;

            reject(error);
          });
      });
    },
  },
  getters: {
    allCountryCodes: (state) => {
      if (state.countryList) {
        return Object.values(state.countryList).reduce(
          (accumulator, currentValue) => {
            return [...accumulator, ...Object.keys(currentValue)];
          },
          []
        );
      } else return [];
    },

    countriesRegions: (state) => {
      if (state.countryList) {
        let objectTmp = {};

        for (let region in state.countryList) {
          objectTmp[region] = {};
          for (let country in state.countryList[region]) {
            objectTmp[region][country] =
              state.countryList[region][country].name;
          }
        }

        return objectTmp;
      } else return {};
    },

    languageMultiselectOptions: (state) => {
      if (state.languageList) {
        return Object.entries(state.languageList).map(([key, value]) => {
          return { id: key, name: value };
        });
      } else return [];
    },

    languageSelectOptions: (state) => {
      if (state.languageList) {
        return Object.entries(state.languageList).map(([key, value]) => {
          return { value: key, name: value };
        });
      } else return [];
    },
  },
});
