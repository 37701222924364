<script setup>
import useTooltip from "@/composables/global/tooltip";

const props = defineProps({
  tooltipText: {
    type: String,
    required: false,
    default: "",
  },
  tooltipColor: {
    type: String,
    required: false,
    default: "",
  },
  tooltipSize: {
    type: String,
    required: false,
    default: "",
  },
  placement: {
    type: String,
    required: false,
    default: "top",
  },
  customWidth: {
    type: String,
    required: false,
    default: "",
  },
  multiLine: {
    type: Boolean,
    required: false,
    default: false,
  },
  showDefaultIcon: {
    type: Boolean,
    required: false,
    default: true,
  },
  noIconText: {
    type: String,
    required: false,
    default: "Learn more",
  },
  noIconTextStyle: {
    type: String,
    required: false,
    default: "text-bs-black font-medium text-xs",
  },
});

const { showTooltip, reference, floating, floatingStyles } = useTooltip(
  props.placement
);
</script>

<template>
  <div>
    <div
      v-if="tooltipText"
      class="flex ml-1 h-fit w-fit"
      :class="tooltipSize ? tooltipSize : 'h-3 w-3'"
      ref="reference"
      @mouseover="showTooltip = true"
      @mouseleave="showTooltip = false"
    >
      <font-awesome-icon
        v-if="showDefaultIcon"
        icon="fa solid fa-info-circle"
        :class="[
          tooltipColor ? tooltipColor : 'text-gray-500',
          tooltipSize ? tooltipSize : 'h-3 w-3',
          'cursor-pointer font-medium my-auto text-gray-500',
        ]"
      />

      <slot />
    </div>

    <div
      v-show="showTooltip"
      ref="floating"
      :style="floatingStyles"
      :class="[
        customWidth ? customWidth : '',
        'flex bg-gray-400 p-2 rounded z-[99999999] text-xs text-white font-medium text-wrap',
      ]"
    >
      <span :class="[multiLine ? 'whitespace-pre' : '']">
        {{ tooltipText }}
      </span>
    </div>
  </div>
</template>
