<script setup>
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

const topNotices = computed(() => {
  if (!user.value || !user.value.notices) return [];
  return user.value.notices.filter((item) => item.position === "top");
});

const bottomNotices = computed(() => {
  if (!user.value || !user.value.notices) return [];
  return user.value.notices.filter((item) => item.position === "bottom");
});
</script>

<template>
  <div
    v-if="topNotices && topNotices.length"
    class="absolute top-0 right-1/2 translate-x-1/2 mt-2.5 mx-2.5 z-[9998] w-full lg:max-w-5xl lg:min-w-5xl"
  >
    <div
      v-for="(notice, i) in topNotices"
      :key="i"
      class="rounded border py-2.5 px-5 mb-2.5 relative"
      :class="[
        notice.type === 'maintenance' &&
          'border-tet-yellow bg-tet-yellow-100 text-tet-yellow',
        notice.type === 'info' &&
          'border-tet-blue bg-tet-blue-100 text-tet-blue',
      ]"
    >
      <div class="flex w-full">
        <div class="flex mr-1.5">
          <font-awesome-icon
            v-if="notice.type === 'maintenance'"
            icon="fa-solid fa-exclamation"
            class="font-bold my-auto"
          />
          <font-awesome-icon
            v-if="notice.type === 'info'"
            icon="fa-solid fa-info"
            class="font-bold my-auto"
          />
        </div>
        <div class="font-medium ml-5 my-auto" v-html="notice.message"></div>
      </div>

      <font-awesome-icon
        v-if="notice.is_dismissible"
        icon="fa-solid fa-x"
        class="w-3 h-3 absolute z-[999] top-0 right-0 transition duration-250 ease-in-out hover:scale-105 hover:text-main1 p-1 cursor-pointer"
        @click="authStore.dismissNotice({ id: notice.id })"
      />
    </div>
  </div>

  <div
    v-if="bottomNotices && bottomNotices.length"
    class="absolute bottom-0 right-1/2 translate-x-1/2 mb-2.5 mx-2.5 z-[9998] w-full lg:max-w-5xl lg:min-w-5xl"
  >
    <div
      v-for="(notice, i) in bottomNotices"
      :key="i"
      class="rounded border py-2.5 px-5 mb-2.5 relative"
      :class="[
        notice.type === 'maintenance' &&
          'border-tet-yellow bg-tet-yellow-100 text-tet-yellow',
        notice.type === 'info' &&
          'border-tet-blue bg-tet-blue-100 text-tet-blue',
      ]"
    >
      <div class="flex w-full">
        <div class="flex mr-1.5">
          <font-awesome-icon
            v-if="notice.type === 'maintenance'"
            icon="fa-solid fa-exclamation"
            class="font-bold my-auto"
          />
          <font-awesome-icon
            v-if="notice.type === 'info'"
            icon="fa-solid fa-info"
            class="font-bold my-auto"
          />
        </div>
        <div class="font-medium ml-5 my-auto" v-html="notice.message"></div>
      </div>

      <font-awesome-icon
        icon="fa-solid fa-x"
        class="w-3 h-3 absolute z-[999] top-0 right-0 transition duration-250 ease-in-out hover:scale-105 hover:text-main1 p-1 cursor-pointer"
        @click="authStore.dismissNotice({ id: notice.id })"
      />
    </div>
  </div>
</template>
