export function filterDuplicateValues(obj) {
  const filteredObj = {};
  const valuesSet = new Set();

  for (const key in obj) {
    const value = obj[key];
    if (!valuesSet.has(value)) {
      filteredObj[key] = value;
      valuesSet.add(value);
    }
  }

  return filteredObj;
}