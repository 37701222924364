export function useGetPreferencesArray(savedArray, fixedArray) {
  let arrayTmp = [];

  try {
    const parsedFilesTabs = JSON.parse(savedArray);
    if (parsedFilesTabs && typeof parsedFilesTabs === "object") {
      for (const [key, value] of Object.entries(parsedFilesTabs)) {
        if (value === "true") arrayTmp.push(key);
      }
    }
  } catch (error) {
    if (typeof savedArray === "string" && savedArray.length) {
      arrayTmp = savedArray.split(",");
    }
  }

  return [...new Set([...arrayTmp, ...fixedArray])];
}

export function getBooleanFromString(savedArray) {
  return (
    typeof savedArray === "string" && savedArray.toLowerCase() === "true"
  );
}
