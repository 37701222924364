import { defineStore } from "pinia";
import axiosClient from "@/axiosClient";
import { app } from "@/main";

const URL = import.meta.env.VITE_API_URL;

export const useTicketsStore = defineStore({
  id: "ticketsStore",
  state: () => ({
    ticketList: null,
    ticketListLoading: false,
    limit: 10,
    page: 1,
    offset: 0,
    total: 0,
    status: ["with_support", "with_client"],
    ticket: null,
    ticketLoading: null,
    newTicket: {
      title: "",
      sla_level: "Low",
      message: "",
      attachments: [],
    },
    newComment: {
      id: null,
      message: "",
      attachments: [],
    },
  }),

  actions: {
    getTickets({ page }) {
      this.ticketListLoading = true;

      let params = {
        offset: this.page ? this.page * 10 - 10 : 0,
        limit: 10,
        order_by: "id",
        order_dir: "desc",
        ...(this.status && {
          status: this.status,
        }),
      };

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/ticket/list/", { params })
          .then((response) => {
            if (response.data.code === 0) {
              this.limit = response.data.limit;
              this.offset = response.data.offset;
              this.total = response.data.total;

              this.ticketList = response.data.data;
            }
            this.ticketListLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.ticketListLoading = false;
            reject(error);
          });
      });
    },
    getTicket(id) {
      this.ticketLoading = true;

      return new Promise((resolve, reject) => {
        axiosClient
          .get(
            URL +
              "/ticket/list?id=" +
              id +
              "&order_by=id&order_dir=desc&return=messages,attachments&limi"
          )
          .then((response) => {
            if (response.data.code === 0) {
              this.ticket = response.data.data[0];
            }
            this.ticketLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.ticketLoading = false;
            reject(error);
          });
      });
    },
    addComment(payload) {
      if (!payload.message || payload.message.length < 1) {
        delete payload.message;
      }

      return new Promise((resolve, reject) => {
        axiosClient
          .post(URL + "/ticket/addmessage", payload)
          .then((response) => {
            if (response.data.code !== 0) {
              app.config.globalProperties.$notify({
                title: "Error!",
                type: "error",
                text: response.data.msg,
              });
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    createTicket() {
      if (this.newTicket.attachments.length === 0) {
        delete this.newTicket.attachments;
      }

      if (!this.newTicket.message || this.newTicket.message.length < 1) {
        delete this.newTicket.message;
      }

      this.newTicket.sla_level = this.newTicket.sla_level.toLowerCase();

      return new Promise((resolve, reject) => {
        axiosClient
          .post(URL + "/ticket/create", this.newTicket)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: response.data.msg,
              });
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});
