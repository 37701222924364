<script setup>
import Layout from "@/components/Layout/Layout.vue";
import { RouterView, useRoute, useRouter } from "vue-router";
import { ref, onMounted, watch, onBeforeMount } from "vue";
import { useAuthStore } from "@/stores/auth.js";
import { useGeneralStore } from "@/stores/general";

const showNav = ref(false);
const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const generalStore = useGeneralStore();

onBeforeMount(() => {
  let environment = import.meta.env.VITE_ENVIRONMENT;

  const favicon = document.getElementById("favicon");
  favicon.href = "/favicon-16x16.png";

  if (environment === "localhost" || environment === "dev")
    favicon.href = "/favicon-16x16_dev.png";
  else if (environment === "stage") favicon.href = "/favicon-16x16_stage.png";
  else favicon.href = "/favicon-16x16.png";
});

onMounted(() => {
  localStorage.getItem("access_token")
    ? (authStore.is_loggedin = true)
    : (authStore.is_loggedin = false);
  if (authStore.is_loggedin) {
    authStore.checkingAccessToken = true;
    authStore.getUser().then((res) => {
      if (res.data.code === 0) {
        generalStore.getCountryList();
        generalStore.getLanguageList();
      } else {
        router.push("/login");
      }
    });
  }

  authStore.uploadWidgetLoading = true;
  let uploadWidget = document.createElement("script");
  uploadWidget.setAttribute("src", import.meta.env.VITE_UPLOAD_WIDGET_URL);
  uploadWidget.setAttribute("type", "text/javascript");
  uploadWidget.setAttribute("data-uploadwidget", "");
  document.head.appendChild(uploadWidget);
  uploadWidget.onload = () => {
    authStore.uploadWidgetLoading = false;
  };
});

watch(route, async (to) => {
  showNav.value = to.meta.navbar;
  const title = to.meta.title ? `Backscreen - ${to.meta.title}` : null;
  document.title = title || "Backscreen";
});

router.beforeEach((to) => {
  if (to.redirectedFrom) {
    if (to.redirectedFrom.query.unifiedToken) {
      let query = { ...to.redirectedFrom.query };
      delete query.unifiedToken;
      authStore.getRedirectedUser(to.redirectedFrom.query.unifiedToken, {
        name: to.redirectedFrom.name,
        params: to.redirectedFrom.params,
        query,
      });
    }
  } else if (to.query.unifiedToken) {
    let query = { ...to.query };
    delete query.unifiedToken;

    authStore.getRedirectedUser(to.query.unifiedToken, {
      name: to.name,
      params: to.params,
      query,
    });
  }
  if (to.name === "verify-email" && to.params.hash) {
    authStore.verifyEmailHash = to.params.hash;
    if (authStore.verifyEmailHash && authStore.is_loggedin) {
      authStore.simplyLogout().then(() => {
        authStore.checkHash();
      });
    } else {
      authStore.checkHash();
    }
  }
  if (to.name === "verify-email" && !authStore.verifyEmailHash) {
    router.push("/login");
  }
  if (authStore.user) {
    if (to.name !== "pre-payment" && authStore.user.force_pay_invoice_id) {
      authStore.logout();
    }
  }
});
</script>

<template>
  <div class="min-h-screen">
    <template v-if="authStore.checkingAccessToken">
      <div class="loader"></div>
    </template>

    <template v-else-if="showNav">
      <Layout />
    </template>

    <template v-else-if="!showNav">
      <RouterView />
    </template>

    <Teleport to="body">
      <notifications
        position="bottom right"
        animation-name="v-fade-left"
        :duration="8000"
      />
    </Teleport>
  </div>
</template>

<style>
.v-fade-left-enter-active,
/* .v-fade-left-leave-active, */
.v-fade-left-leave-to-active {
  opacity: 0;
  transform: translateX(500px) scale(0.2);
}
.v-fade-left-move {
  transition: all 0.5s;
}
.v-fade-left-enter,
.v-fade-left-leave-to {
  opacity: 0;
  transform: translateX(500px) scale(0.2);
}
.vue-notification-group {
  z-index: 99999999999 !important;
}
</style>
