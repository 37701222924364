import axios from "axios";
import { app } from "@/main";

import { useAuthStore } from "@/stores/auth.js";

const axiosClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

axiosClient.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("access_token");

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    config.headers["Request-Timezone"] = "user";
    config.headers["Request-Time-Format"] = "user";
    config.headers["Vsh-Fe"] = 1;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    if (response.data.code === 401) {
      useAuthStore().logout();
    } else if (response.data.code !== 0 && response.data.msg) {
      app.config.globalProperties.$notify({
        title: "Error!",
        type: "error",
        text: response.data.msg,
      });
    }
    return response;
  },
  function (error) {
    if (error.code !== "ERR_CANCELED") {
      if (error.response && error.response.status === 401) {
        useAuthStore().logout();
      }
      app.config.globalProperties.$notify({
        title: "Error!",
        type: "error",
        text: "Something went wrong! Please try again later!",
      });
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
