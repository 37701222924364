<script setup>
import useClientStore from "@/stores/AdminClients.js";
import useUserRoleStore from "@/stores/AdminUserRole.js";
import { useAuthStore } from "@/stores/auth";
import { XIcon } from "@heroicons/vue/outline";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { storeToRefs } from "pinia/dist/pinia";
import { computed, onMounted, ref, watch } from "vue";
import BsButton from "@/components/Base/BsButton.vue";

defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["closeImpersonateModal"]);

const authStore = useAuthStore();
const userRoleStore = useUserRoleStore();
const clientStore = useClientStore();
const { clientList } = storeToRefs(clientStore);

const selectedClient = ref(null);
const selectedUserRole = ref(0);

onMounted(() => {
  if (!clientList.value) {
    clientStore.getClientList();
  }
  if (authStore.user?.impersonation) {
    selectedClient.value = authStore.user.impersonation.client.impersonated_id;
    selectedUserRole.value =
      authStore.user.impersonation.usergroup.impersonated_id;
  }
});

watch(
  () => selectedClient.value,
  () => {
    userRoleStore.getUserRoleList({
      client_id: parseInt(selectedClient.value),
    });
  }
);

const clientValue = computed({
  get() {
    if (!clientStore.clientList || !selectedClient.value) return [];

    return clientStore.clientList.find(
      (item) => item.id === selectedClient.value
    );
  },
  set(value) {
    selectedClient.value = value.id;
    selectedUserRole.value = 0;
  },
});

const userRoleOptions = computed(() => {
  const defaultOption = [{ id: 0, name: "Superadmin" }];
  return !userRoleStore.userRoleList
    ? defaultOption
    : [...defaultOption, ...userRoleStore.userRoleList];
});

const userRoleValue = computed({
  get() {
    return userRoleOptions.value.find(
      (item) => parseInt(item.id) === parseInt(selectedUserRole.value)
    );
  },
  set(value) {
    selectedUserRole.value = value.id;
  },
});

const resetMediaView = computed({
  get() {
    return authStore.impersonateResetMedia;
  },
  set(evt) {
    authStore.savePreferences({
      preferences: {
        impersonate_reset_media: evt.toString(),
      },
    });

    authStore.impersonateResetMedia = evt;
  },
});

const goImpersonate = () => {
  authStore.startImpersonating({
    client_id: selectedClient.value,
    group_id: selectedUserRole.value,
  });
  emit("closeImpersonateModal");
};

const closeModal = () => {
  emit("closeImpersonateModal");
  selectedClient.value = null;
  selectedUserRole.value = 0;
};
</script>

<template>
  <div>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-[9999]" @close="closeModal()">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
              >
                <button
                  class="absolute z-[999] top-0 right-0 w-10 h-10 text-gray-500 text-center transition duration-250 ease-in-out hover:scale-105 hover:text-main1"
                  @click="closeModal()"
                >
                  <XIcon class="w-6 h-6 mx-auto" />
                </button>

                <div>
                  <div
                    class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100"
                  >
                    <font-awesome-icon
                      icon="fa-crown"
                      class="h-6 w-6 text-main1"
                    />
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <DialogTitle
                      as="h3"
                      class="text-lg font-medium leading-6 text-gray-900"
                    >
                      Impersonate
                    </DialogTitle>
                    <div class="mt-5 grid gap-5">
                      <BaseComboBox
                        placeholder="Select client"
                        :focus-on-mount="true"
                        :track-by="['name', 'id']"
                        :options="clientList"
                        v-model="clientValue"
                      />

                      <BaseComboBox
                        :disabled="!clientValue || !userRoleStore.userRoleList"
                        placeholder="Superadmin"
                        :track-by="['name', 'id']"
                        :options="userRoleOptions"
                        v-model="userRoleValue"
                      />

                      <BaseCheckboxNew
                        label="Automatically reset media view to default"
                        id="ga4_ad_events"
                        :big="true"
                        v-model="resetMediaView"
                      />
                    </div>

                    <div class="mt-5 sm:mt-6 flex justify-between">
                      <BsButton
                        class="ml-auto"
                        theme="p"
                        :disabled="!selectedClient"
                        @click="goImpersonate()"
                      >
                        <p>Impersonate</p>
                      </BsButton>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
