<script setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import {
  CameraIcon,
  PaperClipIcon,
  XIcon,
  InformationCircleIcon,
} from "@heroicons/vue/outline";
import { ref, onMounted } from "vue";
import BaseTextarea from "@/components/Base/BaseTextarea.vue";
import { useTicketsStore } from "@/stores/tickets.js";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth.js";
import PriorityModal from "../../../Tickets/PriorityModal.vue";
import BaseModalConfirmationDialog from "@/components/Base/BaseModalConfirmationDialog.vue";

const authStore = useAuthStore();
const router = useRouter();
const ticketsStore = useTicketsStore();

const props = defineProps({
  isActivePrompt: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(["closePopup", "openPopup"]);

const outputFile = ref(null);
const file = ref(null);
const openTicketConfirmationModal = ref(false);
const newTicketId = ref(null);
let additionalSentence = ref("");

const clearFeedback = () => {
  ticketsStore.newTicket = {
    title: "",
    message: "",
    attachments: [],
    sla_level: "Low",
  };
  outputFile.value = null;
  file.value = null;
};

const addImage = () => {
  const input = document.createElement("input");
  input.type = "file";
  input.id = "input-file";
  input.accept = ".png, .jpg, .jpeg, .gif";
  document.body.appendChild(input);
  input.click();

  input.addEventListener("change", (e) => {
    const fileTmp = e.target.files[0];
    if (!fileTmp) return;
    createBase64Image(fileTmp);
    document.body.removeChild(input);
  });
};

const createBase64Image = (fileObject) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    ticketsStore.newTicket.attachments.push(e.target.result);
  };
  reader.readAsDataURL(fileObject);
};

const print = async () => {
  emit("closePopup");
  const player = document.createElement("video");
  player.autoplay = true;

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  player.addEventListener("loadedmetadata", async () => {
    await new Promise((resolve) => setTimeout(resolve, 500));

    let usedTrack = player.srcObject.getTracks()[0].label;

    if (usedTrack.includes("screen")) {
      context.canvas.width = screen.width;
      context.canvas.height = screen.height;
    } else if (usedTrack.includes("window")) {
      context.canvas.width = window.outerWidth;
      context.canvas.height = window.outerHeight;
    } else {
      context.canvas.width = window.innerWidth;
      context.canvas.height = window.innerHeight;
    }

    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(player, 0, 0, canvas.width, canvas.height);

    ticketsStore.newTicket.attachments.push(captureImageUrl());
    emit("openPopup");

    stopSharingTab(player.srcObject);
  });

  navigator.mediaDevices
    .getDisplayMedia({ preferCurrentTab: true })
    .then((stream) => {
      player.srcObject = stream;
    })
    .catch((e) => alert(e));

  function captureImageUrl() {
    return canvas.toDataURL("image/jpeg");
  }

  function stopSharingTab(stream) {
    stream.getTracks().forEach((track) => track.stop());
  }
};

const createTicket = () => {
  ticketsStore.createTicket().then((resp) => {
    newTicketId.value = resp.data.data.id;
    if (resp.data.code === 0) {
      authStore.user.client.type === "enterprise"
        ? (additionalSentence.value =
            "In case of urgency please call +371 80009101")
        : "";

      openTicketConfirmationModal.value = true;
      clearFeedback();
      router.push({
        name: "tickets",
        params: { type: "view", id: resp.data.data.id },
      });
    }
  });
};

const saveBeforeLeave = ref(false);

const checkForChanges = () => {
  if (
    outputFile.value ||
    file.value ||
    ticketsStore.newTicket.title ||
    ticketsStore.newTicket.message ||
    ticketsStore.newTicket.attachments.length ||
    ticketsStore.newTicket.sla_level !== "Low"
  ) {
    saveBeforeLeave.value = true;
  } else {
    clearAndClose();
  }
};

const clearAndClose = () => {
  saveBeforeLeave.value = false;
  clearFeedback();
  emit("closePopup");
};

const emitAndClose = () => {
  emit("closePopup");
  openTicketConfirmationModal.value = false;
};

const removeAttachment = (imgLink) => {
  let imgIndex = ticketsStore.newTicket.attachments.indexOf(imgLink);
  ticketsStore.newTicket.attachments.splice(imgIndex, 1);
};

const slaLevels = ref(["Low", "Medium", "High", "Critical"]);

onMounted(() => {
  if (authStore.user.client.type !== "enterprise") {
    slaLevels.value = slaLevels.value.filter(
      (level) => level !== "High" && level !== "Critical"
    );
  }
});

const showMoreInfo = ref(false);
</script>

<template>
  <TransitionRoot appear :show="isActivePrompt" as="template">
    <Dialog as="div" class="relative z-[999]">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-black opacity-30" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                Create a ticket
              </DialogTitle>
              <div class="mt-2">
                <BaseInputNew
                  id="title"
                  label="Title"
                  v-model="ticketsStore.newTicket.title"
                />
              </div>
              <div class="w-full mb-4 flex mt-3">
                <div
                  class="flex text-sm text-gray-500 font-semibold my-auto mr-2"
                >
                  <p>Priority</p>
                  <div
                    @click="showMoreInfo = true"
                    class="flex text-main1 hover:text-tet-gray-800 cursor-pointer hover:underline mt-0.5 ml-1"
                  >
                    <InformationCircleIcon
                      class="h-4 w-4 font-medium text-sm"
                    />
                  </div>
                </div>
                <BaseSelect
                  class="w-28"
                  :options="slaLevels"
                  v-model:value="ticketsStore.newTicket.sla_level"
                  @onUpdate:value="ticketsStore.newTicket.sla_level = $event"
                />
              </div>
              <div class="mt-2">
                <BaseTextarea
                  id="description"
                  label="Describe your issue or suggestion"
                  v-model="ticketsStore.newTicket.message"
                />
              </div>
              <div class="mt-2 flex">
                <BaseButton
                  title="Add file"
                  class="mx-1"
                  theme="dark"
                  @click="addImage"
                >
                  <PaperClipIcon class="h-6 w-6" />
                </BaseButton>
                <BaseButton
                  title="Add screenshot"
                  class="mx-1"
                  theme="dark"
                  @click="print()"
                >
                  <CameraIcon class="h-6 w-6" />
                </BaseButton>
                <p class="text-xs font-medium text-gray-500 my-auto mx-1">
                  A screenshot will help us better understand the issue.
                </p>
              </div>

              <div
                v-if="ticketsStore.newTicket.attachments"
                class="flex flex-wrap space-x-3"
              >
                <div
                  v-for="(attachment, index) in ticketsStore.newTicket.attachments"
                  :key="index"
                  class="border rounded-md my-3 relative"
                >
                  <img
                    :src="attachment"
                    alt="attachment"
                    class="max-h-16 max-w-[8rem] rounded-md"
                  />
                  <button
                    class="absolute -top-2 -right-2 bg-gray-800 rounded-full text-center transition duration-250 ease-in-out hover:scale-105"
                    @click="removeAttachment(attachment)"
                  >
                    <XIcon class="w-4 h-4 text-white mx-auto" />
                  </button>
                </div>
              </div>

              <div class="flex justify-end mt-4 w-full">
                <BaseButton
                  class="mx-1 w-[89px]"
                  theme="dark"
                  @click="createTicket()"
                  :disabled="
                    ticketsStore.newTicket.title &&
                    ticketsStore.newTicket.message &&
                    ticketsStore.newTicket.attachments &&
                    ticketsStore.newTicket.message.length === 0 &&
                    ticketsStore.newTicket.attachments.length === 0
                  "
                >
                  Submit
                </BaseButton>

                <BaseButton
                  class="ml-1 w-[89px]"
                  theme="gray"
                  @click="checkForChanges()"
                >
                  Cancel
                </BaseButton>
              </div>
            </div>
          </TransitionChild>
        </div>

        <PriorityModal
          :show-modal="showMoreInfo"
          @cancel="showMoreInfo = !showMoreInfo"
        />

        <BaseModalConfirmationDialog
          :show-modal="openTicketConfirmationModal"
          title="Thank you for contacting Backscreen!"
          @accept="emitAndClose()"
          :hideCancelButton="true"
          acceptBtnText="OK"
        >
          Your support Ticket {{ newTicketId }} is registered. You will receive
          an e-mail notification once someone from our team has replied.
          {{ additionalSentence }}
        </BaseModalConfirmationDialog>

        <BaseModalConfirmationDialog
          :show-modal="saveBeforeLeave"
          title="Please confirm!"
          acceptBtnText="Confirm"
          accept-btn-theme="dark"
          @cancel="saveBeforeLeave = false"
          @accept="clearAndClose()"
        >
          Do you really want to leave without creating a ticket?
        </BaseModalConfirmationDialog>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
