<script setup>
import useTooltip from "@/composables/global/tooltip";

const props = defineProps({
  theme: {
    type: String,
    default: "dark",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  actionInProgress: {
    type: Boolean,
    required: false,
    default: false,
  },
  tooltipText: {
    type: String,
    required: false,
    default: "",
  },
  tooltipPlacement: {
    type: String,
    required: false,
    default: "top",
  },
  btnStyling: {
    type: String,
    required: false,
    default: "inline-flex text-center justify-center px-2.5",
  },
});

const { showTooltip, reference, floating, floatingStyles } = useTooltip(
  props.tooltipPlacement
);

const emit = defineEmits(["click"]);

const clickFn = () => {
  emit("click");
};
</script>

<template>
  <div>
    <button
      type="button"
      ref="reference"
      :disabled="disabled || actionInProgress"
      @mouseover="tooltipText ? (showTooltip = true) : (showTooltip = false)"
      @mouseleave="showTooltip = false"
      class="disabled:opacity-50 w-full items-center py-2 px-4 text-xs font-medium rounded-2xl transition duration-250 ease-in-out focus:outline-none focus:ring-0"
      :class="[
        btnStyling,
        theme === 'dark' &&
          'text-white bg-main1 hover:bg-main1-dark border-transparent shadow-sm',
        theme === 'gray' &&
          'text-gray-700 bg-gray-100 hover:bg-gray-200 shadow-sm',
        theme === 'gray-dark' &&
          'text-white bg-gray-600 hover:bg-gray-700 border-gray-600 shadow-sm',
        theme === 'delete' &&
          'text-red-500 bg-transparent border-red-500 hover:bg-red-500 hover:text-white shadow-sm',
        theme === 'green' &&
          'text-white bg-green-500 hover:bg-green-700 border-transparent shadow-sm',
        theme === 'red' &&
          'text-white bg-red-500 hover:bg-red-700 border-transparent shadow-sm',
        theme === 'no border' &&
          'text-gray-700 bg-gray-100 hover:bg-gray-200 border-transparent shadow-sm',
        theme === 'only text' &&
          'text-gray-700 bg-transparent hover:bg-transparent border-transparent hover:scale-105 border-0',
      ]"
      @click="clickFn()"
    >
      <span
        v-if="actionInProgress"
        class="animate-spin w-4 h-4 border-4 border-b-transparent rounded-full"
        role="status"
      />
      <span v-else class="flex">
        <slot />
      </span>
    </button>

    <div
      v-show="showTooltip"
      ref="floating"
      :style="floatingStyles"
      class="bg-gray-200 p-2 rounded z-[9999] text-xs text-gray-500 font-medium"
    >
      {{ tooltipText }}
    </div>
  </div>
</template>
