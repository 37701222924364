<script setup>
import { computed } from "vue";

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: null,
  },
  id: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["onUpdate:value"]);

const tmpValue = computed({
  get() {
    return props.value.toUpperCase();
  },
  set(evt) {
    emit("onUpdate:value", evt.toUpperCase());
  },
});
</script>

<template>
  <div class="flex flex-wrap">
    <label
      v-if="label"
      :for="id"
      class="w-full text-xs font-medium text-gray-500 mb-1"
    >
      {{ label }}
    </label>

    <div class="relative w-full flex">
      <input
        type="text"
        class="w-full py-1 pl-10 shadow-sm rounded-md text-sm border-gray-300 focus:ring-main1 focus:border-none disabled:bg-gray-200"
        :disabled="disabled"
        v-model="tmpValue"
      />

      <input
        :id="id"
        type="color"
        name="color"
        class="absolute left-1 top-[3px] w-6 h-6 text-sm"
        :disabled="disabled"
        v-model="tmpValue"
      />
    </div>
  </div>
</template>
