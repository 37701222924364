import { defineStore } from "pinia";
import axiosClient from "@/axiosClient";
import { app } from "@/main";

const URL = import.meta.env.VITE_API_URL;

export default defineStore({
  id: "useFoldersStore",
  state: () => ({
    defaultFolder: null,
    selectedFolderEdit: { id: 0, parent_id: 0 },
    type: "media",
    tmpNewID: -1,
    presetList: [],
    deleteFolders: [],
    //new
    folderInfo: null,
    folder: null,
    folderLoading: false,
    folderList: null,
    folderListLoading: false,
    inheritedFolderProps: null,
    newFolder: {
      id: -1,
      name: "",
      inherit_parent: 1,
      priority: 0,
      editable: 1,
      parent: { id: 0 },
      embed: {
        poster_from_thumbnail: 0,
        enable_public: 0,
        player_id: -1,
        ad_id: -1,
        protection_id: -1,
        show_related: { type: "", limit: 1, group_id: 0 },
      },
      security: {
        encryption_method: "",
        use_token: 0,
        use_token_full: 0,
        token_duration: "1d",
      },
      transcoding: {
        preset_id: 0,
        auto_transcode_condition: "",
        auto_transcode_after: 0,
        re_transcode: 0,
        subtitle_strip_html: 0,
        subtitle_utf8_convert: 0,
      },
      ingest: {
        duplicate_action: "replace",
        update_asset_name: 0,
        use_tar_manifest: 0,
        tar_manifest_file: "",
        subtitle_offset: "00:00:00",
        patterns: [],
      },
      external_uploads: { upload_automatically: 1, outputs: [] },
      language: { default: "", order: "" },
      multi_audio: { groups: [], input_configs: [] },
      availability: {
        published: 0,
        expire_hours: -1,
        available_hours: -1,
        delete_source_after_transcode_hours: -1,
        delete_source_av_after_transcode_hours: -1,
        delete_transcode_after_upload_hours: -1,
        delete_all_after_upload_hours: -1,
        delete_all_after_expire_hours: -1,
      },
    },
  }),

  actions: {
    getFolderList() {
      this.folderListLoading = true;

      const params = {
        type: this.type,
        return: ["children", "parent", "item_count"],
      };

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/folder/listfull", { params })
          .then((response) => {
            if (response.data.code === 0) {
              this.folderList = [response.data.data];
            }
            this.folderListLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.folderListLoading = false;
            reject(error);
          });
      });
    },

    getFolderInfo() {
      const params = {
        return: ["default_outgoing_patterns", "multi_audio"],
      };

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/folder/getinfo", { params })
          .then((response) => {
            if (response.data.code === 0) {
              this.folderInfo = response.data.data;
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getFolder() {
      this.folderLoading = true;

      const params = {
        type: this.type,
        id: this.selectedFolderEdit.id,
        return: [
          "embed",
          "security",
          "transcoding",
          "ingest",
          "external_uploads",
          "language",
          "multi_audio",
          "availability",
          "editable",
          "parent",
        ],
      };

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/folder/listfull", { params })
          .then((response) => {
            if (response.data.code === 0) {
              this.folder = response.data.data;
              this.selectedFolderEdit.parent_id = this.folder.parent.id
                ? this.folder.parent.id
                : 0;
              this.defaultFolder = JSON.parse(
                JSON.stringify(response.data.data)
              );
            }
            this.folderLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.folderLoading = false;
            reject(error);
          });
      });
    },

    saveFolder() {
      this.type = "media";
      const params = { ...this.folder, type: "media" };

      params.ingest.patterns = params.ingest.patterns.filter(
        (item) => item.extension && item.pattern
      );

      params.external_uploads.outputs = params.external_uploads.outputs.filter(
        (item) => item.name
      );

      params.external_uploads.outputs.forEach((item) => {
        item.upload_file_pattern.patterns =
          item.upload_file_pattern.patterns.filter(
            (item) => item.extension && item.pattern
          );
      });

      params.external_uploads.outputs = params.external_uploads.outputs.filter(
        (item) => item.upload_file_pattern.patterns.length > 0
      );

      return new Promise((resolve, reject) => {
        axiosClient
          .put(URL + "/folder/update/", params)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                type: "success",
                text: response.data.msg,
              });
              this.getFolder();
              this.getFolderList();
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deleteFolder() {
      this.type = "media";

      return new Promise((resolve, reject) => {
        axiosClient
          .delete(URL + "/folder/delete?type=media&id=" + this.folder.id)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                type: "success",
                text: response.data.msg,
              });
              this.selectedFolderEdit.id = this.folder.parent.id;
              this.getFolderList().then((r) => r);
              this.getFolder().then((r) => r);
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    createFolder(payload) {
      this.type = "media";
      const params = {
        ...payload,
        type: "media",
      };

      return new Promise((resolve, reject) => {
        axiosClient
          .post(URL + "/folder/create", params)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                type: "success",
                text: response.data.msg,
              });
              this.selectedFolderEdit.id = response.data.data.id;
              this.getFolder().then((r) => r);
              this.getFolderList().then((r) => r);
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    foldersUpdateMulti() {
      this.type = "media";
      const params = {
        update: this.folderList[0].children,
        ...(this.deleteFolders.length && { delete: this.deleteFolders }),
        type: "media",
      };

      return new Promise((resolve, reject) => {
        axiosClient
          .put(URL + "/folder/updatemulti/", params)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                type: "success",
                text: response.data.msg,
              });
              this.deleteFolders = [];
              this.selectedFolderEdit = { id: 0, parent_id: 0 };
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getPresetList() {
      return new Promise((resolve, reject) => {
        const params = {
          limit: 1000,
          return: ["packages"],
        };
        axiosClient
          .get(URL + "/preset/list/", { params })
          .then((response) => {
            if (response.data.code === 0) {
              this.presetList = response.data.data;
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getInheritedFolderProp(payload) {
      const params = {
        type: payload.type ? payload.type : "media",
        id: payload.id,
        return: ["embed", "security", "parent", "availability"],
      };

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/folder/listfull", { params })
          .then((response) => {
            if (response.data.code === 0) {
              this.inheritedFolderProps = response.data.data;
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});
