import { defineStore } from "pinia";
import axiosClient from "@/axiosClient";
import { app } from "@/main";
import { useAuthStore } from "@/stores/auth";

const URL = import.meta.env.VITE_API_URL;

export default defineStore({
  id: "usePlayerPresetsStore",
  state: () => ({
    pagination: {
      limit: 20,
      offset: 0,
      total: 0,
    },
    playerLoading: true,
    playersLoading: true,
    templatePlayers: "list",
    playerList: null,
    visual_design: {
      colorPrimary: "#ffc50f",
      colorPrimaryBackground: "#000000",
      colorSecondary: "#FFFFFF",
      colorSecondaryBackground: "#000000",
      colorTertiary: "#000000",
      colorTertiaryBackground: "#FFFFFF",
    },
    playerPreset: [],
    uneditedPlayerPreset: [],
    newPlayerPreset: {
      name: "",
      name_override_meta: "",
      allow_url_override: 1,
      https: 1,
      manifest: "transcoded",
      bitrate: 0,
      type: "regular",
      protocol: "hls",
      aspect_ratio: "",
      autoplay: 0,
      autosubs: 0,
      mute: 0,
      start_from_random: 0,
      extended_buffer: 0,
      preload_content: 0,
      block_pause: 0,
      loop: 0,
      singular_live: "",
      custom_css: "",
      up_next: {
        meta: "",
        offset: "10%",
      },
      hide: {
        opt: 0,
        vol: 0,
        sub: 0,
        progress: 0,
        image: 0,
        play: 0,
        time: 0,
        fullscreen: 0,
      },
      soc: {
        fb: 0,
        tw: 0,
        in: 0,
        embed: 0,
      },
      sub: {
        txt_col: "#000000",
        bg_col: "#ffffff",
        font: "Arial, Helvetica, sans-serif",
      },
      custom_titles: [],
      translations: [],
      images: {
        logo: "",
        placeholder: "",
        playbutton: "",
      },
    },
    playersListNames: null,
  }),

  actions: {
    getPlayers() {
      this.playersLoading = true;

      const sections = [
        "hide",
        "soc",
        "sub",
        "custom_titles",
        "translations",
        "images",
      ];

      let params = {
        return: sections.join(","),
        offset: this.pagination.offset,
        limit: this.pagination.limit,
        order_by: "id",
        order_dir: "desc",
      };

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/embed/player/list", { params })
          .then((response) => {
            if (response.data.code === 0) {
              this.playerList = response.data.data;
              this.pagination = {
                limit: response.data.limit,
                offset: response.data.offset,
                total: response.data.total,
              };
            }
            this.playersLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.playersLoading = false;
            reject(error);
          });
      });
    },

    getPlayer(payload) {
      this.playerLoading = true;

      const sections = [
        "hide",
        "soc",
        "sub",
        "custom_titles",
        "translations",
        "images",
        "up_next",
      ];

      return new Promise((resolve, reject) => {
        axiosClient
          .get(
            URL +
              "/embed/player/list?id=" +
              payload.id +
              "&status=any&return=" +
              sections.join(",")
          )
          .then((response) => {
            if (response.data.code === 0) {
              this.playerPreset = response.data.data[0];
              this.uneditedPlayerPreset = JSON.parse(
                JSON.stringify(response.data.data[0])
              );
              // check if uneditedPlayer.translations is an array and if the length is 0 transform it to an object
              if (
                Array.isArray(this.playerPreset.translations) &&
                this.playerPreset.translations.length === 0
              ) {
                this.playerPreset.translations = {};
                this.uneditedPlayerPreset.translations = {};
              }
            }
            this.playerLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.playerLoading = false;
            reject(error);
          });
      });
    },

    getPlayersNames() {
      this.playersLoading = true;

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/embed/player/listnames?order_by=name&order_dir=asc")
          .then((response) => {
            if (response.data.code === 0) {
              this.playersListNames = response.data.data;
              this.playersLoading = false;
              resolve(response);
            }
          })
          .catch((error) => {
            this.playersLoading = false;
            reject(error);
          });
      });
    },

    updatePlayer(payload) {
      this.playerLoading = true;

      if (
        payload.images.placeholder.includes(
          "assets/dist/img/default-poster.jpg"
        ) ||
        payload.images.placeholder.includes("http")
      ) {
        delete payload.images.placeholder;
      }
      if (payload.images.playbutton.includes("http"))
        delete payload.images.playbutton;
      if (payload.images.logo.includes("http")) delete payload.images.logo;
      if (payload.images.length === 0) delete payload.images;
      if (payload.sub.txt_col === "") delete payload.sub.txt_col;
      if (payload.sub.bg_col === "") delete payload.sub.bg_col;
      if (payload.sub.font === "") delete payload.sub.font;
      if (payload.bitrate === "") payload.bitrate = 0;

      return new Promise((resolve, reject) => {
        axiosClient
          .put(URL + "/embed/player/update", payload)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: "Player branding setup updated!",
              });
            }
            app.config.globalProperties.$router.push("/playback");
            this.playerLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.playerLoading = false;
            reject(error);
          });
      });
    },

    createPlayer(payload) {
      this.playerLoading = true;

      if (
        payload.images.placeholder ===
          "//playerdev.cloudycdn.services/assets/dist/img/default-poster.jpg" ||
        payload.images.placeholder.includes("http")
      ) {
        delete payload.images.placeholder;
      }
      if (payload.images.playbutton.includes("http"))
        delete payload.images.playbutton;
      if (payload.images.logo.includes("http")) delete payload.images.logo;

      return new Promise((resolve, reject) => {
        axiosClient
          .post(URL + "/embed/player/create", payload)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: "Player created",
              });
            }
            this.playerLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.playerLoading = false;
            reject(error);
          });
      });
    },

    deletePlayer(payload) {
      return new Promise((resolve, reject) => {
        axiosClient
          .delete(URL + "/embed/player/delete?id=" + payload.id)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: "Player deleted",
              });
              this.getPlayers();
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getUserPaginationLimit() {
      return new Promise((resolve) => {
        const limitTmp =
          useAuthStore().user.preferences.playersLimit ||
          useAuthStore().user.preferences.playback_players_limit;

        if (limitTmp) {
          this.pagination.limit = parseInt(limitTmp);
        }

        resolve();
      });
    },

    updateUserPaginationLimit() {
      //delete old naming
      if (useAuthStore().user.preferences["playersLimit"]) {
        delete useAuthStore().user.preferences["playersLimit"];

        useAuthStore().savePreferences({
          preferences: {
            playersLimit: null,
          },
        });
      }

      const limitTmp = this.pagination.limit.toString();

      if (useAuthStore().user.preferences.playback_players_limit !== limitTmp) {
        useAuthStore().user.preferences["playback_players_limit"] = limitTmp;

        useAuthStore().savePreferences({
          preferences: {
            playback_players_limit: limitTmp,
          },
        });
      }
    },
  },
  getters: {
    mediaPlayerTemplateDropdownOptions: (state) => {
      const defaultOption = [
        { id: -1, name: "Use from folder settings" },
        { id: 0, name: "None" },
      ];

      if (!state.playersListNames) return defaultOption;

      return [...defaultOption, ...state.playersListNames];
    },

    livePlayerTemplateDropdownOptions: (state) => {
      const defaultOption = [{ id: 0, name: "None" }];

      if (!state.playersListNames) return defaultOption;

      return [...defaultOption, ...state.playersListNames];
    },

    folderPlayerTemplateDropdownOptions: (state) => {
      const defaultOption = [
        { id: -1, name: "Inherited from parent folder" },
        { id: 0, name: "None" },
      ];

      if (!state.playersListNames) return defaultOption;

      return [...defaultOption, ...state.playersListNames];
    },
  },
});
