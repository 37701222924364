<script setup>
import { useAuthStore } from "@/stores/auth";

const auth = useAuthStore();

defineProps({
  item: {
    type: Object,
    required: true,
  },
  sidebarCurrent: {
    type: String,
    required: true,
  },
});
</script>

<template>
  <router-link
    :to="item.href"
    class="group block py-2.5 border-l-2 transition duration-300 ease-in-out relative"
    :class="[
      { 'bg-main1 text-white border-main1': item.name === 'Add media' },
      {
        'bg-gray-100 text-main1 border-main1':
          item.name !== 'Add media' && item.name === sidebarCurrent,
      },
      {
        'bg-gray-100 text-gray-500 hover:bg-gray-200':
          item.name !== 'Add media' && item.name !== sidebarCurrent,
      },
      {
        'flex items-center justify-center border-none': auth.sidebarSimple,
      },
    ]"
  >
    <font-awesome-icon
      :icon="'fa-solid ' + item.icon"
      :class="[
        'w-[25px] h-[25px] block mx-auto font-medium z-10',
        auth.sidebarSimple
          ? 'group-hover:text-white group-hover:hidden'
          : 'mb-2',
      ]"
    />

    <span
      class="text-xs block"
      :class="[
        auth.sidebarSimple
          ? 'absolute top-0 left-0 bg-main1 h-full pl-[54px] rounded-tr-lg rounded-br-lg items-center w-0 max-w-[0px] group-hover:w-[200px] group-hover:max-w-[200px] hidden group-hover:flex opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out group-hover:text-white'
          : 'text-center',
      ]"
    >
      {{ item.name }}
    </span>
  </router-link>
</template>
