<script setup>
import { computed } from "vue";
import { notify } from "@kyvg/vue3-notification";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: null,
  },
  id: {
    type: String,
    required: true,
  },
  modelValue: {
    type: [String, Number],
    // required: true
  },
  placeholder: {
    type: String,
    required: false,
    default: null,
  },
  disabled: {
    type: [Boolean, Number],
    required: false,
    default: false,
  },
  type: {
    type: String,
    required: false,
    default: "text",
  },
  infoCircleColor: {
    type: String,
    required: false,
    default: "text-gray-500",
  },
  tooltipText: {
    type: String,
    required: false,
    default: "",
  },
  tooltipPlacement: {
    type: String,
    required: false,
    default: "top",
  },
  tooltipCustomWidth: {
    type: String,
    required: false,
    default: "",
  },
  tooltipMultiLine: {
    type: Boolean,
    required: false,
    default: false,
  },
  maxlength: {
    type: Number,
    required: false,
    default: 524288,
  },
  buttons: {
    type: Array,
    required: false,
    default: () => [],
  },
  resetToDefaultIcon: {
    type: [Boolean],
    required: false,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "resetToDefault", "keydown"]);

const tmpValue = computed({
  get() {
    return props.modelValue;
  },
  set(evt) {
    emit("update:modelValue", evt);
  },
});

const copy = () => {
  navigator.clipboard.writeText(props.modelValue);

  notify({
    type: "success",
    text: "Copied to clipboard!",
    duration: 1000,
  });
};
</script>

<template>
  <div class="w-full">
    <div v-if="label" class="flex gap-0.5 items-center">
      <label :for="id" class="block text-xs text-gray-500 font-medium">
        {{ label }}
      </label>
      <div v-if="tooltipText">
        <BaseTooltipNew
          :tooltipText="tooltipText"
          :tooltipColor="infoCircleColor"
          :customWidth="tooltipCustomWidth"
          :multiLine="tooltipMultiLine"
          :placement="tooltipPlacement"
        />
      </div>

      <div v-if="!disabled" class="h-3 w-3 flex ml-1">
        <font-awesome-icon
          v-if="resetToDefaultIcon"
          title="Reset to default value"
          icon="fa solid fa-rotate-left"
          class="cursor-pointer text-gray-500 font-medium text-sm h-3 w-3 my-auto"
          @click="emit('resetToDefault')"
        />
      </div>
    </div>

    <div class="w-full flex relative">
      <input
        :id="id"
        :name="id"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxlength"
        :type="type"
        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-3 focus:outline-none focus:border-gray-300 focus:ring-0 text-sm placeholder-[#999999]"
        :class="[
          { 'bg-gray-200': disabled },
          { 'pr-8': buttons.length === 1 },
          { 'pr-16': buttons.length === 2 },
        ]"
        autocomplete="off"
        @keydown="$emit('keydown', $event)"
        v-model="tmpValue"
      />

      <div
        v-if="buttons.length"
        class="absolute inset-y-0 right-0 flex gap-2.5 items-center mr-2 cursor-pointer"
      >
        <a
          v-if="buttons.includes('view')"
          class="flex items-center text-main1 transform cursor-pointer transition duration-250 ease-in-out hover:text-main1-dark hover:scale-110 disabled:opacity-50 disabled:cursor-default disabled:text-main1 disabled:scale-100"
          target="_blank"
          :href="'https:' + modelValue"
          title="View"
        >
          <font-awesome-icon
            icon="fa-solid fa-book-open"
            class="h-5 w-5 mt-0.5"
          />
        </a>

        <button
          v-if="buttons.includes('copy')"
          type="button"
          title="Copy"
          class="flex items-center text-main1 transform cursor-pointer transition duration-250 ease-in-out hover:text-main1-dark hover:scale-110 disabled:opacity-50 disabled:cursor-default disabled:text-main1 disabled:scale-100"
          @click="copy()"
        >
          <font-awesome-icon icon="fa-solid fa-copy" class="h-5 w-5 mt-0.5" />
        </button>
      </div>
    </div>
  </div>
</template>
