import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";


export function useGetPermissions() {
  const authStore = useAuthStore();
  const { user } = storeToRefs(authStore);
  const view = (permission) => {
    return user.value ? user.value.permissions.view.includes(permission) : false;
  };
  const update = (permission) => {
    return user.value ?user.value.permissions.update.includes(permission): false;
  };
  const del = (permission) => {
    return user.value ?user.value.permissions.delete.includes(permission): false;
  };

  return {
    view,
    update,
    del,
  };
}
