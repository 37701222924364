<script setup>
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/vue";
import { useAuthStore } from "@/stores/auth.js";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref } from "vue";
import SearchBar from "@/components/Layout/SearchBar.vue";

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

//for manually closing menu items - bugfix
const closeMenuButton = ref();
const closePopoverButton = ref();

const navigation = [
  { name: "My profile", to: "/user/settings", icon: "fa-address-card" },
  { name: "Accounts", to: "/accounts", icon: "fa-users" },
  { name: "Settings", to: "/settings", icon: "fa-gear" },
  { name: "Billing", to: "/billing", icon: "fa-newspaper" },
  { name: "Terms and Policies", to: "/terms", icon: "fa-file-lines" },
  { name: "Log out", to: "/login", icon: "fa-right-from-bracket" },
];

const billingPermissions = ref([
  "billing.invoices",
  "billing.overview",
  "billing.payment_methods",
  "billing.upgrade",
]);

const accountPermissions = ref([
  "accounts.general",
  "accounts.users",
  "accounts.user_roles",
]);

const settingsPermissions = ref([
  // "files",
  // "my_user_groups",
  // "my_users",
  // "settings.recordings"
  "settings.media_tags",
  "settings.integrations",
  "settings.security",
  "settings.notifications",
  "settings.metadata_lists",
  "settings.metadata_list_items",
  "settings.transcoding_presets",
  "settings.transcoding_presets_admin",
  "settings.upload_destinations",
  "settings.s3_users",
  "settings.recordings",
]);

const checkPermission = (item) => {
  if (item.to === "/billing") {
    return billingPermissions.value.some((v) =>
      authStore.user.permissions.view.includes(v)
    );
  }
  if (item.to === "/accounts") {
    return accountPermissions.value.some((v) =>
      authStore.user.permissions.view.includes(v)
    );
  }
  if (item.to === "/settings") {
    return settingsPermissions.value.some((v) =>
      authStore.user.permissions.view.includes(v)
    );
  }
  return true;
};

onMounted(async () => {
  if (authStore.user === null) await authStore.getUser();
});

const getInitials = () => {
  let name = user.value.name.split(" ");
  return name[0].substring(0, 1) + name[name.length - 1].substring(0, 1);
};

const bgColor = computed(() => {
  let environment = import.meta.env.VITE_ENVIRONMENT;

  if (["localhost", "dev"].includes(environment)) {
    return "bg-tet-green-400";
  } else if (["stage"].includes(environment)) {
    return "bg-tet-blue-400";
  } else return false;
});

const saveAdvanced = (value) => {
  authStore.advanced = value;
  authStore.user.preferences.advanced = value.toString();
  authStore.savePreferences({
    preferences: {
      advanced: value.toString(),
    },
  });
};
</script>

<template>
  <div class="z-20">
    <Popover as="div" v-slot="{ open }">
      <header
        class="shadow-sm lg:static lg:overflow-y-visible bg-gray-50 min-h-[70px]"
        :class="[open ? 'fixed inset-0 z-50 overflow-y-auto' : '']"
      >
        <div class="pl-6 py-4 pr-8 relative flex items-center">
          <button
            class="pr-5 lg:hidden flex items-center justify-center bg-gray-50"
            @click="authStore.mobileSidebarOpen = !authStore.mobileSidebarOpen"
          >
            <font-awesome-icon
              icon="fa-solid fa-bars-staggered"
              class="text-gray-500"
              aria-hidden="true"
            />
          </button>
          <!-- Logo -->
          <router-link
            to="/"
            class="border-r border-gray-200 flex-0 items-center"
          >
            <img src="@/assets/img/backscreen-logo.svg" alt="logo" />
          </router-link>

          <!-- Search -->
          <SearchBar />

          <div class="ml-auto" />

          <div v-if="authStore.user?.impersonation">
            <BaseButton
              class="mx-auto"
              theme="red"
              @click="authStore.stopImpersonating()"
            >
              <p>
                STOP impersonating as {{ authStore.user.client_name }} (ID:
                {{ authStore.user.client_id }})
              </p>
            </BaseButton>
          </div>

          <!-- Mobile menu button -->
          <div class="flex items-center lg:hidden">
            <PopoverButton
              ref="closePopoverButton"
              class="rounded-md inline-flex items-center justify-center text-gray-500 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <span class="sr-only">Open menu</span>
              <font-awesome-icon
                v-if="!open"
                icon="fa-solid fa-bars"
                class="p-2"
                aria-hidden="true"
              />
              <font-awesome-icon
                v-else
                icon="fa-solid fa-xmark"
                class="p-2"
                aria-hidden="true"
              />
            </PopoverButton>
          </div>

          <div class="hidden lg:flex lg:items-center lg:justify-end">
            <!-- Profile user -->
            <Menu as="div" class="flex-shrink-0 relative ml-5">
              <MenuButton ref="closeMenuButton">
                <div v-if="user" class="flex items-center">
                  <p class="mr-2.5 font-medium text-xs text-gray-700">
                    {{ user.name }}
                  </p>
                  <template v-if="user.image_url">
                    <div class="bg-white rounded-full flex">
                      <img
                        class="h-9 w-9 rounded-full"
                        :src="user.image_url"
                        alt=""
                      />
                    </div>
                  </template>
                  <template v-else>
                    <div
                      class="flex h-9 w-9 rounded-full bg-gray-400 items-center justify-center"
                    >
                      <span class="text-white font-medium">
                        {{ getInitials() }}
                      </span>
                    </div>
                  </template>
                </div>
              </MenuButton>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  as="div"
                  class="origin-top-right absolute w-48 z-10 right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none cursor-pointer"
                >
                  <MenuItem
                    v-for="(item, i) in navigation"
                    @click="closeMenuButton.el.click()"
                    as="div"
                    :key="i"
                    v-slot="{ active }"
                  >
                    <router-link
                      v-if="checkPermission(item)"
                      :to="item.to"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block py-2 px-4 text-sm text-gray-700',
                      ]"
                      @click="
                        item.to === '/login'
                          ? authStore.logout()
                          : closeMenuButton.el.click()
                      "
                    >
                      <div class="flex gap-2.5">
                        <font-awesome-icon
                          :icon="'fa-solid ' + item.icon"
                          class="text-gray-500 my-auto"
                        />
                        <p class="flex-1">
                          {{ item.name }}
                        </p>
                      </div>
                    </router-link>
                  </MenuItem>

                  <MenuItem @click="saveAdvanced(!authStore.advanced)">
                    <div class="flex py-2 px-4 border-t">
                      <BaseSwitch
                        class="my-auto"
                        :big="false"
                        :modelValue="authStore.advanced"
                        @update:modelValue="saveAdvanced(!authStore.advanced)"
                      />
                      <p class="text-sm text-gray-700 my-auto pl-2">Advanced</p>
                    </div>
                  </MenuItem>

                  <div v-if="user.is_superadmin" class="border-t grid">
                    <div
                      class="flex gap-2.5 text-xs font-semibold text-gray-700 mx-auto pt-4 pb-2 px-4 animate-rubber"
                    >
                      <font-awesome-icon icon="fa-crown" class="my-auto" />
                      <p>For admins only</p>
                    </div>

                    <MenuItem
                      as="div"
                      class="flex py-2 px-4"
                      @click="
                        authStore.showWelcomeModal = !authStore.showWelcomeModal
                      "
                    >
                      <BaseSwitch
                        class="my-auto"
                        v-model="authStore.showWelcomeModal"
                      />
                      <p class="text-xs text-gray-700 my-auto pl-2">
                        Welcome modal
                      </p>
                    </MenuItem>

                    <MenuItem
                      as="div"
                      v-slot="{ active }"
                      @click="closeMenuButton.el.click()"
                    >
                      <router-link
                        to="/demo/bs_demo/"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block py-2 px-4 text-sm text-gray-700',
                        ]"
                        @click="closeMenuButton.el.click()"
                      >
                        <div class="flex gap-2.5">
                          <font-awesome-icon
                            icon="fa-solid fa-wand-sparkles"
                            class="text-gray-500 my-auto"
                          />

                          <p class="flex-1">Demo</p>
                        </div>
                      </router-link>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>

        <PopoverPanel as="nav" class="lg:hidden" aria-label="Global">
          <div class="border-t border-gray-200 pt-4 pb-3">
            <div class="mx-auto px-4 flex items-center sm:px-6">
              <div v-if="user" class="flex-shrink-0">
                <template v-if="user.image_url">
                  <div class="bg-white rounded-full flex">
                    <img
                      class="h-9 w-9 rounded-full"
                      :src="user.image_url"
                      alt=""
                    />
                  </div>
                </template>
                <template v-else>
                  <div
                    class="flex h-9 w-9 rounded-full bg-gray-400 items-center justify-center"
                  >
                    <span class="text-white font-medium">
                      {{ getInitials() }}
                    </span>
                  </div>
                </template>
              </div>
              <div v-if="user" class="ml-3">
                <div class="text-base font-medium text-gray-800">
                  {{ user.name }}
                </div>
                <div class="text-sm font-medium text-gray-500">
                  {{ user.email }}
                </div>
              </div>
            </div>
            <div class="mt-3 mx-auto" @click="closePopoverButton.el.click()">
              <popover-button
                v-for="item in navigation"
                :key="item.name"
                class="block"
              >
                <router-link
                  class="rounded-md text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                  :to="item.to"
                  @click="item.to === '/login' && authStore.logout()"
                >
                  <div class="flex gap-2.5 py-2 pl-5">
                    <font-awesome-icon
                      :icon="'fa-solid ' + item.icon"
                      class="text-gray-500 my-auto"
                    />
                    <p class="flex-1">
                      {{ item.name }}
                    </p>
                  </div>
                </router-link>
              </popover-button>
            </div>
          </div>
        </PopoverPanel>
      </header>
    </Popover>
    <div
      v-if="bgColor"
      class="absolute h-[4px] w-full top-[70px]"
      :class="bgColor"
    ></div>
  </div>
</template>
