<script setup>
import {
  BookOpenIcon,
  SupportIcon,
  FlagIcon,
  XIcon,
} from "@heroicons/vue/outline";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

const URL = import.meta.env.VITE_API_URL;

defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["onUpdate:open", "onUpdate:openFeedback"]);

const createTicket = () => {
  emit("onUpdate:open", false);
  setTimeout(() => {
    emit("onUpdate:openFeedback", true);
  }, 500);
};
</script>

<template>
  <div>
    <TransitionRoot as="template" :show="open">
      <Dialog
        as="div"
        class="relative z-[9999]"
        @close="emit('onUpdate:open', false)"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
              >
                <button
                  class="absolute z-[999] top-0 right-0 w-10 h-10 text-gray-500 text-center transition duration-250 ease-in-out hover:scale-105 hover:text-main1"
                  @click="emit('onUpdate:open', false)"
                >
                  <XIcon class="w-6 h-6 mx-auto" />
                </button>

                <div>
                  <font-awesome-icon
                    icon="fa-solid fa-circle-question"
                    class="text-gray-500 block mx-auto h-7 w-7 font-medium relative z-10 mb-2"
                  />
                  <div class="mt-3 text-center sm:mt-5">
                    <DialogTitle
                      as="h3"
                      class="text-lg font-medium leading-6 text-gray-900"
                      >Help & Feedback</DialogTitle
                    >
                    <div class="mt-5">
                      <div
                        @click="createTicket()"
                        class="flex justify-center w-full py-2 bg-gray-200 hover:bg-gray-300 shadow-md my-2 rounded text-base text-gray-700 font-medium hover:cursor-pointer"
                      >
                        <FlagIcon class="h-6 mr-2" />
                        Create new ticket
                      </div>

                      <router-link
                        @click="emit('onUpdate:open', false)"
                        key="tickets"
                        to="/tickets"
                      >
                        <div
                          class="flex justify-center w-full py-2 bg-gray-200 hover:bg-gray-300 shadow-md my-2 rounded text-base text-gray-700 font-medium"
                        >
                          <SupportIcon class="h-6 mr-2" />
                          Support tickets
                        </div>
                      </router-link>

                      <a
                        href="https://docs.backscreen.com/documentation/"
                        target="”_blank”"
                        @click="emit('onUpdate:open', false)"
                      >
                        <div
                          class="flex justify-center w-full py-2 bg-gray-200 hover:bg-gray-300 shadow-md my-2 rounded text-base text-gray-700 font-medium"
                        >
                          <BookOpenIcon class="h-6 mr-2" />
                          Documentation
                        </div>
                      </a>

                      <a
                        :href="URL + '/docs#/'"
                        target="”_blank”"
                        @click="emit('onUpdate:open', false)"
                      >
                        <div
                          class="flex justify-center w-full py-2 bg-gray-200 hover:bg-gray-300 shadow-md my-2 rounded text-base text-gray-700 font-medium"
                        >
                          <BookOpenIcon class="h-6 mr-2" />
                          API Documentation
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
