<script setup>
import { computed } from "vue";

const props = defineProps({
  modelValue: {
    type: [Number, Boolean],
    required: true,
    default: 0,
  },
  names: {
    type: Array,
    required: true,
  },
  label: {
    type: String,
    required: false,
    default: "",
  },
});

const emits = defineEmits(["update:modelValue"]);

const tmpValue = computed({
  get() {
    return props.modelValue;
  },
  set(evt) {
    emits("update:modelValue", evt);
  },
});
</script>

<template>
  <div class="">
    <label v-if="!!label" class="block text-xs font-medium text-gray-500 mb-1">
      {{ label }}
    </label>

    <div
      class="flex min-h-[32px] items-stretch text-xs font-medium select-none rounded-full border-main1 border-2 overflow-hidden"
    >
      <button
        class="px-2 py-1 min-w-[70px]"
        :class="[
          !tmpValue
            ? 'pointer-events-none bg-main1 text-gray-50'
            : 'bg-gray-50 text-main1',
        ]"
        @click.prevent="tmpValue = false"
      >
        <span>
          {{ names[0] }}
        </span>
      </button>

      <button
        class="px-2 py-1 min-w-[70px]"
        :class="[
          tmpValue
            ? 'pointer-events-none bg-main1 text-gray-50'
            : 'bg-gray-50 text-main1',
        ]"
        @click.prevent="tmpValue = true"
      >
        <span>
          {{ names[1] }}
        </span>
      </button>
    </div>
  </div>
</template>
