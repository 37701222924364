<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import useListMediaStore from "@/stores/media/listMedia";

const route = useRoute();
const router = useRouter();
const listMediaStore = useListMediaStore();
const { searchValue, listLoading } = storeToRefs(listMediaStore);

const searchValueTmp = computed({
  get() {
    return searchValue.value;
  },
  set(evt) {
    listMediaStore.searchValue = evt;

    // Send get list if empty search field
    if (evt === "") {
      if (route.name === "media") doSearch();
    }
  },
});

onMounted(() => {
  if (route.query.search) {
    searchValueTmp.value = route.query.search;
  }
});

watch(
  () => route.name,
  () => {
    if (route.name !== "media") {
      listMediaStore.searchValue = "";
    }
  }
);

const openSearchInput = ref(false);
const focusSearchInput = ref(false);

const checkSearchBar = () => {
  if (openSearchInput.value === true && focusSearchInput.value === false)
    openSearchInput.value = false;
};

const closeSearch = () => {
  openSearchInput.value = false;
  focusSearchInput.value = false;
};

const doSearch = () => {
  let newRoute = {
    name: "media",
    params: route.params,
    query: { ...route.query, page: 1 },
  };

  if (searchValueTmp.value) {
    newRoute.query = { ...newRoute.query, search: searchValueTmp.value };
  } else if (newRoute.query.search) {
    delete newRoute.query.search;
  }

  router.push(newRoute);
};
</script>

<template>
  <div
    class="hidden md:flex items-center px-4 md:px-8 relative h-[34px]"
    :class="{ 'cursor-progress': listLoading }"
  >
    <div
      class="relative flex w-full max-w-[500px] min-w-[300px]"
      :class="{ 'pointer-events-none': listLoading }"
      @mouseover="openSearchInput = true"
      @click="
        focusSearchInput = true;
        openSearchInput = true;
      "
      @mouseleave="checkSearchBar()"
    >
      <div
        @click="doSearch"
        class="cursor-pointer absolute inset-y-0 left-0 pl-3 flex items-center"
      >
        <font-awesome-icon
          icon="fa-solid fa-magnifying-glass"
          class="h-4 w-4 text-gray-500"
          aria-hidden="true"
        />
      </div>
      <input
        class="block w-full rounded-md py-2 px-10 text-sm placeholder-gray-700 focus:text-gray-900 focus:border-gray-500 focus:ring-0"
        :class="[
          openSearchInput
            ? 'border bg-white'
            : 'bg-transparent border-transparent',
        ]"
        placeholder="Search media"
        type="text"
        v-model="searchValueTmp"
        @blur="closeSearch()"
        @keyup.enter="doSearch"
        @changeValue="searchValueTmp = $event.target.value"
        autocomplete="off"
      />
      <div class="absolute right-0 flex">
        <span
          v-if="searchValueTmp"
          @click.prevent="searchValueTmp = ''"
          class="pl-2 pt-[6px] pb-[8px] pr-3 mb-auto hover:cursor-pointer"
        >
          <font-awesome-icon
            icon="fa-solid fa-xmark"
            class="h-3 w-3 text-gray-700"
          />
        </span>
      </div>
    </div>
  </div>
</template>
