import { defineStore } from "pinia";
import axiosClient from "@/axiosClient";
import { app } from "@/main";
import { useAuthStore } from "@/stores/auth";

const URL = import.meta.env.VITE_API_URL;

export default defineStore({
  id: "useAdsPresetStore",
  state: () => ({
    pagination: {
      limit: 20,
      offset: 0,
      total: 0,
    },
    templateAds: "list",
    adsPreset: null,
    uneditedAdsPreset: null,
    adsPresets: null,
    adsPresetLoading: false,
    adsPresetsLoading: false,
    newAdsPreset: {
      ads: [
        {
          url: "",
          type: "MID",
          integration: "",
          adsType: "VAST",
          less_than: "",
          more_than: "",
        },
      ],
      block_adblock: 0,
      name: "",
      recurring: 0,
    },
    adsListNames: null,
  }),

  actions: {
    getAdsPresets() {
      this.adsPresetsLoading = true;

      let params = {
        offset: this.pagination.offset,
        limit: this.pagination.limit,
      };

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/embed/sda/list", { params })
          .then((response) => {
            this.adsPresets = response.data.data;
            this.pagination = {
              limit: response.data.limit,
              offset: response.data.offset,
              total: response.data.total,
            };
            this.adsPresetsLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.adsPresetsLoading = false;
            reject(error);
          });
      });
    },

    getAdsPreset({ id }) {
      this.adsPresetLoading = true;

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/embed/sda/list?id=" + id)
          .then((response) => {
            if (response.data.code === 0) {
              this.adsPreset = response.data.data[0];
              this.uneditedAdsPreset = JSON.parse(
                JSON.stringify(response.data.data[0])
              );

              this.uneditedAdsPreset.ads.forEach((ad) => {
                if (ad.adsType === "VMAP") {
                  if (ad.type.toLowerCase() === "mid" || ad.type === "")
                    ad.type = "PRE";
                }
              });
            }
            this.adsPresetLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.adsPresetLoading = false;
            reject(error);
          });
      });
    },

    getAdsNames() {
      this.adsPresetLoading = true;

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/embed/sda/listnames?order_by=name&order_dir=asc")
          .then((response) => {
            if (response.data.code === 0) {
              this.adsListNames = response.data.data;
              this.adsPresetLoading = false;
              resolve(response);
            }
          })
          .catch((error) => {
            this.adsPresetLoading = false;
            reject(error);
          });
      });
    },

    updateAdsPreset(payload) {
      this.adsPresetLoading = true;

      return new Promise((resolve, reject) => {
        axiosClient
          .put(URL + "/embed/sda/update", payload)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: "Advertisements template updated!",
              });
              this.getAdsPresets();
              app.config.globalProperties.$router.push(
                "/playback/advertisements"
              );
            }
            this.adsPresetLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.adsPresetLoading = false;
            reject(error);
          });
      });
    },

    createAdsPreset(payload) {
      this.adsPresetLoading = true;

      return new Promise((resolve, reject) => {
        axiosClient
          .post(URL + "/embed/sda/create", payload)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: response.data.msg,
              });
              this.getAdsPresets();
              app.config.globalProperties.$router.push(
                "/playback/advertisements"
              );
            }
            this.adsPresetLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.adsPresetLoading = false;
            reject(error);
          });
      });
    },

    deleteAdsPreset({ id }) {
      return new Promise((resolve, reject) => {
        axiosClient
          .delete(URL + "/embed/sda/delete?id=" + id)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: response.data.msg,
              });
              this.getAdsPresets();
              app.config.globalProperties.$router.push(
                "/playback/advertisements"
              );
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getUserPaginationLimit() {
      return new Promise((resolve) => {
        const limitTmp =
          useAuthStore().user.preferences.adsLimit ||
          useAuthStore().user.preferences.playback_ads_limit;

        if (limitTmp) {
          this.pagination.limit = parseInt(limitTmp);
        }

        resolve();
      });
    },

    updateUserPaginationLimit() {
      //delete old naming
      if (useAuthStore().user.preferences["adsLimit"]) {
        delete useAuthStore().user.preferences["adsLimit"];

        useAuthStore().savePreferences({
          preferences: {
            adsLimit: null,
          },
        });
      }

      const limitTmp = this.pagination.limit.toString();

      if (useAuthStore().user.preferences.playback_ads_limit !== limitTmp) {
        useAuthStore().user.preferences["playback_ads_limit"] = limitTmp;

        useAuthStore().savePreferences({
          preferences: {
            playback_ads_limit: limitTmp,
          },
        });
      }
    },
  },

  getters: {
    mediaAdsTemplateDropdownOptions: (state) => {
      const defaultOption = [
        { id: -1, name: "Use from folder settings" },
        { id: 0, name: "None" },
      ];

      if (!state.adsListNames) return defaultOption;

      return [...defaultOption, ...state.adsListNames];
    },

    liveAdsTemplateDropdownOptions: (state) => {
      const defaultOption = [{ id: 0, name: "None" }];

      if (!state.adsListNames) return defaultOption;

      return [...defaultOption, ...state.adsListNames];
    },

    folderAdsTemplateDropdownOptions: (state) => {
      const defaultOption = [
        { id: -1, name: "Inherited from parent folder" },
        { id: 0, name: "None" },
      ];

      if (!state.adsListNames) return defaultOption;

      return [...defaultOption, ...state.adsListNames];
    },
  },
});
