import { createApp, markRaw } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import App from "./App.vue";
import router from "./router";
import VeeValidatePlugin from "./includes/validation";
import Notifications from "@kyvg/vue3-notification";
import "@/assets/base.css";
import { useAuthStore } from "@/stores/auth";
import { BrowserTracing } from "@sentry/tracing";
import { useGetPermissions } from "@/composables/global/getPermissions";
import JsonViewer from "vue3-json-viewer";
import "vue3-json-viewer/dist/index.css";
import AssetNotFound from "@/components/Base/AssetNotFound.vue";
import BaseAlert from "@/components/Base/BaseAlert.vue";
import BaseButton from "@/components/Base/BaseButton.vue";
import BaseButtonCopy from "@/components/Base/BaseButtonCopy.vue";
import BaseButtonOptions from "@/components/Base/BaseButtonOptions.vue";
import BaseCheckboxNew from "@/components/Base/BaseCheckboxNew.vue";
import BaseRadio from "@/components/Base/BaseRadio.vue";
import BaseInputNew from "@/components/Base/BaseInputNew.vue";
import BaseNumberInputNew from "@/components/Base/BaseNumberInputNew.vue";
import BaseTooltip from "@/components/Base/BaseTooltip.vue";
import BaseTooltipNew from "@/components/Base/BaseTooltipNew.vue";
import BaseTopBar from "@/components/Base/BaseTopBar.vue";
import BaseTextarea from "@/components/Base/BaseTextarea.vue";
import BaseRichTextNew from "@/components/Base/BaseRichTextNew.vue";
import BaseCard from "@/components/Base/BaseCard.vue";
import BaseCardTab from "@/components/Base/BaseCardTab.vue";
import BaseCardColumnHeading from "@/components/Base/BaseCardColumnHeading.vue";
import BaseChip from "@/components/Base/BaseChip.vue";
import BaseSelect from "@/components/Base/BaseSelect.vue";
import BaseSelectPlayer from "@/components/Base/BaseSelectPlayer.vue";
import BaseSelectAds from "@/components/Base/BaseSelectAds.vue";
import BaseSelectProtection from "@/components/Base/BaseSelectProtection.vue";
import BaseSelectNew from "@/components/Base/BaseSelectNew.vue";
import BaseSelectObject from "@/components/Base/BaseSelectObject.vue";
import BaseSwitch from "@/components/Base/BaseSwitch.vue";
import BaseTabsParams from "@/components/Base/BaseTabsParams.vue";
import BaseTabsSection from "@/components/Base/BaseTabsSection.vue";
import BaseTabsSimple from "@/components/Base/BaseTabsSimple.vue";
import BaseToggleSwitch from "@/components/Base/BaseToggleSwitch.vue";
import BaseModalConfirmationDialog from "@/components/Base/BaseModalConfirmationDialog.vue";
import BaseModalImageCropper from "@/components/Base/BaseModalImageCropper.vue";
import BaseDatePickerNew from "@/components/Base/BaseDatePickerNew.vue";
import BaseColorPicker from "@/components/Base/BaseColorPicker.vue";
import BaseColorInput from "@/components/Base/BaseColorInput.vue";
import BaseComboBox from "@/components/Base/BaseComboBox.vue";
import BaseComboBoxTags from "@/components/Base/BaseComboBoxTags.vue";
import BasePagination from "@/components/Base/BasePagination.vue";
import BaseSortChevron from "@/components/Base/BaseSortChevron.vue";
import BaseFolderSelect from "@/components/Base/BaseFolderSelect.vue";
import BaseModalPanel from "@/components/Base/BaseModalPanel.vue";
import BaseNotificationAlert from "@/components/Base/BaseNotificationAlert.vue";
import PermissionDenied from "@/components/Base/PermissionDenied.vue";
import NoItemsToShow from "@/components/Base/NoItemsToShow.vue";
import BsButton from "@/components/Base/BsButton.vue";
import BsToggleSwitch from "@/components/Base/BsToggleSwitch.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ApexCharts from "apexcharts";
import VueApexCharts from "vue3-apexcharts";
import { addGTM } from "@/GoogleTagManager";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import * as Sentry from "@sentry/vue";
import "virtual:vite-plugin-sentry/sentry-config";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

const dist = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist;
const release = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release;

library.add(fas, far);
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export const app = createApp(App);
const pinia = createPinia();

pinia.use(({ store }) => {
  store.$router = markRaw(router);
});
pinia.use(piniaPluginPersistedstate);

app.use(pinia);
app.use(router);
app.use(VeeValidatePlugin);
app.use(Notifications);
app.use(JsonViewer);

if (["prod"].includes(import.meta.env.VITE_ENVIRONMENT)) {
  addGTM();
}

if (import.meta.env.VITE_ENVIRONMENT !== "localhost") {
  const authStore = useAuthStore();
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    tunnel: import.meta.env.VITE_SENTRY_TUNNEL,
    environment: import.meta.env.VITE_ENVIRONMENT,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [
          "localhost:3000",
          "admin.backscreen.com",
          /^\//,
        ],
      }),
    ],
    trackComponents: true,
    hooks: ["activate", "create", "destroy", "mount", "update"],
    debug: import.meta.env.VITE_ENVIRONMENT !== "prod",
    ...(authStore.user && {
      initialScope: {
        user: {
          id: authStore.user.id,
          name: authStore.user.name,
          client_id: authStore.user.client_id,
          client: authStore.user.client_name,
        },
      },
    }),
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    dist,
    release,
  });
}

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  if (to.path === "/login" && authStore.is_loggedin) {
    next({ path: "/" });
  } else {
    if (to.meta.guard && !authStore.is_loggedin) next({ path: "/login" });
    else next();
  }
});

app.component("AssetNotFound", AssetNotFound);
app.component("BaseButton", BaseButton);
app.component("BaseButtonCopy", BaseButtonCopy);
app.component("BaseButtonOptions", BaseButtonOptions);
app.component("BaseAlert", BaseAlert);
app.component("BaseCheckboxNew", BaseCheckboxNew);
app.component("BaseRadio", BaseRadio);
app.component("BaseInputNew", BaseInputNew);
app.component("BaseNumberInputNew", BaseNumberInputNew);
app.component("BaseTooltip", BaseTooltip);
app.component("BaseTooltipNew", BaseTooltipNew);
app.component("BaseTopBar", BaseTopBar);
app.component("BaseTextarea", BaseTextarea);
app.component("BaseRichTextNew", BaseRichTextNew);
app.component("BaseCard", BaseCard);
app.component("BaseCardTab", BaseCardTab);
app.component("BaseCardColumnHeading", BaseCardColumnHeading);
app.component("BaseChip", BaseChip);
app.component("BaseSelect", BaseSelect);
app.component("BaseSelectPlayer", BaseSelectPlayer);
app.component("BaseSelectAds", BaseSelectAds);
app.component("BaseSelectProtection", BaseSelectProtection);
app.component("BaseSelectNew", BaseSelectNew);
app.component("BaseSelectObject", BaseSelectObject);
app.component("BaseSwitch", BaseSwitch);
app.component("BaseTabsSection", BaseTabsSection);
app.component("BaseToggleSwitch", BaseToggleSwitch);
app.component("BaseTabsParams", BaseTabsParams);
app.component("BaseTabsSimple", BaseTabsSimple);
app.component("BaseModalConfirmationDialog", BaseModalConfirmationDialog);
app.component("BaseModalImageCropper", BaseModalImageCropper);
app.component("BaseDatePickerNew", BaseDatePickerNew);
app.component("BaseColorPicker", BaseColorPicker);
app.component("BaseComboBox", BaseComboBox);
app.component("BaseComboBoxTags", BaseComboBoxTags);
app.component("BaseColorInput", BaseColorInput);
app.component("BasePagination", BasePagination);
app.component("BaseSortChevron", BaseSortChevron);
app.component("BaseFolderSelect", BaseFolderSelect);
app.component("BaseModalPanel", BaseModalPanel);
app.component("BaseNotificationAlert", BaseNotificationAlert);
app.component("PermissionDenied", PermissionDenied);
app.component("NoItemsToShow", NoItemsToShow);
app.component("BsButton", BsButton);
app.component("BsToggleSwitch", BsToggleSwitch);
app.component("v-select", vSelect);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("ApexChart", VueApexCharts);

const getPermissions = useGetPermissions();
app.provide("getPermissions", getPermissions);

//v-focus custom directive
app.directive("focus", {
  mounted(el) {
    el.focus();
  },
});

app.mount("#app");
