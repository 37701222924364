import { defineStore } from "pinia";
import axiosClient from "@/axiosClient";
import { useAdminStore } from "@/stores/admin.js";
import { useAuthStore } from "@/stores/auth";
import { defaultPreferences } from "@/helpers/defaultPreferences";

const URL = import.meta.env.VITE_API_URL;

export default defineStore({
  id: "useListMediaStore",
  state: () => ({
    list: null,
    listLoading: false,
    mediaInfo: null,
    selectedData: [],
    selectedAction: {},
    selectedFolder: 0,
    mediaLimit: 20,
    mediaOffset: 0,
    orderBy: "id",
    orderDir: true,
    searchValue: "",
    mediaListParams: {
      id: null,
      title: null,
      status: [],
      status_exclude: [],
      publisher_status: null,
      technical_status: [],
      technical_status_exclude: [],
      errors: null,
      metadata: {},
      tags: [],
      source_file_ext: [],
      created_period: null,
      created_from: null,
      created_to: null,
      cat_id: null,
      resolution: null,
      file_length: null,
      gop_size: null,
      frame_rate: null,
      codec: null,
      language: null,
    },
    selectedSearchFilters: [],
    // view related
    showFolders: true,
    activeView: "default",
    mediaViewsNew: null,
    controller: null,
  }),

  actions: {
    getList() {
      this.listLoading = true;

      if (this.controller) {
        this.controller.abort();
      }

      this.controller = new AbortController();

      const metadataTmp = this.mediaListParams.metadata
        ? Object.entries(this.mediaListParams.metadata).map(([key, item]) => {
            if (item) {
              let obj = {};
              obj[key] = this.mediaViewsNew[
                this.activeViewIndex
              ].selectedSearchWildcards.includes("Metadata - " + key)
                ? item.map((val) => "*" + val + "*")
                : item;
              return obj;
            }
          })
        : false;

      const params = {
        offset: this.mediaOffset,
        limit: this.mediaLimit,
        order_by:
          this.orderBy !== "status"
            ? this.orderBy
            : useAuthStore().advanced
            ? "status_advanced"
            : "status_simple",
        order_dir: this.orderDir ? "desc" : "asc",

        search: this.searchValue ? this.searchValue : null,

        status:
          this.mediaListParams.status.length === 0
            ? "any"
            : this.mediaListParams.status,

        ...(this.mediaListParams.status_exclude.length && {
          status_exclude: this.mediaListParams.status_exclude,
        }),

        ...(this.mediaListParams.publisher_status && {
          publisher_status:
            this.mediaListParams.publisher_status !== "all"
              ? this.mediaListParams.publisher_status
              : null,
        }),

        ...(this.mediaListParams.technical_status.length &&
          useAdminStore().adminSettings?.metadata?.search_index_ready && {
            tech_status: this.mediaListParams.technical_status,
          }),

        ...(this.mediaListParams.technical_status_exclude.length &&
          useAdminStore().adminSettings?.metadata?.search_index_ready && {
            tech_status_exclude: this.mediaListParams.technical_status_exclude,
          }),

        ...(this.mediaListParams.errors &&
          useAdminStore().adminSettings?.metadata?.search_index_ready && {
            errors_warnings: this.mediaListParams.errors.map((item) => {
              if (
                this.mediaViewsNew[
                  this.activeViewIndex
                ].selectedSearchWildcards.includes("errors")
              ) {
                return "*" + item.trim() + "*";
              } else return item.trim();
            }),
          }),

        ...(this.mediaListParams.id && {
          id: this.mediaListParams.id.map((item) => {
            item.trim();
            return parseFloat(item);
          }),
        }),

        ...(this.mediaListParams.title &&
          useAdminStore().adminSettings?.metadata?.search_index_ready && {
            name: this.mediaListParams.title.map((item) => {
              if (
                this.mediaViewsNew[
                  this.activeViewIndex
                ].selectedSearchWildcards.includes("title")
              ) {
                return "*" + item.trim() + "*";
              } else return item.trim();
            }),
          }),

        ...(this.mediaListParams.created_period && {
          created_period: this.mediaListParams.created_period,
        }),

        ...(this.mediaListParams.created_from && {
          created_from: this.mediaListParams.created_from,
        }),

        ...(this.mediaListParams.created_to && {
          created_to: this.mediaListParams.created_to,
        }),

        ...(this.mediaListParams.cat_id && {
          cat_id: this.mediaListParams.cat_id,
        }),

        ...(this.mediaListParams.source_file_ext.length !== 0 && {
          "file_extension[source]": this.mediaListParams.source_file_ext.map(
            (item) => {
              item.trim();
              if (
                this.mediaViewsNew[
                  this.activeViewIndex
                ].selectedSearchWildcards.includes("source_file_ext")
              ) {
                return "*" + item + "*";
              } else {
                return item;
              }
            }
          ),
        }),

        ...(this.mediaListParams.resolution && {
          "media_info[resolution]": this.mediaListParams.resolution,
        }),
        ...(this.mediaListParams.file_length && {
          "media_info[length]": this.mediaListParams.file_length,
        }),
        ...(this.mediaListParams.gop_size && {
          "media_info[gop_size]": this.mediaListParams.gop_size,
        }),
        ...(this.mediaListParams.frame_rate && {
          "media_info[frame_rate]": this.mediaListParams.frame_rate,
        }),
        ...(this.mediaListParams.codec && {
          "media_info[codec]": this.mediaListParams.codec,
        }),
        ...(this.mediaListParams.language && {
          "media_info[language]": this.mediaListParams.language,
        }),
        ...(this.mediaListParams.gop_size && {
          "media_info[gop_size]": this.mediaListParams.gop_size,
        }),
        ...(this.mediaListParams.audio_language && {
          "media_info[audio_language]": this.mediaListParams.audio_language,
        }),
        ...(this.mediaListParams.subtitle_language && {
          "media_info[subtitle_language]":
            this.mediaListParams.subtitle_language,
        }),
        ...(this.mediaListParams.audio_tracks_count && {
          "media_info[audio_tracks_count]":
            this.mediaListParams.audio_tracks_count,
        }),
        ...(this.mediaListParams.audio_type && {
          "media_info[audio_type]": this.mediaListParams.audio_type,
        }),
        ...(this.mediaListParams.content_type && {
          "media_info[content_type]": this.mediaListParams.content_type,
        }),

        ...(this.mediaListParams.tags && {
          tags: this.mediaListParams.tags.map((item) => item.tag),
        }),

        ...(metadataTmp &&
          useAdminStore().adminSettings?.metadata?.search_index_ready && {
            metadata: metadataTmp,
          }),

        return: [
          "category",
          "sources",
          "images",
          "availability",
          "tech_status",
          "warnings",
          "errors",
          "tags",
          "actions",
          "transcode_info",
          "transcodes",
          "language",
          "file.streams",
          "alerts",
        ],
      };

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/media/list/", { params, signal: this.controller.signal })
          .then((response) => {
            if (response.data.code === 0) {
              this.list = response.data;
              this.controller = null;
            }
            this.listLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.listLoading = false;
            reject(error);
          });
      });
    },

    getMediaInfo() {
      let params = {
        return: ["cnames"],
      };
      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/media/getinfo/", { params })
          .then((response) => {
            if (response.data.code === 0) {
              this.mediaInfo = response.data.data;
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    resetMediaListParams() {
      this.mediaListParams = defaultPreferences.mediaListParams;
      this.searchValue = "";
    },
  },

  getters: {
    activeViewIndex() {
      if (!this.mediaViewsNew) return 0;

      const indexTmp = this.mediaViewsNew.findIndex(
        (item) => item.id === this.activeView
      );

      return indexTmp >= 0 ? indexTmp : 0;
    },

    mediaInfoFilter() {
      if (
        !this.mediaListParams.resolution &&
        !this.mediaListParams.file_length &&
        !this.mediaListParams.gop_size &&
        !this.mediaListParams.frame_rate &&
        !this.mediaListParams.codec &&
        !this.mediaListParams.language
      )
        return { media_info: null };
    },
  },

  persist: {
    paths: ["showFolders"],
  },
});
