<script setup>
import { ref } from "vue";
import useTooltip from "@/composables/global/tooltip";

defineEmits(["cancel", "save", "addNew"]);

defineProps({
  faIcon: {
    type: String,
    required: false,
    default: "",
  },
  icon: {
    type: Function,
    required: false,
  },
  title: {
    type: String,
    required: true,
  },
  infoTooltip: {
    type: String,
    required: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  showButtons: {
    type: Boolean,
    required: false,
    default: false,
  },
  showAddNewText: {
    type: String,
    required: false,
  },
  downloadCSV: {
    type: String,
    required: false,
    default: "",
  },
  actionInProgress: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabledBtnMessage: {
    type: String,
    required: false,
    default: "",
  },
});

const showErrorMsg = ref(false);

const { showTooltip, reference, floating, floatingStyles } = useTooltip("top");
</script>

<template>
  <div class="flex gap-2.5 mb-4 mt-2">
    <h1 class="flex flex-1 gap-2.5 items-center min-h-[32px]">
      <font-awesome-icon
        v-if="faIcon"
        :icon="'fa-solid ' + faIcon"
        class="text-gray-500 my-auto"
      />

      <component v-else :is="icon()" class="w-[18px] h-[18px] text-gray-500" />

      <span class="text-xl text-gray-500 font-medium">{{ title }}</span>

      <a
        v-if="downloadCSV"
        ref="reference"
        :href="downloadCSV"
        target="_blank"
        class="mx-1 mt-1 text-gray-500 cursor-pointer hover:text-gray-700 hover:scale-105"
        @mouseover="showTooltip = true"
        @mouseleave="showTooltip = false"
      >
        <font-awesome-icon icon="fa-solid fa-file-csv" class="h-4 w-4" />
      </a>

      <span
        v-show="showTooltip"
        ref="floating"
        class="bg-gray-200 p-2 rounded z-[9999] text-xs text-gray-500 font-medium"
        :style="floatingStyles"
      >
        Download selected list (CSV)
      </span>
    </h1>

    <slot />

    <div v-if="!showButtons">
      <!--   Removed disabled for discard :disabled="disabled"  -->
      <p
        v-if="disabled && showErrorMsg && disabledBtnMessage"
        class="absolute top-[76px] pr-3 right-5 text-xs text-gray-500 font-medium"
      >
        {{ disabledBtnMessage }}
      </p>

      <div class="flex gap-2.5">
        <BsButton theme="s" @click="$emit('cancel')"> Discard </BsButton>

        <BsButton
          theme="p"
          :disabled="disabled"
          @mouseenter="showErrorMsg = true"
          @mouseleave="showErrorMsg = false"
          @click="$emit('save')"
        >
          Save
        </BsButton>
      </div>
    </div>

    <BsButton
      v-if="showAddNewText"
      theme="p"
      icon="fa-plus"
      :disabled="disabled"
      :action-in-progress="actionInProgress"
      @click="$emit('addNew')"
    >
      {{ showAddNewText }}
    </BsButton>
  </div>
</template>
