<script setup>
import { computed } from "vue";
import { notify } from "@kyvg/vue3-notification";

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: null,
  },
  labelStyle: {
    type: String,
    required: false,
    default: "font-medium text-gray-500 text-xs mb-1",
  },
  rows: {
    type: Number,
    required: false,
    default: 4,
  },
  cols: {
    type: Number,
    required: false,
    default: 30,
  },
  id: {
    type: String,
    required: true,
    default: "description",
  },
  modelValue: {
    type: String,
    required: true,
    default: "",
  },
  placeholder: {
    type: String,
    required: false,
    default: "",
  },
  className: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  buttons: {
    type: Array,
    required: false,
    default: () => [],
  },
  resetToDefaultIcon: {
    type: [Boolean],
    required: false,
    default: false,
  },
});

const emit = defineEmits([
  "update:modelValue",
  "blur",
  "resetEmbed",
  "resetToDefault",
]);

const tmpValue = computed({
  get() {
    return props.modelValue;
  },
  set(evt) {
    emit("update:modelValue", evt);
  },
});

const copy = () => {
  navigator.clipboard.writeText(props.modelValue);

  notify({
    type: "success",
    text: "Copied to clipboard!",
    duration: 1000,
  });
};
</script>

<template>
  <div class="w-full">
    <div v-if="label" class="flex gap-0.5">
      <label
        :for="id"
        class="block"
        :class="labelStyle"
      >
        {{ label }}
      </label>
      <div v-if="!disabled" class="h-3 w-3 flex">
        <font-awesome-icon
          v-if="resetToDefaultIcon"
          title="Reset to default value"
          icon="fa solid fa-rotate-left"
          class="cursor-pointer text-gray-500 font-medium text-sm h-3 w-3 my-auto ml-2.5"
          @click="emit('resetToDefault')"
        />
      </div>
    </div>
    <div class="w-full flex relative">
      <textarea
        :disabled="disabled"
        :placeholder="placeholder"
        :id="id"
        :name="id"
        v-model="tmpValue"
        :cols="cols"
        :rows="rows"
        @blur="$emit('blur')"
        class="block w-full border border-gray-300 placeholder-[#999999] rounded-md shadow-sm py-1 px-3 focus:outline-none focus:border-gray-300 focus:ring-0 text-sm disabled:bg-gray-200"
        :class="[className, { 'pr-8': buttons.length }]"
      />
      <div
        v-if="buttons.length"
        class="absolute inset-y-0 right-0 grid items-center mr-2 cursor-pointer"
      >
        <button
          v-if="buttons.includes('reset')"
          type="button"
          title="Reset"
          class="flex items-center text-main1 transform cursor-pointer transition duration-250 ease-in-out hover:text-main1-dark hover:scale-110 disabled:opacity-50 disabled:cursor-default disabled:text-main1 disabled:scale-100"
          @click="$emit('resetEmbed')"
        >
          <font-awesome-icon icon="fa-solid fa-rotate" class="h-5 w-5 mt-0.5" />
        </button>

        <button
          v-if="buttons.includes('copy')"
          type="button"
          title="Copy"
          class="flex items-center text-main1 transform cursor-pointer transition duration-250 ease-in-out hover:text-main1-dark hover:scale-110 disabled:opacity-50 disabled:cursor-default disabled:text-main1 disabled:scale-100"
          @click="copy()"
        >
          <font-awesome-icon icon="fa-solid fa-copy" class="h-5 w-5 mt-0.5" />
        </button>
      </div>
    </div>
  </div>
</template>
