<script setup>
defineProps({
  columnName: {
    type: String,
    required: true,
  },
  orderName: {
    type: String,
    required: true,
  },
  orderBy: {
    type: String,
    required: true,
  },
  orderDir: {
    type: Boolean,
    required: true,
  },
});

const alphabeticalColumns = [
  "category.name",
  "name",
  "asset_id",
  "status",
  "tech_status",
  "tag",
  "category_id",
  "type",
  "encoding.encoder",
];

const upIcon = (orderName) => {
  return alphabeticalColumns.includes(orderName)
    ? "fa-solid fa-arrow-down-a-z"
    : "fa-solid fa-arrow-down-1-9";
};

const downIcon = (orderName) => {
  return alphabeticalColumns.includes(orderName)
    ? "fa-solid fa-arrow-up-a-z"
    : "fa-solid fa-arrow-up-1-9";
};
</script>

<template>
  <div
    class="px-3 flex items-center justify-between w-full group transition duration-300 ease-in-out"
  >
    <span class="flex">
      <p class="truncate">
        {{ columnName }}
      </p>
      <font-awesome-icon
        v-if="orderBy === orderName && !orderDir"
        :icon="
          orderBy === orderName && !orderDir
            ? upIcon(orderName)
            : downIcon(orderName)
        "
        class="w-4 h-4 ml-2 my-auto text-gray-400 group-hover:text-gray-500 text-lg"
      />
      <font-awesome-icon
        v-if="orderBy === orderName && orderDir"
        :icon="
          orderBy === orderName && !orderDir
            ? upIcon(orderName)
            : downIcon(orderName)
        "
        class="w-4 h-4 ml-2 my-auto text-gray-400 group-hover:text-gray-500 text-lg"
      />
    </span>
  </div>
</template>
