import { defineStore } from "pinia";
import axiosClient from "@/axiosClient";

const URL = import.meta.env.VITE_API_URL;
export default defineStore({
  id: "useUserRoleStore",
  state: () => ({
    userRoleListLoading: false,
    userRoleList: null,
  }),
  actions: {
    getUserRoleList({ client_id }) {
      this.userRoleListLoading = true;
      let params = {
        client_id,
        include_public: 1,
        limit: 1000,
      };
      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/admin/userrole/list", { params })
          .then((response) => {
            if (response.data.code === 0) {
              this.userRoleList = response.data.data;
            }
            this.userRoleListLoading = false;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});
