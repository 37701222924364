<script setup>
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  ComboboxLabel,
} from "@headlessui/vue";
import { computed, ref } from "vue";
import { SelectorIcon } from "@heroicons/vue/outline";
import {
  useElementBounding,
  useElementVisibility,
  useWindowSize,
} from "@vueuse/core";

const props = defineProps({
  modelValue: {
    type: [String, Number, Object, null],
    required: false,
    default: "",
  },
  label: {
    type: String,
    required: false,
  },
  options: {
    type: [Array],
    required: true,
  },
  trackBy: {
    type: [Array],
    required: false,
    default: () => ["name"],
  },
  disabled: {
    type: [Boolean],
    required: false,
    default: false,
  },
  placeholder: {
    type: String,
    required: false,
    default: "none",
  },
  noBorder: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabledOptions: {
    type: [Array],
    required: false,
    default: () => [],
  },
  disabledOptionTrackBy: {
    type: String,
    required: false,
    default: "id",
  },
});
const emit = defineEmits(["update:modelValue"]);

const inputFocus = ref(null);

const functionRef = (el) => {
  inputFocus.value = el;
};

const query = ref("");
const singleBatch = ref(100);
const lazyLoadBatches = ref(1);

const totalBatch = computed(() => {
  return singleBatch.value * lazyLoadBatches.value;
});

const optimizeSearch = computed(() => {
  if (!props.options || !Array.isArray(props.options)) return false;
  return props.options.length > singleBatch.value;
});

const filteredQueryOptions = computed(() => {
  return props.options.filter((item) => {
    return (
      item.id.toString().includes(query.value.toString()) ||
      item.name.toLowerCase().includes(query.value.toLowerCase())
    );
  });
});

const filteredOptions = computed(() => {
  if (query.value.length < 3 && optimizeSearch.value) {
    return [];
  }

  return query.value === ""
    ? props.options
    : filteredQueryOptions.value.filter((item, index) => {
        return index < totalBatch.value;
      });
});

const checkOptimizedSearch = computed(() => {
  return props.options.some(
    (item) =>
      item.id.toString().includes(query.value.toString()) ||
      item.name.toLowerCase().includes(query.value.toLowerCase())
  );
});

const tmpValue = computed({
  get() {
    if (
      !props.modelValue ||
      (Array.isArray(props.modelValue) && !props.modelValue.length)
    ) {
      return [];
    }
    return props.modelValue;
  },
  set(evt) {
    emit("update:modelValue", evt);
  },
});

const spliceValue = (i) => {
  const start = JSON.parse(JSON.stringify(tmpValue.value));
  start.splice(i, 1);
  tmpValue.value = start;
};

const { right, bottom, left, top } = useElementBounding(inputFocus);
const targetIsVisible = useElementVisibility(inputFocus);
const { height } = useWindowSize();

const styleObject = computed(() => {
  let bottomTmp = {
    maxHeight: height.value - bottom.value - 50 + "px",
    top: bottom.value + 4 + "px",
    width: right.value - left.value + "px",
    left: left.value + "px",
  };
  let topTmp = {
    maxHeight: top.value - 50 + "px",
    top: top.value - 4 + "px",
    width: right.value - left.value + "px",
    left: left.value + "px",
    transform: "translateY(-100%)",
    marginBottom: 4 + "px",
  };
  return height.value - bottom.value > 200 ? bottomTmp : topTmp;
});

const clearQuery = (evt) => {
  query.value = evt;
  lazyLoadBatches.value = 1;
};
</script>

<template>
  <Combobox
    :disabled="!!disabled"
    class="w-full font-normal"
    v-model="tmpValue"
    as="div"
    multiple
    nullable
  >
    <ComboboxLabel class="text-xs font-medium text-gray-500 block mb-1">
      {{ label }}
    </ComboboxLabel>

    <div
      :ref="functionRef"
      class="flex flex-wrap max-w-full w-full relative rounded-md text-left focus:outline-none sm:text-sm pr-12 pl-1"
      :class="[
        noBorder
          ? 'border-transparent focus:border--transparent text-xs py-2'
          : 'border rounded-md border-gray-300 shadow-sm text-sm',
        disabled ? 'bg-gray-200' : 'bg-white',
      ]"
    >
      <template v-if="tmpValue">
        <BaseChip
          v-for="(item, i) in tmpValue"
          :key="item"
          :name="item.name"
          class="-mt-0.5"
          @removeChip="spliceValue(i)"
        />
      </template>

      <ComboboxInput
        v-slot="{ open }"
        class="flex-1 min-w-[50px] placeholder-[#999999] w-full rounded border-none py-[2px] focus:ring-0 disabled:bg-gray-200 disabled:cursor-default font-normal text-base text-gray-900"
        :placeholder="placeholder"
        @focus="open = true"
        :display-value="() => query"
        @change="clearQuery($event.target.value)"
      />

      <ComboboxButton
        class="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-2 disabled:bg-gray-200 disabled:cursor-default"
        @click="clearQuery('')"
      >
        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </ComboboxButton>
    </div>

    <transition
      leave-active-class="transition duration-100 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <Teleport to="body">
        <ComboboxOptions
          v-if="targetIsVisible"
          class="z-[999999] absolute border overflow-y-auto overscroll-x-hidden rounded bg-white p-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm"
          :style="styleObject"
        >
          <ComboboxOption
            v-if="filteredOptions.length === 0"
            :disabled="true"
            as="template"
          >
            <li
              class="relative cursor-pointer select-none py-2 px-2 text-gray-900 font-normal"
            >
              <span class="block text-left">
                List is empty!
                {{
                  optimizeSearch && checkOptimizedSearch
                    ? "Type to search, min 3 chars!"
                    : ""
                }}
              </span>
            </li>
          </ComboboxOption>

          <ComboboxOption
            v-else
            as="template"
            v-slot="{ active, selected, disabled }"
            v-for="option in filteredOptions"
            :key="option"
            :value="option"
            :disabled="disabledOptions.includes(option[disabledOptionTrackBy])"
            @click="clearQuery('')"
          >
            <li
              class="rounded relative cursor-pointer select-none py-2 px-2"
              :class="[
                disabled ? 'bg-gray-300' : '',
                active ? 'bg-main1-dark text-white' : 'text-gray-900',
                selected ? 'font-medium text-white bg-main1' : 'font-normal',
              ]"
            >
              <div class="block truncate text-left flex">
                <p v-for="(track, i) in trackBy" :key="i">
                  {{ option[track] }} &nbsp;
                </p>
              </div>
            </li>
          </ComboboxOption>

          <div
            v-if="
              optimizeSearch &&
              query.length > 2 &&
              filteredQueryOptions.length !== filteredOptions.length
            "
            @click.prevent="lazyLoadBatches++"
          >
            <li
              class="rounded relative cursor-pointer select-none py-2 px-2 text-main1 font-semibold hover:bg-gray-100"
            >
              <span class="block truncate text-left">
                Show more options ...
              </span>
            </li>
          </div>
        </ComboboxOptions>
      </Teleport>
    </transition>
  </Combobox>
</template>
