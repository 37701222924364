<script setup>
import { computed } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  firstValue: {
    type: [String, Number],
    required: true,
  },
  secondValue: {
    type: [String, Number],
    required: true,
  },
  thirdValue: {
    type: [String, Number],
    required: false,
  },
  label: {
    type: String,
    required: false,
  },
  firstLabel: {
    type: String,
    required: true,
  },
  secondLabel: {
    type: String,
    required: true,
  },
  thirdLabel: {
    type: String,
    required: false,
  },
  checkedValue: {
    type: [String, Number],
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabledFirst: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabledSecond: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabledThird: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["changeValue"]);

const checkedValueTmp = computed({
  get() {
    return props.checkedValue;
  },
  set(value) {
    emit("changeValue", value);
  },
});
</script>

<template>
  <div>
    <label v-if="label" class="block text-xs font-medium text-gray-500 mb-1">
      {{ label }}
    </label>

    <div class="flex items-center h-5">
      <label
        class="block text-xs font-medium text-gray-500 cursor-pointer disabled:cursor-default"
      >
        <input
          class="focus:ring-0 h-4 w-4 text-main1 border-gray-300 mr-2 cursor-pointer disabled:bg-gray-300 disabled:cursor-default"
          :class="disabled || disabledFirst ? 'hover:text-gray-300' : ''"
          type="radio"
          :name="name"
          :value="firstValue"
          v-model="checkedValueTmp"
          :disabled="disabled || disabledFirst"
        />
        {{ firstLabel }}
      </label>

      <label
        class="ml-5 block text-xs font-medium text-gray-500 cursor-pointer disabled:cursor-default"
      >
        <input
          class="focus:ring-0 h-4 w-4 text-main1 border-gray-300 mr-2 cursor-pointer disabled:bg-gray-300 disabled:cursor-default"
          :class="disabled || disabledSecond ? 'hover:text-gray-300' : ''"
          type="radio"
          :name="name"
          :value="secondValue"
          v-model="checkedValueTmp"
          :disabled="disabled || disabledSecond"
        />
        {{ secondLabel }}
      </label>

      <label
        v-if="thirdLabel"
        class="ml-5 block text-xs font-medium text-gray-500 cursor-pointer disabled:cursor-default"
      >
        <input
          class="focus:ring-0 h-4 w-4 text-main1 border-gray-300 mr-2 cursor-pointer disabled:bg-gray-300 disabled:cursor-default"
          :class="disabled || disabledThird ? 'hover:text-gray-300' : ''"
          type="radio"
          :name="name"
          :value="thirdValue"
          v-model="checkedValueTmp"
          :disabled="disabled || disabledThird"
        />
        {{ thirdLabel }}
      </label>
    </div>
  </div>
</template>
