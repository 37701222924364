<script setup>
import { useAuthStore } from "@/stores/auth";

const auth = useAuthStore();

defineProps({
  item: {
    type: Object,
    required: true,
  },
  sidebarCurrent: {
    type: String,
    required: true,
  },
});
</script>

<template>
  <router-link
    :to="item.href"
    :class="[
      item.name === sidebarCurrent
        ? 'bg-gray-100 text-gray-900'
        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
      'group flex items-center px-2 py-2 text-base font-medium rounded-md',
    ]"
    @click="auth.mobileSidebarOpen = false"
  >
    <font-awesome-icon
      :icon="'fa-solid ' + item.icon"
      :class="[
        item.name === sidebarCurrent
          ? 'text-gray-500'
          : 'text-gray-400 group-hover:text-gray-500',
        'mr-4 flex-shrink-0 h-6 w-6',
      ]"
    />
    <span>
      {{ item.name }}
    </span>
  </router-link>
</template>
