<script setup>
import { ref } from "vue";
import { XIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/outline";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    required: false,
    default: "bg-main1",
  },
  expandable: {
    type: Boolean,
    required: false,
    default: false,
  },
  childTag: {
    type: Object,
    required: false,
  },
  allowedCountries: {
    type: Array,
    required: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideRemoveBtn: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const isOpen = ref(false);

const emit = defineEmits(["click", "removeChip", "changeCountries", "addTag"]);

const removeChip = () => {
  emit("removeChip", props.name);
};

const collapse = () => {
  isOpen.value = !isOpen.value;
  emit("click");
};

const setCountryColor = (value) => {
  return props.allowedCountries.includes(value) ? "#1061FF" : "#D3D3D3";
};

const changeCountries = (value) => {
  emit("changeCountries", value);
};

const hexToRgb = (hex) => {
  return hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));
};

const checkTextColor = () => {
  if (props.color === "bg-main1") return true;
  const rgb = hexToRgb(props.color);
  return rgb ? !!rgb.filter((item) => item <= 200).length : true;
};
</script>

<template>
  <div class="max-w-full flex-none">
    <div :class="{ 'flex flex-wrap pb-4': expandable }">
      <div
        class="mt-1 mr-1 px-3 py-1 rounded-full flex align-center"
        :class="[
          color === 'bg-main1' && 'bg-main1',
          disabled ? 'cursor-default' : 'cursor-pointer',
        ]"
        :style="color !== 'bg-main1' && `background-color:${color}`"
        @click="!disabled && changeCountries(name)"
      >
        <p
          class="w-full truncate font-semibold text-xs"
          :class="checkTextColor() ? 'text-white' : 'text-gray-700'"
        >
          {{ name }}
        </p>
        <div
          v-if="!hideRemoveBtn && !disabled"
          class="bg-transparent focus:outline-none flex items-center"
          @click.prevent="!disabled && removeChip()"
        >
          <XIcon
            class="w-4 h-4 ml-2 font-semibold text-xs"
            :class="[
              !disabled && 'hover:text-gray-300',
              checkTextColor() ? 'text-white' : 'text-gray-700',
            ]"
          />
        </div>
      </div>

      <div
        v-if="expandable"
        class="text-gray-500 text-xl cursor-pointer hover:text-gray-400 my-auto"
        @click="collapse()"
      >
        <div v-if="isOpen" class="flex">
          <p class="text-sm text-right">Collapse</p>
          <ChevronUpIcon class="w-4 h-4 text-gray-500 ml-0.5 mt-0.5" />
        </div>
        <div v-else class="flex">
          <p class="text-sm">Expand</p>
          <ChevronDownIcon class="w-4 h-4 text-gray-500 ml-0.5 mt-0.5" />
        </div>
      </div>
    </div>

    <div v-if="isOpen" class="flex flex-wrap ml-8 mb-4">
      <BaseChip
        v-for="(country, countryCode) in childTag"
        :name="country"
        :key="countryCode"
        :color="setCountryColor(countryCode)"
        :allowedCountries="allowedCountries"
        :disabled="disabled"
        @changeCountries="changeCountries(countryCode)"
      >
      </BaseChip>
    </div>
  </div>
</template>
