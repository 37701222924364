<script setup>
import { computed } from "vue";

const props = defineProps({
  label: {
    type: String,
    required: false,
  },
  fieldId: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const emit = defineEmits(["changeValue", "resetValue"]);

const valueTmp = computed({
  get() {
    return props.value;
  },
  set(value) {
    emit("changeValue", value);
  },
});
</script>

<template>
  <div class="flex items-center">
    <div
      class="relative w-4 h-4 rounded-full focus:ring-0 focus:ring-offset-0 sm:text-sm border-gray-300 border-2 grow-0 ml-1"
      :class="!disabled ? 'cursor-pointer' : 'cursor-default'"
      :style="{ 'background-color': valueTmp }"
    >
      <label
        v-if="label"
        :for="fieldId"
        class="text-sm font-medium text-gray-500"
      >
        <input
          :id="fieldId"
          type="color"
          class="invisible"
          :disabled="disabled"
          v-model="valueTmp"
        />
      </label>
    </div>

    <font-awesome-icon
      v-if="!disabled"
      icon="fa-solid fa-refresh"
      class="ml-1 text-gray-500 hover:text-gray-500 transform transition duration-250 ease-in-out cursor-pointer flex-shrink-0"
      @click="$emit('resetValue')"
    />

    <label
      v-if="label"
      :for="fieldId"
      class="block font-medium text-xs text-gray-500 ml-3"
    >
      {{ label }}
    </label>
  </div>
</template>
