<script setup>
import { ref, computed } from "vue";
import TopNavigation from "@/components/Layout/TopNavigation.vue";
import Sidebar from "@/components/Layout/SideBar/Sidebar.vue";
import NotificationCentre from "@/components/Layout/NotificationCentre.vue";
import { useAuthStore } from "@/stores/auth.js";
import UserNotices from "@/components/Layout/Notices/UserNotices.vue";
import BsWelcomeMsg from "@/components/Layout/BsWelcomeMsg/BsWelcomeMsg.vue";

const authStore = useAuthStore();

const showNotificationCentre = computed({
  get() {
    return authStore.showNotificationCentre;
  },
  set(value) {
    authStore.showNotificationCentre = value;
  },
});

const alerts = ref(false);
</script>

<template>
  <div v-if="authStore.user" class="flex flex-col h-full w-full">
    <BsWelcomeMsg />

    <TopNavigation />

    <div class="flex flex-auto w-full main-height">
      <Sidebar />

      <main class="flex-1 px-4 sm:px-6 md:px-8 h-full pt-4 overflow-auto">
        <RouterView />
      </main>
    </div>

    <NotificationCentre :open="showNotificationCentre" />

    <BaseAlert v-if="alerts" />

    <UserNotices />
  </div>
</template>

<style scoped lang="scss">
.main-height {
  height: calc(100vh - 70px);
}
</style>
