<script setup>
import { ref, computed, onMounted } from 'vue'
import { useAuthStore } from "@/stores/auth.js";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'

// const open = ref(true);
const authStore = useAuthStore();

const showNotificationCentre = computed({
  get() {
    return authStore.showNotificationCentre
  },
  set(value) {
    authStore.showNotificationCentre = value
  }
});


const testAlerts = ref([
  {
    alertType: "error",
    msg: "ut aliquam purus sit amet luctus venenatis lectus magna fringilla urna porttitor rhoncus dolor purus"
  },
  {
    alertType: "warning",
    msg: "donec ultrices tincidunt arcu non sodales neque sodales ut etiam sit amet nisl purus in mollis nunc sed id semper risus in hendrerit gravida rutrum"
  },
  {
    alertType: "resolved",
    msg: "elementum eu facilisis sed odio morbi quis commodo odio aenean sed adipiscing"
  },
  {
    alertType: "info",
    msg: "in nisl nisi scelerisque eu ultrices vitae auctor eu augue ut lectus arcu bibendum at varius vel pharetra"
  },
  {
    alertType: "error",
    msg: "ut aliquam purus sit amet luctus venenatis lectus magna fringilla urna porttitor rhoncus dolor purus"
  },
  {
    alertType: "warning",
    msg: "donec ultrices tincidunt arcu non sodales neque sodales ut etiam sit amet nisl purus in mollis nunc sed id semper risus in hendrerit gravida rutrum"
  },
  {
    alertType: "resolved",
    msg: "elementum eu facilisis sed odio morbi quis commodo odio aenean sed adipiscing"
  },
  {
    alertType: "info",
    msg: "in nisl nisi scelerisque eu ultrices vitae auctor eu augue ut lectus arcu bibendum at varius vel pharetra"
  },
  {
    alertType: "error",
    msg: "ut aliquam purus sit amet luctus venenatis lectus magna fringilla urna porttitor rhoncus dolor purus"
  },
  {
    alertType: "warning",
    msg: "donec ultrices tincidunt arcu non sodales neque sodales ut etiam sit amet nisl purus in mollis nunc sed id semper risus in hendrerit gravida rutrum"
  },
  {
    alertType: "resolved",
    msg: "elementum eu facilisis sed odio morbi quis commodo odio aenean sed adipiscing"
  },
  {
    alertType: "info",
    msg: "in nisl nisi scelerisque eu ultrices vitae auctor eu augue ut lectus arcu bibendum at varius vel pharetra"
  },
  {
    alertType: "error",
    msg: "ut aliquam purus sit amet luctus venenatis lectus magna fringilla urna porttitor rhoncus dolor purus"
  },
  {
    alertType: "warning",
    msg: "donec ultrices tincidunt arcu non sodales neque sodales ut etiam sit amet nisl purus in mollis nunc sed id semper risus in hendrerit gravida rutrum"
  },
  {
    alertType: "resolved",
    msg: "elementum eu facilisis sed odio morbi quis commodo odio aenean sed adipiscing"
  },
  {
    alertType: "info",
    msg: "in nisl nisi scelerisque eu ultrices vitae auctor eu augue ut lectus arcu bibendum at varius vel pharetra"
  }
]);

const tabs = ref([
  {
    id: "all",
    name: "All",
  },
  {
    id: "info",
    name: "Info",
  },
  {
    id: "error",
    name: "Errors",
  },
  {
    id: "warning",
    name: "Warnings",
  },
  {
    id: "resolved",
    name: "Resolved",
  }
]);

onMounted(() => {
  filterAlerts("all")
})

const selectedTab = ref("all");

const filterAlerts = (value) => {
  selectedTab.value = value
  if (value !== "all") {
    filteredAlerts.value = testAlerts.value.filter(alert => alert.alertType === value)
  } else {
    filteredAlerts.value = testAlerts.value
  }
}

const filteredAlerts = ref([]);
</script>

<template>
  <TransitionRoot as="div" :show="showNotificationCentre">
    <Dialog as="div" class="relative z-10" @close="showNotificationCentre = false">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 top-[5%] right-0 flex max-w-full pl-10"> 
            <TransitionChild 
              as="template" 
              enter="transform transition ease-in-out duration-500 sm:duration-700" 
              enter-from="translate-x-full" 
              enter-to="translate-x-0" 
              leave="transform transition ease-in-out duration-500 sm:duration-700" 
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-lg">
                <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl bg-gray-50">
                  <div class="absolute top-0 mb-7 bg-gray-50 w-screen max-w-lg z-[999]">
                    <div class="px-4 sm:px-6">
                      <div class="flex items-start justify-between mt-5">
                        <DialogTitle class="text-lg font-medium text-gray-900">Notification centre</DialogTitle>
                        <div class="ml-3 flex h-7 items-center">
                          <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="showNotificationCentre = false">
                            <span class="sr-only">Close panel</span>
                            <XIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="flex w-full justify-between mt-2">
                      <div 
                        v-for="tab in tabs"
                        class="w-full text-center text-sm px-4 sm:px-6 py-2 bg-gray-100 hover:bg-gray-200"
                        :class="[
                          tab.id === 'error' && 'border-b border-tet-red text-tet-red-800 bg-tet-red-100 hover:bg-tet-red-300',
                          tab.id === 'warning' && 'border-b border-tet-yellow text-tet-yellow-800 bg-tet-yellow-100 hover:bg-tet-yellow-300',
                          tab.id === 'resolved' && 'border-b border-tet-green text-tet-green-800 bg-tet-green-100 hover:bg-tet-green-300',
                          tab.id === 'info' && 'border-b border-main1 text-tet-blue-800 bg-tet-blue-100 hover:bg-tet-blue-300',
                          tab.id === 'all' && 'border-b border-tet-warm-gray text-tet-gray-800 bg-tet-gray-100 hover:bg-tet-gray-300',
                          (tab.id === selectedTab && selectedTab === 'error') && '!border-b-2 border-tet-red bg-tet-red-100',
                          (tab.id === selectedTab && selectedTab === 'warning') && '!border-b-2 border-tet-yellow bg-tet-yellow-100',
                          (tab.id === selectedTab && selectedTab === 'resolved') && '!border-b-2 border-tet-green bg-tet-green-100',
                          (tab.id === selectedTab && selectedTab === 'info') && '!border-b-2 border-main1 bg-tet-blue-100',
                          (tab.id === selectedTab && selectedTab === 'all') && '!border-b-2 border-tet-warm-gray bg-tet-gray-100'
                          
                        ]"
                        :key="tab.id"
                        @click.prevent="filterAlerts(tab.id)"
                      >
                        <span class="hover:cursor-default">{{ tab.name }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="relative px-4 sm:px-6 space-y-2 pt-24">
                    <!-- Replace with your content -->

                    <template v-for="alert in filteredAlerts">
                      <BaseNotificationAlert
                        :alertType="alert.alertType"
                        :message="alert.msg"
                      />
                    </template>


                    <!-- /End replace -->
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>