<script setup>
import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import useAdsPresetsStore from "@/stores/playerBuilder/adsPresets";
import useFoldersStore from "@/stores/folders";

const getPermissions = inject("getPermissions");
const adsPresetStore = useAdsPresetsStore();
const {
  adsListNames,
  folderAdsTemplateDropdownOptions,
  liveAdsTemplateDropdownOptions,
  mediaAdsTemplateDropdownOptions,
} = storeToRefs(adsPresetStore);
const foldersStore = useFoldersStore();
const { inheritedFolderProps } = storeToRefs(foldersStore);
const router = useRouter();

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
  type: {
    type: String,
    required: false,
    default: "media",
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  isMainFolder: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const options = computed(() => {
  if (props.type === "live") return liveAdsTemplateDropdownOptions.value;

  if (props.type === "folder") {
    if (props.isMainFolder) {
      return adsListNames.value;
    } else {
      return folderAdsTemplateDropdownOptions.value;
    }
  }

  return mediaAdsTemplateDropdownOptions.value;
});

const tmpValue = computed({
  get() {
    if (!options.value) return [];

    const value = options.value.find((item) => item.id === props.modelValue);

    return value ? value : [];
  },
  set(evt) {
    emit("update:modelValue", evt.id);
  },
});

const getNonInheritedFolderProp = (folder) => {
  if (!inheritedFolderProps.value) return;

  if (!folder.inherit_parent && folder.embed.ad_id !== -1) {
    return folder;
  } else {
    return getNonInheritedFolderProp(folder.parent);
  }
};

const redirectPresetID = computed(() => {
  if (props.modelValue >= 0) {
    return props.modelValue;
  } else if (props.modelValue === -1) {
    if (!inheritedFolderProps.value) return 0;
    if (!getPermissions.view("folders.general")) return 0;

    const folderTmp = getNonInheritedFolderProp(inheritedFolderProps.value);
    return folderTmp.embed.ad_id;
  }
  return 0;
});

const goToPreset = () => {
  router.push({
    name: "playback",
    params: {
      section: "advertisements",
      type: "edit",
      id: redirectPresetID.value,
    },
  });
};
</script>

<template>
  <div
    v-if="getPermissions.view('players.advertisements')"
    class="flex gap-2.5 w-full"
  >
    <BaseComboBox
      class="min-w-[300px] max-w-[500px]"
      id="advertisement_preset_dropdown"
      label="Ads Template"
      :disabled="disabled"
      :options="options"
      v-model="tmpValue"
    />

    <BaseTooltipNew
      v-if="redirectPresetID"
      class="mt-auto"
      :tooltip-text="'Go to Ads Template with ID ' + redirectPresetID + '.'"
      :show-default-icon="false"
    >
      <div
        class="ml-2 text-xs text-main1 hover:cursor-pointer hover:underline"
        @click="goToPreset()"
      >
        Go to Ads Template setup
      </div>
    </BaseTooltipNew>
  </div>
</template>
