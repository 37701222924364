<script setup>
import { useAuthStore } from "@/stores/auth";

const auth = useAuthStore();

defineProps({
  sidebarSimple: {
    type: Boolean,
    required: true,
  },
  mobile: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emits = defineEmits(["openModal"]);
const OpenFeedbackModal = () => {
  auth.mobileSidebarOpen = false;
  emits("openModal");
};
</script>

<template>
  <div>
    <button
      v-if="mobile"
      type="button"
      class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md disabled:opacity-50"
      :title="auth.user?.impersonation_allowed ? 'Impersonate' : 'Not connected to VPN'"
      :disabled="!auth.user?.impersonation_allowed"
      @click="OpenFeedbackModal()"
    >
      <font-awesome-icon
        icon="fa-crown"
        class="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6 mr-4"
      />

      Impersonate
    </button>

    <button
      v-else
      type="button"
      class="group block py-2.5 border-l-2 hover:bg-gray-200 transition duration-300 ease-in-out relative docs-btn w-[74px] disabled:opacity-50"
      :class="[sidebarSimple ? 'md:w-[54px]' : 'md:w-[74px]', auth.user?.impersonation ? 'bg-tet-red' : 'bg-gray-100',]"
      :title="auth.user?.impersonation_allowed ? 'Impersonate' : 'Not connected to VPN'"
      :disabled="!auth.user?.impersonation_allowed"
      @click="$emit('openModal')"
    >
      <font-awesome-icon
        icon="fa-crown"
        class="text-gray-500 w-6 h-6 block mx-auto font-medium relative z-10 mb-2"
      />
      <span
        class="text-xs block text-gray-700"
        :class="[
          sidebarSimple
            ? 'absolute top-0 left-0 bg-main1 h-full pl-[54px] rounded-tr-lg rounded-br-lg items-center w-0 max-w-[0px] group-hover:w-[200px] group-hover:max-w-[200px] hidden transition duration-300 ease-in-out group-hover:text-white'
            : 'text-center',
        ]"
      >
        Impersonate
      </span>
    </button>
  </div>
</template>
