import { defineStore } from "pinia";
import axiosClient from "@/axiosClient";
import { app } from "@/main";
import { useAuthStore } from "@/stores/auth";

const URL = import.meta.env.VITE_API_URL;

export default defineStore({
  id: "useSecurityPresetStore",
  state: () => ({
    pagination: {
      limit: 20,
      offset: 0,
      total: 0,
    },
    templateSecurity: "list",
    securityPreset: null,
    uneditedSecurityPreset: null,
    securityPresets: null,
    securityPresetLoading: false,
    securityPresetsLoading: false,
    newSecurityPreset: {
      name: "",
      allow_domains: "",
      block_ip: "",
      allow_ip: "",
      allow_country: "",
      embed_token: 0,
      block_proxy: 0,
    },
    protectionListNames: null,
  }),

  actions: {
    getSecurityPresets() {
      this.setSecurityPresetsLoading = true;

      let params = {
        offset: this.pagination.offset,
        limit: this.pagination.limit,
      };

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/embed/protection/list", { params })
          .then((response) => {
            this.securityPresets = response.data.data;
            this.pagination = {
              limit: response.data.limit,
              offset: response.data.offset,
              total: response.data.total,
            };
            this.setSecurityPresetsLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.setSecurityPresetsLoading = false;
            reject(error);
          });
      });
    },

    getSecurityPreset({ id }) {
      this.setSecurityPresetLoading = true;

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/embed/protection/list?id=" + id)
          .then((response) => {
            if (response.data.code === 0) {
              this.securityPreset = response.data.data[0];
              this.uneditedSecurityPreset = JSON.parse(
                JSON.stringify(response.data.data[0])
              );
            }
            this.setSecurityPresetLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.setSecurityPresetLoading = false;
            reject(error);
          });
      });
    },

    getProtectionNames() {
      this.setSecurityPresetLoading = true;

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/embed/protection/listnames?order_by=name&order_dir=asc")
          .then((response) => {
            if (response.data.code === 0) {
              this.protectionListNames = response.data.data;
              this.setSecurityPresetLoading = false;
              resolve(response);
            }
          })
          .catch((error) => {
            this.setSecurityPresetLoading = false;
            reject(error);
          });
      });
    },

    updateSecurityPreset(payload) {
      this.setSecurityPresetLoading = true;

      return new Promise((resolve, reject) => {
        axiosClient
          .put(URL + "/embed/protection/update", payload)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: "Security preset updated!",
              });
              this.getSecurityPresets();
              app.config.globalProperties.$router.push("/playback/security");
            }
            this.setSecurityPresetLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.setSecurityPresetLoading = false;
            reject(error);
          });
      });
    },

    createSecurityPreset(payload) {
      this.setSecurityPresetLoading = true;

      return new Promise((resolve, reject) => {
        axiosClient
          .post(URL + "/embed/protection/create", payload)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: response.data.msg,
              });
              this.getSecurityPresets();
              app.config.globalProperties.$router.push("/playback/security");
            }
            this.setSecurityPresetLoading = false;
            resolve(response);
          })
          .catch((error) => {
            this.setSecurityPresetLoading = false;
            reject(error);
          });
      });
    },

    deleteSecurityPreset({ id }) {
      return new Promise((resolve, reject) => {
        axiosClient
          .delete(URL + "/embed/protection/delete?id=" + id)
          .then((response) => {
            if (response.data.code === 0) {
              app.config.globalProperties.$notify({
                title: "Success!",
                type: "success",
                text: response.data.msg,
              });
              this.getSecurityPresets();
              app.config.globalProperties.$router.push("/playback/security");
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getUserPaginationLimit() {
      return new Promise((resolve) => {
        const limitTmp =
          useAuthStore().user.preferences.securityLimit ||
          useAuthStore().user.preferences.playback_protection_limit;

        if (limitTmp) {
          this.pagination.limit = parseInt(limitTmp);
        }

        resolve();
      });
    },

    updateUserPaginationLimit() {
      //delete old naming
      if (useAuthStore().user.preferences["securityLimit"]) {
        delete useAuthStore().user.preferences["securityLimit"];

        useAuthStore().savePreferences({
          preferences: {
            securityLimit: null,
          },
        });
      }

      const limitTmp = this.pagination.limit.toString();

      if (
        useAuthStore().user.preferences.playback_protection_limit !== limitTmp
      ) {
        useAuthStore().user.preferences["playback_protection_limit"] = limitTmp;

        useAuthStore().savePreferences({
          preferences: {
            playback_protection_limit: limitTmp,
          },
        });
      }
    },
  },

  getters: {
    mediaProtectionTemplateDropdownOptions: (state) => {
      const defaultOption = [
        { id: -1, name: "Use from folder settings" },
        { id: 0, name: "None" },
      ];

      if (!state.protectionListNames) return defaultOption;

      return [...defaultOption, ...state.protectionListNames];
    },

    liveProtectionTemplateDropdownOptions: (state) => {
      const defaultOption = [
        { id: 0, name: "None" },
      ];

      if (!state.protectionListNames) return defaultOption;

      return [...defaultOption, ...state.protectionListNames];
    },

    folderProtectionTemplateDropdownOptions: (state) => {
      const defaultOption = [
        { id: -1, name: "Inherited from parent folder" },
        { id: 0, name: "None" },
      ];

      if (!state.protectionListNames) return defaultOption;

      return [...defaultOption, ...state.protectionListNames];
    },
  },
});
