<script setup>
import { computed } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const props = defineProps({
  label: {
    type: String,
    required: false,
  },
  id: {
    type: String,
    required: false,
  },
  modelValue: {
    type: [Number, String, null],
    required: true,
    default: 0,
  },
  customModal: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: [Boolean, Number],
    required: false,
    default: false,
  },
  placeholder: {
    type: String,
    required: false,
    default: null,
  },
  step: {
    type: Number,
    required: false,
    default: 1,
  },
  min: {
    type: Number,
    required: false,
    default: null,
  },
  max: {
    type: Number,
    required: false,
    default: null,
  },
  infoCircleColor: {
    type: String,
    required: false,
    default: "text-gray-500",
  },
  tooltipText: {
    type: String,
    required: false,
    default: "",
  },
  tooltipPlacement: {
    type: String,
    required: false,
    default: "top",
  },
  ignoreZero: {
    type: Boolean,
    required: false,
    default: false,
  },
  resetToDefaultIcon: {
    type: [Boolean],
    required: false,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "blurEmit", "resetToDefault"]);

const tmpValue = computed({
  get() {
    if (
      (props.ignoreZero && props.modelValue === 0) ||
      props.modelValue === null
    ) {
      return "";
    }
    return parseFloat(props.modelValue);
  },
  set(evt) {
    emit("update:modelValue", evt);
  },
});
</script>

<template>
  <div>
    <div class="flex gap-0.5 items-center">
      <label
        v-if="label"
        :for="id"
        class="block text-xs text-gray-500 font-medium"
      >
        {{ label }}
      </label>

      <div v-if="tooltipText">
        <BaseTooltipNew
          :tooltipText="tooltipText"
          :placement="tooltipPlacement"
        />
      </div>

      <div v-if="!disabled && label" class="h-3 w-3 flex ml-1">
        <font-awesome-icon
          v-if="resetToDefaultIcon"
          title="Reset to default value"
          icon="fa solid fa-rotate-left"
          class="cursor-pointer text-gray-500 font-medium text-sm h-3 w-3 my-auto"
          @click="emit('resetToDefault')"
        />
      </div>
    </div>

    <input
      :id="id"
      :name="id"
      :placeholder="placeholder"
      :disabled="disabled"
      v-model="tmpValue"
      :step="step"
      :min="min"
      :max="max"
      type="number"
      @blur="$emit('blurEmit')"
      @keydown.enter="$emit('blurEmit')"
      class="mt-1 block border border-gray-300 placeholder-[#999999] rounded-md shadow-sm py-1 px-3 focus:outline-none focus:border-gray-300 focus:ring-0 text-sm"
      :class="{
        'w-24': customModal,
        'w-full': !customModal,
        'bg-gray-200': disabled,
      }"
    />
  </div>
</template>
