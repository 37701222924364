<script setup>
import {
  Dialog,
  DialogPanel,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
  DialogTitle,
} from "@headlessui/vue";
import { defineProps, defineEmits, ref } from "vue";

defineProps({
  showModal: {
    type: Boolean,
    required: true,
  },
  title: {
    type: String,
    required: false,
    default: "Please confirm",
  },
  titleClass: {
    type: String,
    required: false,
    default: "text-sm text-gray-600",
  },
  acceptBtnText: {
    type: String,
    required: false,
    default: "Delete",
  },
  acceptBtnTheme: {
    type: String,
    required: false,
    default: "delete",
  },
  hideButtons: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideCancelButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  modalWidth: {
    type: String,
    required: false,
    default: "sm:max-w-lg",
  },
  overflow: {
    type: String,
    required: false,
    default: "overflow-hidden",
  },
  actionInProgress: {
    type: Boolean,
    required: false,
    default: false,
  },
  errorMsg: {
    type: String,
    required: false,
    default: "",
  },
});
const emit = defineEmits(["accept", "cancel"]);
const showErrorMsg = ref(false);
</script>

<template>
  <TransitionRoot appear as="template" :show="showModal">
    <Dialog as="div" class="relative z-[999999]">
      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div
          class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-gray-500 opacity-75" />
          </TransitionChild>

          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative bg-white rounded p-5 text-left shadow-xxl transform transition-all my-8 w-full"
              :class="[modalWidth, overflow]"
            >
              <DialogTitle
                v-if="!hideButtons"
                as="h2"
                class="mb-5"
                :class="titleClass"
              >
                {{ title }}
              </DialogTitle>

              <Transition mode="out-in">
                <div>
                  <div class="flex items-center mb-2.5">
                    <div class="text-xs w-full text-gray-700 font-light mr-2.5">
                      <slot></slot>
                    </div>
                  </div>

                  <div
                    v-if="!hideButtons"
                    class="mt-7 flex gap-2.5 justify-end"
                  >
                    <BaseButton
                      v-if="!hideCancelButton"
                      theme="gray"
                      @click="emit('cancel')"
                    >
                      Cancel
                    </BaseButton>

                    <BaseButton
                      :theme="acceptBtnTheme"
                      :disabled="disabled || actionInProgress"
                      @mouseenter="showErrorMsg = true"
                      @mouseleave="showErrorMsg = false"
                      @click="emit('accept')"
                    >
                      <div
                        v-if="actionInProgress"
                        class="animate-spin w-4 h-4 border-4 border-b-transparent rounded-full"
                        role="status"
                      />
                      <p v-else>{{ acceptBtnText }}</p>
                    </BaseButton>

                    <p
                      v-if="errorMsg && showErrorMsg"
                      class="absolute bottom-14 right-7 text-xs text-gray-500 font-medium"
                    >
                      {{ errorMsg }}
                    </p>
                  </div>
                </div>
              </Transition>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
