<script setup>
import { computed } from "vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { useAuthStore } from "@/stores/auth";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const props = defineProps({
  label: {
    type: String,
    required: false,
  },
  id: {
    type: [String, null],
    required: true,
  },
  modelValue: {
    type: String,
    required: false,
  },
  config: {
    type: Object,
    required: false,
    default: () => ({
      enableTime: true,
      enableSeconds: true,
      static: true,
      dateFormat: useAuthStore()
        .user.datetime_format.replace("s", "S")
        .replace("a", "K"),
      defaultHour: 0,
      time_24hr: !useAuthStore().user.datetime_format.includes("a"),
    }),
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  noBorder: {
    type: Boolean,
    required: false,
    default: false,
  },
  placeholder: {
    type: String,
    required: false,
    default: "",
  },
  info: {
    type: String,
    required: false,
    default: "",
  },
  tooltipPlacement: {
    type: String,
    required: false,
    default: "top",
  },
  resetToDefaultIcon: {
    type: [Boolean],
    required: false,
    default: false,
  },
  showXIcon: {
    type: [Boolean],
    required: false,
    default: true,
  },
});

const emit = defineEmits(["update:modelValue", "resetToDefault"]);

const tmpValue = computed({
  get() {
    return props.modelValue;
  },
  set(evt) {
    emit("update:modelValue", evt);
  },
});
</script>

<template>
  <div>
    <div v-if="label" class="flex gap-0.5">
      <label
        v-if="label"
        :for="id"
        :title="label"
        class="block text-xs font-medium text-gray-500 mb-1"
      >
        <span class="flex items-center">
          <p class="truncate">{{ label }}</p>
          <BaseTooltipNew :tooltipText="info" :placement="tooltipPlacement" />
        </span>
        <!--        {{ useGetFormattedDate().userTimeFormat() }}-->
      </label>

      <div v-if="!disabled" class="h-3 w-3 flex">
        <font-awesome-icon
          v-if="resetToDefaultIcon"
          title="Reset to default value"
          icon="fa solid fa-rotate-left"
          class="cursor-pointer text-gray-500 font-medium text-sm h-3 w-3 my-auto ml-2.5"
          @click="emit('resetToDefault')"
        />
      </div>
    </div>

    <div class="w-full flex relative">
      <div class="flex-auto">
        <flatPickr
          :disabled="disabled"
          :id="id"
          :name="id"
          :config="config"
          v-model="tmpValue"
          :placeholder="placeholder"
          class="relative w-full cursor-pointer bg-white text-left disabled:bg-gray-200 disabled:cursor-default placeholder-[#999999]"
          :class="[
            noBorder
              ? 'border-transparent text-xs py-2 pl-3 '
              : 'border rounded-md border-gray-300 shadow-sm sm:text-sm py-1 pl-3 ',
            !disabled && !!tmpValue && showXIcon ? 'pr-10' : 'pr-3',
          ]"
        />
      </div>

      <div
        v-if="!disabled && !!tmpValue && showXIcon"
        class="absolute inset-y-0 right-0 flex items-center mr-2 cursor-pointer"
      >
        <font-awesome-icon
          icon="fa-solid fa-xmark"
          class="h-3 w-3 text-gray-400"
          @click="tmpValue = ''"
        />
      </div>
    </div>
  </div>
</template>
