<script setup>
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  ListboxLabel,
} from "@headlessui/vue";
import { computed } from "vue";
import { SelectorIcon, InformationCircleIcon } from "@heroicons/vue/outline";

const props = defineProps({
  value: {
    type: [String, Number, null],
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
  options: {
    type: [Object, Array],
    required: true,
  },
  disabledOptions: {
    type: [Object, Array],
    required: false,
    default: [],
  },
  customTitle: {
    type: Boolean,
    required: false,
    default: false,
  },
  extWidth: {
    type: Boolean,
    required: false,
    default: false,
  },
  expireSelect: {
    type: Boolean,
    required: false,
    default: false,
  },
  userTimezone: {
    type: String,
    required: false,
    default: "",
  },
  noBorder: {
    type: Boolean,
    required: false,
    default: false,
  },
  smallHeight: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  emptyRendition: {
    type: Boolean,
    required: false,
  },
  defValue: {
    type: [String, Number],
    required: false,
    default: "",
  },
  infoCircleColor: {
    type: String,
    required: false,
    default: "text-gray-500",
  },
  tooltipText: {
    type: String,
    required: false,
    default: "",
  },
});

const emit = defineEmits(["onUpdate:value"]);

const tmpValue = computed({
  get() {
    if (typeof props.value === "undefined") props.value = "";
    return props.value;
  },
  set(evt) {
    emit("onUpdate:value", evt);
  },
});

const getSizeName = (size) => {
  if (size === "1.4") {
    return "L";
  }
  if (size === "1.2") {
    return "M";
  }
  if (size === "1") {
    return "S";
  }
  if (size === "0.8") {
    return "XS";
  }
};
</script>

<template>
  <Listbox
    :class="{ 'w-full': expireSelect === false }"
    :disabled="disabled"
    v-model="tmpValue"
    as="div"
  >
    <ListboxLabel class="text-xs text-gray-500 font-medium block mb-1 flex">
      {{ label }}
      <BaseTooltip
        v-if="tooltipText"
        :text="tooltipText"
        customWidth="125px"
      >
        <InformationCircleIcon
          class="cursor-pointer h-4 w-4 font-medium text-sm pt-0.5"
          :class="infoCircleColor"
        />
      </BaseTooltip>
    </ListboxLabel>
    <ListboxButton
      class="relative w-full cursor-pointer bg-white text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 disabled:bg-gray-200 disabled:cursor-default"
      :class="[
        noBorder
          ? 'border-b-gray-300 border-t-transparent border-x-transparent border-b focus:border-t-transparent focus:border-x-transparent focus:border-b-gray-300 text-xs py-2 px-3 '
          : 'border rounded-md border-gray-300 shadow-sm text-sm py-1 px-3 ',
        customTitle ? 'w-16' : 'w-full',
        extWidth && 'w-32',
      ]"
    >
      <span v-if="emptyRendition && tmpValue === ''">
        {{ defValue }}
      </span>
      <span v-else-if="tmpValue && !customTitle" class="block truncate h-5">
        {{ tmpValue }}
      </span>
      <span v-else-if="customTitle" class="block truncate">
        {{ getSizeName(tmpValue) }}
      </span>
      <span v-else-if="!tmpValue" class="block truncate h-5">
        {{ defValue }}
      </span>
      <span
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
      >
        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </span>
    </ListboxButton>

    <transition
      leave-active-class="transition duration-100 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div class="relative w-full">
        <ListboxOptions
          class="z-10 absolute border mt-1 w-full overflow-y-scroll overscroll-x-hidden rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus-visible:border-indigo-500 sm:text-sm"
          :class="[smallHeight ? 'max-h-32' : 'max-h-60']"
        >
          <ListboxOption
            v-slot="{ active, selected }"
            v-for="option in options"
            :key="option"
            :value="option"
            :disabled="disabledOptions.includes(option)"
            as="template"
          >
            <li
              class="relative select-none py-2 px-2"
              :class="[
                active ? 'bg-main1-dark text-white' : 'text-gray-900',
                selected ? 'font-medium text-white bg-main1' : 'font-normal',
                disabledOptions.includes(option)
                  ? 'bg-gray-300'
                  : 'cursor-pointer',
              ]"
            >
              <span v-if="emptyRendition && option === ''">
                {{ defValue }}
              </span>
              <span v-if="customTitle" class="block truncate h-5">
                {{ getSizeName(option) }}
              </span>
              <span v-else class="block truncate">
                {{ option }}
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </div>
    </transition>
  </Listbox>
</template>
