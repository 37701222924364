<script setup>
import { computed, nextTick, ref, watchEffect } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  ListboxLabel,
} from "@headlessui/vue";
import { SelectorIcon } from "@heroicons/vue/outline";

const props = defineProps({
  value: {
    type: [String, Number, Object, null],
    required: false,
    default: "",
  },
  label: {
    type: String,
    required: false,
  },
  options: {
    type: [Object, Array],
    required: true,
  },
  trackBy: {
    type: [String],
    required: false,
    default: "name",
  },
  disabled: {
    type: [Boolean, Number],
    required: false,
    default: false,
  },
  defaultSelectValue: {
    type: String,
    required: false,
    default: "none",
  },
  disabledOptions: {
    type: [Array],
    required: false,
    default: [],
  },
  trackDisabledKey: {
    type: String,
    required: false,
    default: "value",
  },
  tooltipText: {
    type: String,
    required: false,
    default: "",
  },
  tooltipPlacement: {
    type: String,
    required: false,
    default: "top",
  },
});

const emit = defineEmits(["onUpdate:value"]);

const tmpValue = computed({
  get() {
    if (!props.value) {
      return "";
    }
    return props.value;
  },
  set(evt) {
    emit("onUpdate:value", evt);
  },
});

const dropdownIsOpen = ref(false);
const openDropdown = () => {
  dropdownIsOpen.value = true;
};

const listboxButton = ref(null);
const listboxOptions = ref(null);

const dropdownIsAbove = ref(false);

watchEffect(() => {
  if (dropdownIsOpen.value && listboxButton.value) {
    nextTick(() => {
      const buttonRect = listboxButton.value.getBoundingClientRect();
      const spaceBelow = window.innerHeight - buttonRect.bottom;
      dropdownIsAbove.value = spaceBelow < 250;
    });
  }
});
</script>

<template>
  <Listbox
    as="div"
    class="w-full"
    :disabled="disabled"
    v-slot="{ open }"
    v-model="tmpValue"
  >
    <ListboxLabel
      class="text-xs font-medium text-gray-500 mb-1 flex items-center"
    >
      {{ label }}
      <div v-if="tooltipText">
        <BaseTooltipNew
          :tooltipText="tooltipText"
          :placement="tooltipPlacement"
        />
      </div>
    </ListboxLabel>

    <div class="relative">
      <div ref="listboxButton">
        <ListboxButton
          class="
            relative w-full cursor-pointer bg-white text-left border rounded-md
            border-gray-300 shadow-sm text-sm py-1 px-3 disabled:bg-gray-200
            focus:outline-none disabled:cursor-default
          "
          :key="open"
          @click="openDropdown"
        >
          <span v-if="tmpValue" class="block truncate">
            {{ tmpValue[trackBy] }}
          </span>
          <span v-else class="block truncate">
            {{ defaultSelectValue }}
          </span>
          <span
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>
      </div>
    </div>

    <transition
      leave-active-class="transition duration-100 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div class="relative w-full">
        <div v-if="dropdownIsOpen" ref="listboxOptions">
          <ListboxOptions
            class="
              z-[999999] absolute border overflow-y-auto w-full overscroll-x-hidden rounded-md bg-white p-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus-visible:border-indigo-500 sm:text-sm max-h-96"
            :class="[dropdownIsAbove ? 'bottom-full mb-8' : 'top-full']"
          >
            <ListboxOption
              v-slot="{ active, selected }"
              v-for="option in options"
              :key="option"
              :value="option"
              :disabled="disabledOptions.includes(option[trackDisabledKey])"
              as="template"
            >
              <li
                class="rounded relative cursor-pointer select-none py-2 px-2"
                :class="[
                  disabledOptions.includes(option[trackDisabledKey])
                    ? 'bg-gray-100'
                    : '',
                  active ? 'bg-main1-dark text-white' : 'text-gray-900',
                  selected ? 'font-medium text-white bg-main1' : 'font-normal',
                ]"
              >
                <span class="block truncate">{{ option[trackBy] }}</span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </div>
      </div>
    </transition>
  </Listbox>
</template>
