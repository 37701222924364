import { defaultPreferences } from "@/helpers/defaultPreferences";

export function useGetMediaViews(userMediaViews) {
  let mediaViewsArray;

  try {
    mediaViewsArray = JSON.parse(userMediaViews);
  } catch (err) {
    console.log("Invalid Media Views JSON passed!");
    mediaViewsArray = [...defaultPreferences.defaultMediaViews];
  }

  mediaViewsArray.forEach((view) => {
    if (!view.orderBy) view.orderBy = "id";
    if (!view.orderDir) view.orderDir = true;
    if (!view.mediaLimit) view.mediaLimit = 20;
    if (!view.selectedSearchFilters) view.selectedSearchFilters = [];
    if (!view.selectedSearchWildcards) view.selectedSearchWildcards = [];
    if (!view.selectedFolder) view.selectedFolder = 0;

    if (!view.mediaListParams)
      view.mediaListParams = defaultPreferences.mediaListParams;
    //update later added params

    Object.keys(defaultPreferences.mediaListParams).forEach((key) => {
      if (!view.mediaListParams[key])
        view.mediaListParams[key] = defaultPreferences.mediaListParams[key];
    });

    if (!view.columns) view.columns = defaultPreferences.mediaColumns.default;

    //set fixed array based on viewType
    let fixedArrayTmp = null;
    if (view.viewType === "List") {
      fixedArrayTmp = defaultPreferences.mediaColumns.fixedList;
    } else if (view.viewType === "Grid") {
      fixedArrayTmp = defaultPreferences.mediaColumns.fixedGrid;
    } else {
      fixedArrayTmp = defaultPreferences.mediaColumns.fixedAdvanced;
    }

    //check old/new saved column
    let arrayTmp = [];
    if (view.columns && Array.isArray(view.columns)) {
      arrayTmp = view.columns;
    } else {
      for (const [key, value] of Object.entries(view.columns)) {
        if (value === "true") arrayTmp.push(key);
      }
    }
    if (arrayTmp.includes("Technical_status")) {
      arrayTmp.splice(arrayTmp.indexOf("Technical_status"), 1);
    }
    view.columns = [...new Set([...arrayTmp, ...fixedArrayTmp])];
  });

  return mediaViewsArray;
}
